import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-resend-email",
  templateUrl: "./resend-email.component.html",
  styleUrls: ["./resend-email.component.scss"],
})
export class ResendEmailComponent {
  email: string;
  individual: boolean;

  constructor(
    private api: ApiService,
    private _notification: NotificationsService,
    private _router: Router,
    private _location: Location
  ) {
    if (this._router.getCurrentNavigation().extras.state) {
      let data = this._router.getCurrentNavigation().extras.state.data;
      this.email = data["email"];
      this.individual = data["individual"];
    }
  }

  resendEmail() {
    this.api.resendConfirmationEmail(this.email, this.individual).subscribe(
      (res) => {
        this._notification.success(
          "Success",
          "Confirmation email has been resent"
        );
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Resend email error", err.detail);
      }
    );
  }

  backAction() {
    if (this.individual) {
      this._router.navigate(["/register/personal"]);
    } else {
      this._router.navigate(["/register/business"]);
    }
  }
}
