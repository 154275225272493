import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {

  uploadForm: FormGroup;
  uploaded = false;

  constructor(
    private apiService: ApiService,
    public modal: NgbActiveModal,
    private _notification: NotificationsService, 
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    this.uploadForm = this._fb.group({
      policy_name: ['', [Validators.required, Validators.pattern("[a-zA-Z0-9.\\-\\_ ]*")]],
      policy_version: ['', [Validators.required, Validators.pattern("[a-zA-Z0-9.\\-\\_ ]*")]]
    })
  }

  submit() {
    if (this.uploadForm.valid) {
      this.uploaded = true;
    } else {
      this.validateAllFormFields(this.uploadForm)
    }
  }

  close() {
    this.modal.close( false );
  }

  okClick() {
    this.modal.close( this.uploadForm );
  }

  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);            
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);          
      }
    });
  }

}
