import { Component, NgModule, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from '../../../services/api.service';
import { Location } from '@angular/common'
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { RegistrationSharedData } from 'src/app/services/registration-shared-data.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})

export class RegisterComponent implements OnInit {

  signUpForm: FormGroup;
  hide_pass = true;
  individual: boolean;
  accountType: string;
  checkbox: boolean;

  constructor(
    public api: ApiService,
    private _notification: NotificationsService,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private _location: Location,
    private _router: Router,
    private sharedService: RegistrationSharedData
  ) { }

  ngOnInit() {
    this.route.data.subscribe(params =>
      this.individual = params.individual
    )
    this.initForm();
  }

  initForm() {

    if (this.individual) {
      this.signUpForm = this._fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)]]
      });
    } else {
      this.signUpForm = this._fb.group({
        first_name: ['', [Validators.required]],
        last_name: ['', [Validators.required]],
        company_name: ['', [Validators.required]],
        web_address: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)]]
      })
    }

    this.accountType = this.individual ? 'personal' : 'business';
  }

  goNext() {

    if (this.signUpForm.valid) {

      if (this.individual) {

        this.sharedService.setRegistrationData({ type: 'personal', formData: this.signUpForm })
        this._router.navigate(['/register/personal/wizard'])

      }
      else {

        this.sharedService.setRegistrationData({ type: 'business', formData: this.signUpForm })
        this._router.navigate(['/register/business/wizard'])
      }

    }
    else {

      this.validateAllFormFields(this.signUpForm);
    }
  }

  /*signUp(signUpForm: FormGroup): void {
    if (this.signUpForm.valid) {

      let registerPayload: Object;

      if (this.individual) {
        registerPayload = {
          email: signUpForm.controls.email.value,
          password: signUpForm.controls.password.value
        };
      } else {
        registerPayload = {
          first_name: signUpForm.controls.first_name.value,
          last_name: signUpForm.controls.last_name.value,
          company_name: signUpForm.controls.company_name.value,
          web_address: signUpForm.controls.web_address.value,
          email: signUpForm.controls.email.value,
          password: signUpForm.controls.password.value
        };
      }

      this.api.register(registerPayload, this.individual).subscribe(
        res => {
          const data = {
            email: signUpForm.controls.email.value,
            individual: this.individual,
          }
          let navigationExtras: NavigationExtras = {
            state: {
              data: data
            }
          };
          this._router.navigate(['/resend-email'], navigationExtras)
        }, error => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error('Sign Up error', err.detail);
        }
      );
    }
    else {
      this.validateAllFormFields(this.signUpForm);
    }
  }*/

  goToLogin() {
    const data = {
      individual: this.individual
    }

    let navigationExtras: NavigationExtras = {
      state: {
        data: data
      }
    };

    this._router.navigate(['/login'], navigationExtras);
  }

  backAction() {
    this._router.navigate(["/register"]);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
