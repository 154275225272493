import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

declare let gtag: Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  /* notifOptions = {
    position: ['bottom', 'right'],
    timeOut: 2000,
    lastOnBottom: true,
    clickToClose: true,
    animate: 'scale'
  }; */

  private subscription: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private metaTagService: Meta,
    private readonly router: Router
  ) { 

    
  }

  ngOnInit(): void {
    this.metaTagService.addTag({
      name: "keywords",
      content:
        "what is a privacy policy, children's online privacy policy, data privacy, digital privacy, privacy and online safety, continuous data protection, protect privacy app, protect privacy online, data, protection, privacy,directive, regulations, encryption",
    });
    this.setUpAnalytics();
  }

  setUpAnalytics() {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (isPlatformBrowser(this.platformId)) {
          gtag("config", "UA-144025317-2", {
            page_path: event.urlAfterRedirects,
          });
        }
      });
  }

  notifOptions = {
    position: ["bottom", "right"],
    animate: "scale",
    timeOut: 5000,
    pauseOnHover: true,
    clickIconToClose: true,
    clickToClose: true,
    lastOnBottom: true,
  };

  title = "project-o";

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

/*

npm run build:ssr
npm run serve:ssr

*/
