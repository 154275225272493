import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';
import { PasswordValidation } from 'src/app/validation/password-validator';

@Component({
  selector: 'app-verify-invitation',
  templateUrl: './verify-invitation.component.html',
  styleUrls: ['./verify-invitation.component.scss']
})

export class VerifyInvitationComponent implements OnInit {

  invitationForm: FormGroup;
  hide_pass1 = true;
  hide_pass2 = true;
  private individual: boolean;
  private key: string;

  constructor(
    private api: ApiService,
    private _fb: FormBuilder,
    private _notification: NotificationsService,
    private route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.invitationForm = this._fb.group({
      password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)]],
    }
    );

    this.route.params.subscribe(params => {
      this.key = params["key"];
    })

    this.route.data.subscribe(data => {
      this.individual = data['individual']
      this.api.verifyInvitation(this.key, this.individual).subscribe(
        res => {

        },
        error => {
          this._router.navigate(['/invitation-expired'])
        }
      )
    })
  }

  accept(invitationForm: FormGroup) {
    if (invitationForm.valid) {

      this.api.acceptInvitation(invitationForm.getRawValue(), this.key, this.individual).subscribe(
        res => {
          // const data = {
          //   individual: this.individual
          // }
      
          // let navigationExtras: NavigationExtras = {
          //   state: {
          //     data: data
          //   }
          // };
          
          // this._router.navigate(['/login'], navigationExtras);
          this._router.navigate(['/']);
        },
        error => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error('Invitation error', err.detail);
        }
      );

    } else {
      this.validateAllFormFields(this.invitationForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);            
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);          
      }
    });
  }

}
