import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FetchDataService {

    private fetchArchivedPoliciesSource = new Subject<boolean>()
    private fetchAnalysedPoliciesSource = new Subject<boolean>()
    private fetchAnalysedDocumentsSource = new Subject<boolean>()

    archivedPolicies$ = this.fetchArchivedPoliciesSource.asObservable()
    analysedPolicies$ = this.fetchAnalysedPoliciesSource.asObservable()
    analysedDocuments$ = this.fetchAnalysedDocumentsSource.asObservable()

    fetchArchivedPolicies() {
        this.fetchArchivedPoliciesSource.next(true)
    }

    fetchAnalysedPolicies() {
        this.fetchAnalysedPoliciesSource.next(true)
    }

    fetchAnalysedDocuments() {
        this.fetchAnalysedDocumentsSource.next(true)
    }

}