<div class="wrapper">
  <app-onboard-header [cancelLink]="getBackLink()"></app-onboard-header>
  <div class="content-wrapper">
    <div class="stepper-header">
      <a (click)="goBackPage()" class="back-link" *ngIf="active > 1">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 8H1M1 8L8 15M1 8L8 1"
            stroke="#5F3B19"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <a [routerLink]="getBackLink()" class="back-link" *ngIf="active === 1">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 8H1M1 8L8 15M1 8L8 1"
            stroke="#5F3B19"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
    </div>
    <app-what-preferences
      [ngStyle]="{ display: active === 1 ? 'block' : 'none' }"
      (selectedItems)="selectedWhat = $event"
      [preselected]="preselectedData"
    ></app-what-preferences>
    <app-purpose-preferences
      [ngStyle]="{ display: active === 2 ? 'block' : 'none' }"
      (selectedItems)="selectedPurposes = $event"
      [preselected]="preselectedPurpose"
    ></app-purpose-preferences>
    <app-retention-preferences
      [ngStyle]="{ display: active === 3 ? 'block' : 'none' }"
      (selectedItems)="selectedRetention = $event"
      [preselected]="preselectedRetention"
    ></app-retention-preferences>
    <app-preferences-overview
      [ngStyle]="{ display: active === 4 ? 'block' : 'none' }"
      [selectedPurposes]="selectedPurposes"
      [selectedWhat]="selectedWhat"
      [selectedRetention]="selectedRetention"
    ></app-preferences-overview>
    <div class="stepper-footer">
      <div *ngIf="active < 4">
        <div
          class="step-index"
          [ngStyle]="{ opacity: active === 1 ? '100%' : '40%' }"
        ></div>
        <div
          class="step-index"
          [ngStyle]="{ opacity: active === 2 ? '100%' : '40%' }"
        ></div>
        <div
          class="step-index"
          [ngStyle]="{ opacity: active === 3 ? '100%' : '40%' }"
        ></div>
        <div
          class="step-index"
          [ngStyle]="{ opacity: active === 4 ? '100%' : '40%' }"
        ></div>
        <button
          (click)="goNextPage()"
          class="next-btn"
          [disabled]="
            (active === 1 && selectedWhat.length === 0) ||
            (active === 2 && selectedPurposes.length === 0) ||
            (active === 3 && selectedRetention.length === 0)
          "
        >
          <span>
            Next
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="background-size: 14px 14px; margin-left: 13px"
            >
              <path
                d="M1.0481 8.76645H15.0481M15.0481 8.76645L8.0481 1.76645M15.0481 8.76645L8.0481 15.7664"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </button>
      </div>
      <div *ngIf="active === 4">
        <button class="get-started-btn" (click)="onSubmit()">
          {{ isChanging ? "Save" : "Get started" }}
        </button>
      </div>
    </div>
  </div>
</div>
