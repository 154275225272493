import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { Router } from '@angular/router';
import { RegistrationSharedData } from 'src/app/services/registration-shared-data.service';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  constructor(private router: Router, private location: Location, private sharedService: RegistrationSharedData) { }

  data = {
    goThroughView: true,
    privacyPolicyView: false,
    termsAndConditionsView: false,
    summarisationView: false
  }

  ngOnInit(): void {
    if (!this.sharedService.getRegistrationData()) {
      this.router.navigate(['/register'])
    }
  }
}
