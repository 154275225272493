<div class="dialog-header">
  <span>Invite user</span>
  <span style="flex: 1 1 auto"></span>
  <span>
    <button type="button" class="dialog-close-button" (click)="close()">
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="background-size: 12px 12px"
      >
        <path
          d="M13 1.5L1 13.5M1 1.5L13 13.5"
          stroke="#6B6B6B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </span>
</div>

<div class="dialog-content">
  <form
    [formGroup]="invitationForm"
    id="invitationBusiness"
    class="invitation-form"
  >
    <div class="row" *ngIf="!individual">
      <div class="col-lg-6 col-sm-12 business-fields">
        <mat-form-field appearance="outline">
          <mat-label>First name</mat-label>
          <input
            matInput
            placeholder="First name"
            autocomplete="off"
            formControlName="first_name"
          />
          <mat-hint
            *ngIf="
              invitationForm.get('first_name').hasError('required') &&
              invitationForm.get('first_name').touched
            "
            id="nameHelp"
            class="danger"
          >
            First name is required
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-sm-12 business-fields">
        <mat-form-field appearance="outline">
          <mat-label>Last name</mat-label>
          <input
            matInput
            placeholder="Last name"
            autocomplete="off"
            formControlName="last_name"
          />
          <mat-hint
            *ngIf="
              invitationForm.get('last_name').hasError('required') &&
              invitationForm.get('last_name').touched
            "
            id="surnameHelp"
            class="danger"
          >
            Last name is required
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 field-wrapper">
        <mat-form-field appearance="outline">
          <mat-label>Email address</mat-label>
          <input
            matInput
            placeholder="example@mail.com"
            autocomplete="off"
            formControlName="email"
          />
          <mat-hint
            *ngIf="
              invitationForm.get('email').hasError('email') &&
              !invitationForm.get('email').hasError('required') &&
              invitationForm.get('email').touched
            "
            id="email2Help1"
            class="danger"
          >
            Please enter a valid email address
          </mat-hint>
          <mat-hint
            *ngIf="
              invitationForm.get('email').hasError('required') &&
              invitationForm.get('email').touched
            "
            id="email2Help2"
            class="danger"
          >
            Email is required
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="submit-wrapper">
      <button
        type="button"
        (click)="close()"
        mat-flat-button
        class="cancel-btn"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="submit-btn"
        type="submit"
        (click)="submitInvite()"
      >
        Send invitation
      </button>
    </div>
  </form>
</div>
