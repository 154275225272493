<section class="bg-white mt-20 lg:mt-24 py-12">

    <div class="container">

        <div class="flex flex-col gap-10">

            <div>
                <div class="flex items-center justify-center gap-5 font-medium text-lg">
                    <p class="text-bronze">TERMS AND CONDITIONS</p>
                </div>
                <hr class="bg-bronze h-[3px] transition-all duration-300" />
            </div>

            <div class="flex flex-col gap-1 w-full">
                <div *ngFor="let item of termsAndConditionItems.sections; index as i">
                    <div (click)="toggleDropDownSection(i)"
                        class="flex items-center gap-5 bg-purple-light p-3 cursor-pointer">
                        <p class="text-brown font-semibold">{{item.section.replaceAll('**','')}}</p>
                        <img #arrows src="../../../assets/imgs/bronze-down-arrow.svg"
                            class="ms-auto transition-all duration-300" alt="bronze arrow" />
                    </div>
                    <div #listItems_termsAndConditions style="height: 0px;"
                        class="overflow-hidden transition-all duration-300">
                        <div class="p-3">
                            <markdown class="markdown-style" [data]="item.content"></markdown>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="data" class="flex items-center gap-5">
                <button (click)="goBack()"
                    class="bg-white border-[1px] border-bronze text-bronze px-4 py-2 rounded-full hover:bg-bronze hover:text-white">I
                    decline</button>
                <button (click)="displaySummarisationView()"
                    class="bg-bronze border-[1px] border-bronze text-white px-4 py-2 rounded-full hover:bg-white hover:text-bronze">I
                    accept</button>
            </div>

        </div>
    </div>

</section>