import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;

  constructor(
    private api: ApiService,
    private _notification: NotificationsService,
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    this.contactForm = this._fb.group({
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
  }

  send(contactForm) {
    if (contactForm.valid) {
      this.api.contactGest(contactForm.getRawValue()).subscribe(
        res => {
          this.contactForm.reset();
          this.contactForm.controls.subject.setErrors(null);
          this.contactForm.controls.message.setErrors(null);
          this._notification.success("Success", "Message sent");
        },
        error => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Contact error", err.detail);
        }
      );
    } else {
      this.validateAllFormFields(this.contactForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);            
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);          
      }
    });
  }
}
