<div class="accordance-wrapper">
  <div class="header">
    <span class="title">Deviation from your profile</span>
    <button mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="content-wrapper">
      <p class="info">
        {{ appMetadata.label }} includes privacy provisions which are not in
        line with your privacy preferences:
      </p>
      <div
        class="chip-wrapper"
        *ngIf="
          crossReferenceResults &&
          crossReferenceResults.data_collected &&
          crossReferenceResults.data_collected.length !== 0
        "
      >
        <span class="label">They collect additional data:</span>
        <div>
          <mat-chip-list>
            <mat-chip
              *ngFor="let value of crossReferenceResults.data_collected"
              [ngStyle]="{ 'background-color': dataCollectedColors[value] }"
              color="primary"
              (click)="showSentences($event, value)"
              >{{ value }}</mat-chip
            >
          </mat-chip-list>
        </div>
      </div>
      <div
        class="chip-wrapper"
        *ngIf="
          crossReferenceResults &&
          crossReferenceResults.collecting_purpose &&
          crossReferenceResults.collecting_purpose.length !== 0
        "
      >
        <span class="label">For purpose of:</span>
        <div>
          <mat-chip-list>
            <mat-chip
              *ngFor="let value of crossReferenceResults.collecting_purpose"
              [ngStyle]="{ 'background-color': collectingPurposeColors[value] }"
              color="primary"
              (click)="showSentences($event, value)"
              >{{ value }}</mat-chip
            >
          </mat-chip-list>
        </div>
      </div>
      <div
        class="chip-wrapper"
        *ngIf="
          crossReferenceResults &&
          crossReferenceResults.retention_period &&
          crossReferenceResults.retention_period.length !== 0
        "
      >
        <span class="label">With following retention period:</span>
        <div>
          <mat-chip-list>
            <mat-chip
              *ngFor="let value of crossReferenceResults.retention_period"
              [ngStyle]="{ 'background-color': retentionPeriodColors[value] }"
              (click)="showSentences($event, value)"
              >{{ value }}</mat-chip
            >
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <button class="mt-2 submit-btn" mat-raised-button (click)="cancel()">
      OK
    </button>
  </div>
</div>
