import { Component } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationsService } from "angular2-notifications";
import { ApiService } from "src/app/services/api.service";
import { ComparisonDialogComponent } from "../comparison-dialog/comparison-dialog.component";

@Component({
  selector: "app-choose-dialog",
  templateUrl: "./choose-dialog.component.html",
  styleUrls: ["./choose-dialog.component.scss"],
})
export class ChooseDialogComponent {
  applications: any;
  selectedApps = [];

  page = 1;
  pageSize = 5;
  count: number;

  searchTerm = "";

  constructor(
    private api: ApiService,
    public modal: NgbActiveModal,
    private _notification: NotificationsService,
    private _modal: NgbModal
  ) {
    this.getApplications();
  }

  getApplications() {
    const params = {
      page: this.page,
      size: this.pageSize,
    };

    this.api.getBusinessAnalysedApplicationList(params, null).subscribe(
      (res) => {
        this.applications = res.items;
        this.count = res.total;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Get analysed applications", err.detail);
      }
    );
  }

  handlePageChange(event) {
    this.page = event;

    if (this.searchTerm != "") {
      this.onSearch(true);
    } else {
      this.getApplications();
    }
  }

  onAppSelectionChange(e): void {
    console.log(e.options[0].value);
    const newVal = e.options[0].value;
    for (let i = 0; i < this.selectedApps.length; i++) {
      if (this.selectedApps[i].id == newVal) {
        this.selectedApps.splice(i, 1);
        return;
      }
    }
    this.selectedApps.push(newVal);

    if (this.selectedApps.length == 3) {
      this.selectedApps.shift();
    }
  }

  isAppSelected(id) {
    for (let i = 0; i < this.selectedApps.length; i++) {
      if (this.selectedApps[i].id == id) {
        return true;
      }
    }
    return false;
  }

  cancel(): void {
    this.modal.close();
  }

  onSearch(isNextPage) {
    if (this.searchTerm == "") {
      this.page = 1;
      this.getApplications();
    } else {
      if (!isNextPage) {
        this.page = 1;
      }

      const params = {
        term: this.searchTerm,
        page: this.page,
        size: this.pageSize,
      };

      this.api.searchAnalysedApps(params, null).subscribe(
        (res) => {
          this.applications = res.items;
          this.count = res.total;
        },
        (error) => {}
      );
    }
  }

  compare(): void {
    if (this.selectedApps.length == 2) {
      const modalRef = this._modal.open(ComparisonDialogComponent, {
        windowClass: "modal-l",
        size: "lg",
        centered: true,
        backdrop: "static",
        keyboard: false,
      });

      modalRef.componentInstance.appsToCompare = this.selectedApps;
    }
  }
}
