import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-preferences-wizard',
  templateUrl: './preferences-wizard.component.html',
  styleUrls: ['./preferences-wizard.component.scss']
})
export class PreferencesWizardComponent implements OnInit {

  animationOptions: AnimationOptions = {
    path: '../../../assets/animations/animacija.json',
    rendererSettings: {
      preserveAspectRatio: "xMaxYMin meet"
    }
  }

  profileType: string;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.profileType = params['type']
      if (this.profileType) {
        this.apiService.getPreferencesProfileType(this.profileType).subscribe();
      }
    })  
  }

  onChooseClick() {
    if (this.profileType) this.router.navigateByUrl(`${this.profileType}/select-preferences`);
  }

}
