<div class="wrapper">
  <div class="d-flex justify-content-between flex-grow-1">
    <a href="/" class="navbar-brand mr-auto">
      <span class="img-space">
        <img
          class="img-logo"
          src="../../../assets/images/stit-s-transparencijom.png"
          alt="logo"
        />
      </span>
      <span class="hide-img">
        <img
          class="img-text"
          width="104px"
          height="29px"
          src="../../../assets/images/ispis-s-transparencijom.png"
          alt="img"
        />
      </span>
    </a>

    <a [href]="cancelLink" class="x-icon">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 1L1 13M1 1L13 13"
          class="svg-icon"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>
  </div>
</div>
