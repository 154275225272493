<div class="bg">
  <div class="header">
    <a routerLink="/" class="navbar-brand">
      <span class="img-space">
        <img class="img-logo" src="../../../assets/images/stit-s-transparencijom.png" alt="logo">
      </span>
      <span class="hide-img">
        <img class="img-text" width="104px" height="29px" src="../../../assets/images/ispis-s-transparencijom.png" alt="logo">
      </span>
    </a>

    <a href="/" class="x-icon">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1L1 13M1 1L13 13" class="svg-icon" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </a>
  </div>

  <div class="img-container">
    <svg class="content" width="1009" height="442" viewBox="0 0 1009 442" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M323.105 347.23H271.543V436H183.066V347.23H0.253906V284.242L188.047 7.67969H271.543V276.918H323.105V347.23ZM183.066 276.918V204.262C183.066 192.152 183.555 174.574 184.531 151.527C185.508 128.48 186.289 115.102 186.875 111.391H184.531C177.305 127.406 168.613 143.031 158.457 158.266L79.9414 276.918H183.066ZM653.867 221.84C653.867 296.645 641.562 352.016 616.953 387.953C592.539 423.891 554.844 441.859 503.867 441.859C454.453 441.859 417.148 423.305 391.953 386.195C366.953 349.086 354.453 294.301 354.453 221.84C354.453 146.254 366.66 90.5898 391.074 54.8477C415.488 18.9102 453.086 0.941406 503.867 0.941406C553.281 0.941406 590.586 19.6914 615.781 57.1914C641.172 94.6914 653.867 149.574 653.867 221.84ZM444.395 221.84C444.395 274.379 448.887 312.074 457.871 334.926C467.051 357.582 482.383 368.91 503.867 368.91C524.961 368.91 540.195 357.387 549.57 334.34C558.945 311.293 563.633 273.793 563.633 221.84C563.633 169.301 558.848 131.605 549.277 108.754C539.902 85.707 524.766 74.1836 503.867 74.1836C482.578 74.1836 467.344 85.707 458.164 108.754C448.984 131.605 444.395 169.301 444.395 221.84ZM1008.65 347.23H957.09V436H868.613V347.23H685.801V284.242L873.594 7.67969H957.09V276.918H1008.65V347.23ZM868.613 276.918V204.262C868.613 192.152 869.102 174.574 870.078 151.527C871.055 128.48 871.836 115.102 872.422 111.391H870.078C862.852 127.406 854.16 143.031 844.004 158.266L765.488 276.918H868.613Z"
        fill="#F7F1E7" />
    </svg>
  </div>

  <div class="container">
    <div class="content-wrapper">

      <div class="titile">
        {{ title }}
      </div>

      <div class="paragraph">
      {{ message }}
      </div>

      <div class="row">
        <div class="col btn-wraper">
          <button mat-raised-button class="btn-content" routerLink="/">Go to Homepage</button>
        </div>
      </div>

    </div>
  </div>

</div>