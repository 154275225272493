import { animate, style, transition, trigger } from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { MatListOption, MatSelectionList } from "@angular/material/list";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationsService } from "angular2-notifications";
import { Subscription } from "rxjs";
import { BusinessAppDetails } from "src/app/models/app.model";
import { ApiService } from "src/app/services/api.service";
import { DeleteAppService } from "src/app/services/delete-app.service";
import { FetchDataService } from "src/app/services/fetch-data.service";
import { AnalysingPolicyComponent } from "../analysing-policy/analysing-policy.component";
import { SharedPrivacyPolicy } from 'src/app/services/shared-data/privacy-policy';

interface DeviceType {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-privacy-policies",
  templateUrl: "./privacy-policies.component.html",
  styleUrls: ["./privacy-policies.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class PrivacyPoliciesComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  @ViewChild("appSelectionList")
  private selectionList?: MatSelectionList;

  @ViewChild("accordion")
  private accordion?: MatAccordion;


  isBusinessUser = false;

  spinnerAction = false;

  deviceTypes: DeviceType[] = [
    { value: "IOS", viewValue: "iOS" },
    { value: "ANDROID", viewValue: "Android" },
  ];

  assets = {
    Contacts: "../../../../../assets/ios-icons/contacts.svg",
    "Health & Fitness": "../../../../../assets/ios-icons/health-info.svg",
    Purchases: "../../../../../assets/ios-icons/purchase-history.svg",
    Location: "../../../../../assets/ios-icons/location.svg",
    "User Content": "../../../../../assets/ios-icons/user-content.svg",
    "Usage Data": "../../../../../assets/ios-icons/usage-data.svg",
    "Contact Info": "../../../../../assets/ios-icons/contact-info.svg",
    "Search History": "../../../../../assets/ios-icons/search-history.svg",
    "Browsing History": "../../../../../assets/ios-icons/browsing-history.svg",
    "Sensitive Info": "../../../../../assets/ios-icons/sensitive-info.svg",
    "Other Data": "../../../../../assets/ios-icons/other-data.svg",
    "Financial Info": "../../../../../assets/ios-icons/financial-info.svg",
    Identifiers: "../../../../../assets/ios-icons/identifiers.svg",
    Diagnostics: "../../../../../assets/ios-icons/diagnostics.svg",
  };

  applications: any;
  appDetails: any;
  appToDisplay: BusinessAppDetails[];
  lastSelectedAppId = -1;

  applicationList: any;
  applicationsToArchive: number;
  selectedType = this.deviceTypes[0].value;

  searchTerm = "";

  archiveSearchTerm = "";

  page = 1;
  pageSize = 5;
  count: number;

  ratio = "6:1";

  isSubmitError = false;
  _isAndroid: boolean;
  fetchSubscription: Subscription;

  @Input() set isAndroid(value: boolean) {
    this._isAndroid = value;
    this.onMobileTypeChange();
  }

  selectedApplications = [];

  emptySearchResults = true;

  constructor(
    private api: ApiService,
    private _notification: NotificationsService,
    private _modal: NgbModal,
    private cdr: ChangeDetectorRef,
    private deleteAppService: DeleteAppService,
    private fetchDataService: FetchDataService,
    private sharedPrivacyPolicy: SharedPrivacyPolicy
  ) {
    this.isBusinessUser = !JSON.parse(localStorage.getItem("individual"));
  }

  ngOnInit() {
    if (this.isBusinessUser) {
      this.getDefaultApplications();
    }
    this.getApplications();
    this.fetchSubscription = this.fetchDataService.archivedPolicies$.subscribe(
      (isDataFetch) => {
        this.getApplications();
      }
    );
  }

  ngOnDestroy(): void {
    this.fetchSubscription.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
    if (this.selectionList && this.selectionList.selectedOptions["_multiple"]) {
      this.selectionList.selectedOptions = new SelectionModel<MatListOption>(
        false
      );
    }
  }

  getDefaultApplications() {
    const params = {
      mobile_type: this.selectedType,
    };

    this.api.getBusinessDefaultApps(params).subscribe(
      (res) => {
        this.applicationList = res;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        // this._notification.error('Get apps error', err.detail);
      }
    );
  }

  onDeviceTypeChange(event) {
    if (this.searchTerm == "") {
      this.getDefaultApplications();
    } else {
      this.onSearch();
    }

    this.appDetails = null;
  }

  addApplications() {
    if (this.selectedApplications.length > 0) {
      this.spinnerAction = true;

      const payload = this.selectedApplications.map((app) => {
        if (this.selectedType == "ANDROID") {
          return {
            label: app.label,
            app_id: app.app_id,
            mobile_type: this.selectedType,
          };
        } else {
          return {
            app_id: app.app_id,
            mobile_type: this.selectedType,
          };
        }
      });

      this.selectedApplications = [];

      this.api.addBusinessApplication(payload).subscribe(
        (res) => {
          this.spinnerAction = false;
          if (res.added.length > 0) {
            this.getApplications();
            const modalRef = this._modal.open(AnalysingPolicyComponent, {
              size: "md",
              centered: true,
              backdrop: "static",
              keyboard: false,
            });
            this._notification.success(
              "Success",
              `We have added ${res.added.length} ${
                res.added.length == 1 ? "application" : "applications"
              } to your archive!`
            );
          } else {
            this._notification.success(
              "Success",
              "Selected applications are already archived!"
            );
          }
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Add application", err.detail);
        }
      );
    } else {
      this.isSubmitError = true;
      setTimeout(() => {
        this.isSubmitError = false;
      }, 5000);
    }
  }

  getApplications() {
    const params = {
      page: this.page,
      size: this.pageSize,
    };

    this.api.getBusinessApplicationList(params, this._isAndroid).subscribe(
      (res) => {
        this.applications = res.items;
        this.count = res.total;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        // this._notification.error('Get apps error', err.detail);
      }
    );
  }

  handlePageChange(event) {
    this.page = event;

    if (this.archiveSearchTerm != "") {
      this.onArchiveSearch(true);
    } else {
      this.getApplications();
    }
  }

  onSearch() {
    if (this.searchTerm == "") {
      this.getDefaultApplications();
    } else {
      const params = {
        term: this.searchTerm,
        mobile_type: this.selectedType,
      };

      this.api.getSearchedApps(params).subscribe(
        (res) => {
          this.applicationList = res;
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Searched application", err.detail);
        }
      );
    }
  }

  onArchiveSearch(isNextPage) {
    if (this.archiveSearchTerm == "") {
      this.page = 1;
      this.getApplications();
    } else {
      if (!isNextPage) {
        this.page = 1;
      }

      const params = {
        term: this.archiveSearchTerm,
        page: this.page,
        size: this.pageSize,
      };

      this.api.searchArchivedApps(params, this._isAndroid).subscribe(
        (res) => {
          if (res.total == 0) {
            this.emptySearchResults = true;
            setTimeout(() => {
              this.emptySearchResults = false;
            }, 5000);
          } else {
            this.applications = res.items;
            this.count = res.total;
          }
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Searched application", err.detail);
        }
      );
    }
  }

  isAppSelected(id) {
    return this.lastSelectedAppId === id;
  }

  onAppSelectionChange(): void {
    if (this.appToDisplay[0]) {
      this.lastSelectedAppId = this.appToDisplay[0].id;

      if (this.accordion) {
        this.accordion.closeAll();
      }

      const params = {
        mobile_type: this.appToDisplay[0].type,
      };

      this.api
        .getBusinessApplicationDetails(
          params,
          this.appToDisplay[0].id,
          this._isAndroid
        )
        .subscribe(
          (res) => {
            this.appDetails = res;
            this.sharedPrivacyPolicy.selectPrivacyPolicy({
              id: res.id,
              type:'privacy polices',
              appName: res.label,
              appLogo: res.image_link,
              link: res.privacy_policies__[0].privacy_policy_link,
              text: res.privacy_policies__[0].privacy_policy_text,
              appPermissions: res.permissions
            })
            console.log(true);
          },
          (error) => {
            let err = JSON.parse(JSON.stringify(error.error));
            this._notification.error("Get app details", err.detail);
          }
        );
    } else {
      this.appDetails = null;
      this.lastSelectedAppId = -1;
      this.sharedPrivacyPolicy.unselectPrivacyPolicy('privacy polices');
    }
  }

  appCount() {
    return this.applications.length < this.pageSize
      ? this.applications.length
      : this.pageSize;
  }

  checkSumbitEror() {
    if (this.isSubmitError) {
      if (this.applications && this.selectedApplications.length > 0) {
        this.isSubmitError = false;
      } else {
        this.isSubmitError = true;
      }
    }
  }

  onMobileTypeChange() {
    this.appDetails = null;
    this.lastSelectedAppId = -1;
    this.appToDisplay = [];
    this.getApplications();
  }

  removeApp(appId, mobileType) {
    const params = {
      mobile_type: mobileType,
    };

    this.api.deleteBusinessApplication(params, appId).subscribe(
      (res) => {
        if (
          this.page > 1 &&
          (this.applications.length - 1) % this.pageSize == 0
        ) {
          this.page -= 1;
        }

        if (this.lastSelectedAppId == appId) {
          this.onMobileTypeChange();
        } else {
          this.getApplications();
        }

        this.deleteAppService.removeApplication(appId);
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Remove application", err.detail);
      }
    );
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  @HostListener("window:resize", ["$event"])
  getScreenHeight(event?) {
    if (window.innerWidth <= 318) {
      this.ratio = "3:1";
    } else if (window.innerWidth <= 346) {
      this.ratio = "4:1";
    } else {
      this.ratio = "6:1";
    }
  }
}
