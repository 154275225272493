import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-wrapper",
  templateUrl: "./login-wrapper.component.html",
  styleUrls: ["./login-wrapper.component.scss"],
})
export class LoginWrapperComponent {
  selectedTabIndex = 0;

  constructor(private _router: Router) {
    if (this._router.getCurrentNavigation().extras.state) {
      let data = this._router.getCurrentNavigation().extras.state.data;
      let individual = data["individual"];
      this.selectedTabIndex = individual ? 0 : 1;
    }
  }
}
