import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';
import { BusinessUsersComponent } from '../business-users/business-users.component';
import { InvitationModalComponent } from '../invitation-modal/invitation-modal.component';

@Component({
  selector: 'app-management-business',
  templateUrl: './management-business.component.html',
  styleUrls: ['./management-business.component.scss']
})
export class ManagementBusinessComponent implements OnInit {

  @ViewChild('users')
  private userList: BusinessUsersComponent

  invitationForm: FormGroup;

  constructor(private api: ApiService, private _modal: NgbModal,private _notification: NotificationsService, private _fb: FormBuilder) { }

  ngOnInit() {
    this.invitationForm = this._fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]]
    })
  }

  openInvitationModal(): void {
    const modalRef = this._modal.open(InvitationModalComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false
    })
    modalRef.componentInstance.individual = false;
    modalRef.result.then((result) => {
      if(result) {
        this.userList.fetchUsers();
      }
    })
  }

}
