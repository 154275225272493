import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthLoginGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isLoggedIn()) {
      if (JSON.parse(localStorage.getItem("individual"))) {
        this.router.navigate(["apps/web"]);
      } else {
        console.log("salji ga na dashboard");
        this.router.navigate(["dashboard"]);
      }
      return false;
    }

    return true;
  }
}
