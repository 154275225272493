import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationsService } from "angular2-notifications";
import { Sentences } from "src/app/models/app.model";
import { ApiService } from "src/app/services/api.service";
import { WebApiService } from "src/app/services/web-api.service";

@Component({
  selector: "app-sentences-dialog",
  templateUrl: "./sentences-dialog.component.html",
  styleUrls: ["./sentences-dialog.component.scss"],
})
export class SentencesDialogComponent implements OnInit {
  constructor(
    private api: ApiService,
    private web_api: WebApiService,
    public modal: NgbActiveModal,
    private _notification: NotificationsService
  ) {}

  @Input() appMetadata: any;
  title: string;
  sentences: Array<Sentences>;

  ngOnInit() {
    if (this.appMetadata.deviationSentences) {
      this.title = this.appMetadata.value;

      const params = {
        mobile_type: this.appMetadata.mobile_type,
      };

      let policyId = null;

      if (this.appMetadata.sandbox) {
        const queryParams = {
          document_id: this.appMetadata.id,
          preference: this.appMetadata.value,
        };

        this.api
          .getDeviationSentences(queryParams, false, this.appMetadata.sandbox)
          .subscribe(
            (res) => {
              this.sentences = res;
            },
            (error) => {
              let err = JSON.parse(JSON.stringify(error.error));
              this._notification.error("Deviation from profile", err.detail);
            }
          );
      } else {
        if (this.appMetadata.type === "web") {
          this.web_api
            .getBusinessApplicationDetails(params, this.appMetadata.id)
            .subscribe(
              (res) => {
                policyId = res.privacy_policies__[0].id;

                const queryParams = JSON.parse(
                  localStorage.getItem("individual")
                )
                  ? {
                      privacy_policy_id: policyId,
                      preference: this.appMetadata.value,
                    }
                  : {
                      privacy_policy_id: policyId,
                      preference: this.appMetadata.value,
                      mobile_type: this.appMetadata.mobile_type,
                    };

                this.web_api
                  .getDeviationSentences(
                    queryParams,
                    this.appMetadata.isAndroid,
                    this.appMetadata.sandbox
                  )
                  .subscribe(
                    (res) => {
                      this.sentences = res;
                    },
                    (error) => {
                      let err = JSON.parse(JSON.stringify(error.error));
                      this._notification.error(
                        "Deviation from profile",
                        err.detail
                      );
                    }
                  );
              },
              (error) => {
                let err = JSON.parse(JSON.stringify(error.error));
                // this._notification.error('Get apps error', err.detail);
              }
            );
        } else {
          this.api
            .getBusinessApplicationDetails(
              params,
              this.appMetadata.id,
              this.appMetadata.isAndroid
            )
            .subscribe(
              (res) => {
                policyId = res.privacy_policies__[0].id;

                const queryParams = JSON.parse(
                  localStorage.getItem("individual")
                )
                  ? {
                      privacy_policy_id: policyId,
                      preference: this.appMetadata.value,
                    }
                  : {
                      privacy_policy_id: policyId,
                      preference: this.appMetadata.value,
                      mobile_type: this.appMetadata.mobile_type,
                    };

                this.api
                  .getDeviationSentences(
                    queryParams,
                    this.appMetadata.isAndroid,
                    this.appMetadata.sandbox
                  )
                  .subscribe(
                    (res) => {
                      this.sentences = res;
                    },
                    (error) => {
                      let err = JSON.parse(JSON.stringify(error.error));
                      this._notification.error(
                        "Deviation from profile",
                        err.detail
                      );
                    }
                  );
              },
              (error) => {
                let err = JSON.parse(JSON.stringify(error.error));
                // this._notification.error('Get apps error', err.detail);
              }
            );
        }
      }
    } else {
      this.title =
        this.appMetadata.value != "yes"
          ? this.appMetadata.value
          : this.appMetadata.attribute;

      const queryParams = JSON.parse(localStorage.getItem("individual"))
        ? {
            category: this.appMetadata.category,
            attribute: this.appMetadata.attribute,
            value: this.appMetadata.value,
            app_id: this.appMetadata.id,
          }
        : this.appMetadata.sandbox
        ? {
            category: this.appMetadata.category,
            attribute: this.appMetadata.attribute,
            value: this.appMetadata.value,
            document_id: this.appMetadata.id,
          }
        : {
            category: this.appMetadata.category,
            attribute: this.appMetadata.attribute,
            value: this.appMetadata.value,
            app_id: this.appMetadata.id,
            mobile_type: this.appMetadata.mobile_type,
          };
      console.log(this.appMetadata.type);
      if (this.appMetadata.type === "web") {
        this.web_api.getSentences(queryParams).subscribe(
          (res) => {
            this.sentences = res;
          },
          (error) => {
            let err = JSON.parse(JSON.stringify(error.error));
            this._notification.error("Sentences", err.detail);
          }
        );
      } else {
        this.api
          .getSentences(
            queryParams,
            this.appMetadata.isAndroid,
            this.appMetadata.sandbox
          )
          .subscribe(
            (res) => {
              this.sentences = res;
            },
            (error) => {
              let err = JSON.parse(JSON.stringify(error.error));
              this._notification.error("Sentences", err.detail);
            }
          );
      }
    }
  }

  cancel(): void {
    this.modal.close();
  }
}
