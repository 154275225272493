import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AppDetails, AppList } from 'src/app/models/app.model';
import { ApiService } from 'src/app/services/api.service';
import { SharedPrivacyPolicy } from 'src/app/services/shared-data/privacy-policy';

@Component({
  selector: 'app-mobile-apps',
  templateUrl: './mobile-apps.component.html',
  styleUrls: ['./mobile-apps.component.scss']
})
export class MobileAppsComponent implements OnInit {

  //@ViewChild(MatSelectionList, { static: true })
 // private selectionList: MatSelectionList;

  appList: Array<AppList>;
  appDetails: AppDetails;
  mobileType: string;
  selectedAppId: number;
  selectedPolicyId: number;
  title: string = 'Andoird archive';
  isAndroid = true

  constructor(
    private api: ApiService,
     private _notification: NotificationsService,
     private route: ActivatedRoute,
     private sharedPrivacyPolicy: SharedPrivacyPolicy
     ) { }

  ngOnInit() {
  //  this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);
    
    this.route.params.subscribe(routeParams => {
      this.onParamsChange(routeParams.type);
    });
  }

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  ngAfterViewInit() {
    this.tabGroup.selectedIndexChange.subscribe((selectedIndex: number) => {
      
      if(selectedIndex === 0){

        this.sharedPrivacyPolicy.setPrivacyPolicyType('privacy polices');
      }
      else{
        this.sharedPrivacyPolicy.setPrivacyPolicyType('analyzed polices');
      }
    });
  }

  onParamsChange(mobileType): void {
    if (mobileType === 'ios') {
      this.isAndroid = false
      this.title = 'iOS archive'
     } else if (mobileType === 'android') { 
       this.title = 'Andoid archive'
       this.isAndroid = true
     }
  }

  // onParamsChange(mobileType): void {
  //   this.mobileType = mobileType;
  //   if (mobileType === 'ios') this.title = 'iOS archive'
  //   else if (mobileType === 'android') this.title = 'Andoid archive'
  //   this.appDetails = null;
  //   this.api.getApplicationList(mobileType === 'android').subscribe(
  //     res => {
  //       this.appList = res;
  //     },
  //     error => {
  //       let err = JSON.parse(JSON.stringify(error.error));
  //       this._notification.error('Retrieve applications error', err.detail);
  //     }
  //   );
  // }

  // onAppSelectionChange(): void {
  //   if (`${this.selectedAppId}`) {
  //     const isAndroid = (this.mobileType === "android") ? true : false;
  //     this.api.getApplicationDetail(isAndroid, this.selectedAppId).subscribe(
  //       res => {
  //         this.appDetails = res;
  //       },
  //       error => {
  //         let err = JSON.parse(JSON.stringify(error.error));
  //         this._notification.error('Retrieve applications error', err.detail);
  //       }
  //     )
  //   } else {
  //     this.appDetails = null;
  //   }
  // }

  // appNotSelected(): boolean {
  //   if (!this.selectedAppId || (`${this.selectedAppId}` === '')) {
  //     return true;
  //   } else {
  //     return false;
  //   };
  // }

}
