<div class="row wrapper bg-white">
  <div class="col-12 header">
    <app-onboard-header></app-onboard-header>
  </div>
  <div class="col-6 empty-state bg-white">
    <img src="../../../assets/images/login_image-min.png" alt="login">
  </div>
  <div class="col-lg-6 col-12">
    <div class="container">
      <div class="content-wrapper">

        <a [routerLink]="['/login']">
          <svg width="16" height="16" class="arrow-icon" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#5F3B19" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </a>

        <p class="title">Reset password</p>

        <p class="message">Enter a new password for your account.</p>

        <form [formGroup]="resetPasswordForm" id="forgotPasswordForm" class="password-form">
          <div class="row field-wrapper">
            <div class="col">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>New password</mat-label>
                  <input #password formControlName="new_password1" matInput [type]="hide_pass1 ? 'password' : 'text'">
                  <button type="button" class="eye-btn" mat-icon-button matSuffix (click)="hide_pass1 = !hide_pass1"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_pass1">
                    <mat-icon>{{hide_pass1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>
                <small
                  *ngIf="resetPasswordForm.get('new_password1').untouched || (!resetPasswordForm.get('new_password1').hasError('required') && resetPasswordForm.get('new_password1').touched)"
                  id="new_password1Help" class="form-text text-muted">
                  8 characters long. At least 1 digit, upper and lowercase letter!
                </small>
                <small
                  *ngIf="resetPasswordForm.get('new_password1').hasError('required') && resetPasswordForm.get('new_password1').touched"
                  id="password1Help1" class="form-text danger">
                  Please enter your password
                </small>
              </div>
            </div>
          </div>
          <div class="row submit-wrapper">
            <div class="col">
              <button ngbAutofocus (click)="reset(resetPasswordForm)" class="mt-2 submit-btn" form="resetPasswordForm"
                mat-raised-button color="primary">Reset password</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- <div class="bg">
  <div class="container container-transform">
    <div class="row">
      <div class="col-3 col-sm-3 col-md-3 offset-md-2 col-lg-3 offset-lg-2 col-xl-3 offset-xl-2">
        <div class="title-mob">
          Reset password
        </div>
        <div class="title">
          Reset password quick and painless
        </div>
      </div>
      <div class="col-12 col-sm-6 offset-sm-1 col-md-6 col-lg-6 offset-lg-0 col-xl-6">
        <div>
          <div class="card-body d-flex flex-column">
            <form [formGroup]="resetFG" class="flex-grow-1" id="resetForm" (ngSubmit)="reset(resetFG)">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <mat-form-field appearance="fill">
                      <mat-label>new password</mat-label>
                      <input formControlName="new_password1" matInput [type]="hide_pass1 ? 'password' : 'text'">
                      <button type="button" class="eye-btn" mat-icon-button matSuffix (click)="hide_pass1 = !hide_pass1"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_pass1">
                        <mat-icon>{{hide_pass1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                    </mat-form-field>
                    <small id="new_password1Help" class="form-text text-muted">
                      8 characters long. At least 1 capital letter and number.
                    </small>
                    <small
                      *ngIf="resetFG.get('new_password1').hasError('required') && resetFG.get('new_password1').touched"
                      id="password1Help1" class="form-text danger">
                      Please enter your password
                    </small>
                    <small
                      *ngIf="resetFG.get('new_password1').hasError('pattern') && resetFG.get('new_password1').touched"
                      id="password1Help2" class="form-text danger">
                      Weak password
                    </small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <mat-form-field appearance="fill">
                      <mat-label>confirm password</mat-label>
                      <input formControlName="new_password2" matInput [type]="hide_pass2? 'password' : 'text'">
                      <button type="button" class="eye-btn" mat-icon-button matSuffix (click)="hide_pass2 = !hide_pass2"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_pass2">
                        <mat-icon>{{hide_pass2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                    </mat-form-field>
                    <small
                      *ngIf="resetFG.get('new_password2').hasError('required') && resetFG.get('new_password2').touched"
                      id="new_password2Help" class="form-text danger">
                      Please confirm your new password
                    </small>
                    <small
                      *ngIf="resetFG.get('new_password2').hasError('pattern') && resetFG.get('new_password2').touched"
                      id="password1Help2" class="form-text danger">
                      Weak password
                    </small>
                    <small
                      *ngIf="resetFG.get('new_password2').hasError('MatchPassword') && resetFG.get('new_password2').touched"
                      id="new_password2Help" class="form-text danger">
                      Password not match
                    </small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button class="mt-2 submit-btn" form="resetForm" mat-raised-button
                    color="primary">Send</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->