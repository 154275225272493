import { Component, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services/api.service";
import { SentencesDialogComponent } from "../sentences-dialog/sentences-dialog.component";

@Component({
  selector: "app-policy-accordance-dialog",
  templateUrl: "./policy-accordance-dialog.component.html",
  styleUrls: ["./policy-accordance-dialog.component.scss"],
})
export class PolicyAccordanceDialogComponent {
  @Input() crossReferenceResults: any;
  @Input() appMetadata: any;

  dataCollectedColors = {
    "computer infomration": "#998616",
    "user online activities": "#D99266",
    financial: "#5F3B19",
    "survey data": "#CCA96A",
    location: "#998616",
    "ip address and device ids": "#CC9B00",
    "cookies and tracking elements": "#D99266",
    "generic personal information": "#5F3B19",
    demographic: "#D99266",
    contact: "#CCA96A",
    "personal identifier": "#CC9B00",
    health: "#5F3B19",
    unspecified: "#998616",
    other: "#D99266",
    "user profile": "#CCA96A",
  };

  collectingPurposeColors = {
    "service operation and security": "#CCA96A",
    research: "#CC9B00",
    marketing: "#D99266",
    "legal requirement": "#CC9B00",
    "perform service": "#5F3B19",
    unspecified: "#998616",
    other: "#D99266",
    advertising: "#998616",
  };

  retentionPeriodColors = {
    indefinitely: "#D99266",
    unspecified: "#998616",
    "stated period": "#CC9B00",
    limited: "#CCA96A",
    other: "#5F3B19",
  };

  constructor(
    public modal: NgbActiveModal,
    private _modal: NgbModal,
    private api: ApiService
  ) {}

  showSentences($event: any, value: string) {
    $event.srcElement.blur();

    const appData = this.appMetadata.sandbox
      ? {
          deviationSentences: true,
          id: this.appMetadata.id,
          value: value,
          sandbox: true,
        }
      : {
          deviationSentences: true,
          id: this.appMetadata.id,
          mobile_type: this.appMetadata.mobile_type,
          type: this.appMetadata.mobile_type,
          isAndroid: this.appMetadata.isAndroid,
          value: value,
          sandbox: false,
        };

    const modalRef = this._modal.open(SentencesDialogComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.appMetadata = appData;
  }

  cancel(): void {
    this.modal.close();
  }
}
