<div class="comparison-wrapper">
  <div class="header">
    <span>Privacy policy comparison</span>
    <button mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="isAppFetched()" class="row">
    <div class="app-details details-left">
      <div class="app-wrapper">
        <div class="app-title">
          <span
            >{{ appsToCompare[0].label }}
            <span class="title-type">({{ appsToCompare[0].type }})</span></span
          >
        </div>
        <div class="app-content-wrapper">
          <mat-accordion multi>
            <mat-expansion-panel
              class="rating-panel"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Ratings</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-prose-rating
                *ngIf="!isEmptyObject(compare.first_app_prose_rating)"
                [isDialogParent]="true"
                [rating]="compare.first_app_prose_rating.privacy_policy_rating"
              ></app-prose-rating>
              <app-user-rating
                *ngIf="!isEmptyObject(compare.first_app_user_rating)"
                [isDialogParent]="true"
                [appRatings]="compare.first_app_user_rating"
                [appId]="appsToCompare[0].id"
                class="app-rating-chart"
              ></app-user-rating>
            </mat-expansion-panel>
            <mat-expansion-panel
              class="rating-panel"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Missing regulations</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span
                *ngIf="
                  compare.first_app_prose_rating.missing_regulations.length ===
                  0
                "
                class="regulation-btn"
              >
                <mat-icon>settings_suggest</mat-icon>
                <span>No missing regulations</span>
              </span>
              <div
                *ngIf="
                  compare.first_app_prose_rating.missing_regulations.length > 0
                "
                class="regulation-wrap"
              >
                <p
                  class="regulations-description"
                  *ngIf="
                    compare.first_app_prose_rating ||
                    compare.first_app_prose_rating.missing_regulations.length >
                      0
                  "
                >
                  Answers to following questions are not clearly addressed
                  within this privacy policy.
                </p>
                <span
                  *ngFor="
                    let regulation of compare.first_app_prose_rating
                      .missing_regulations
                  "
                  class="regulation-btn"
                >
                  <mat-icon>settings_suggest</mat-icon>
                  <span class="regulation-description">{{
                    regulation.individual_description
                  }}</span>
                </span>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >What data is collected and notice on special categories of
                    personal data?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p
                class="what-collects"
                *ngFor="
                  let party of compare.first_app_know_data.what_is_collected
                    | keyvalue
                "
              >
                <span class="party-label">{{ party.key }}:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="let value of party.value; let i = index"
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
              </p>
              <p
                class="not-available"
                *ngIf="
                  isEmptyObject(compare.first_app_know_data.what_is_collected)
                "
              >
                Information is not available.
              </p>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >What is the purpose of data processing and notice on
                    changing that purpose?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Purpose:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_know_data
                        .collecting_purpose;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
              </span>
              <p
                class="not-available"
                *ngIf="
                  compare.first_app_know_data.collecting_purpose === null ||
                  compare.first_app_know_data.collecting_purpose.length === 0
                "
              >
                Information is not available.
              </p>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >What are the legal basis and legitimate interests to
                    process data?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Legal basis:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_know_data.legal_basis;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.first_app_know_data.legal_basis === null ||
                    compare.first_app_know_data.legal_basis.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>How long is data held - retention period?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Retention period:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_know_data.retention_period;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.first_app_know_data.retention_period === null ||
                    compare.first_app_know_data.retention_period.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >When will policy change and how will they notify You?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p
                class="what-collects"
                *ngFor="
                  let party of compare.first_app_know_data.when_and_how
                    | keyvalue
                "
              >
                <span class="party-label">{{ party.key }}:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="let value of party.value; let i = index"
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
              </p>
              <p
                class="not-available"
                *ngIf="isEmptyObject(compare.first_app_know_data.when_and_how)"
              >
                Information is not available.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Can you access your data?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Access data:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_control_data.access;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.first_app_control_data.access === null ||
                    compare.first_app_control_data.access.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Can you transfer your data elsewhere?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Portability:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_control_data.portability;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.first_app_control_data.portability === null ||
                    compare.first_app_control_data.portability.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Can you object the processing of your data?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Can you object:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_control_data
                        .object_processing;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.first_app_control_data.object_processing === null ||
                    compare.first_app_control_data.object_processing.length ===
                      0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Can you erase your data?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Erase:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_control_data.erase;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.first_app_control_data.erase === null ||
                    compare.first_app_control_data.erase.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Can you rectify your data?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Rectify:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_control_data.rectify;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.first_app_control_data.rectify === null ||
                    compare.first_app_control_data.rectify.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >Do you have option to provide/withdraw consent for data
                    processing?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Consent and withdrawal:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_control_data
                        .consent_and_withdrawal;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.first_app_control_data.consent_and_withdrawal ===
                      null ||
                    compare.first_app_control_data.consent_and_withdrawal
                      .length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >Have they provided contact information for objecting data
                    processing?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Contact:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_control_data.contact;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.first_app_control_data.contact === null ||
                    compare.first_app_control_data.contact.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>

            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >What security measures are applied to protect your
                    data?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Security measures:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.first_app_security_data
                        .security_measures;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.first_app_security_data.security_measures ===
                      null ||
                    compare.first_app_security_data.security_measures.length ===
                      0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span>Is there a note on specific audience?</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span class="multiline">
              <span class="party-label">Children:</span>
              <mat-chip-list>
                <mat-chip
                  disabled
                  disableRipple
                  #t
                  *ngFor="
                    let value of compare.first_app_audience_data
                      .specific_audience;
                    let i = index
                  "
                  [style.background]="getColor(t, i)"
                  >{{ value }}</mat-chip
                >
              </mat-chip-list>
              <p
                class="not-available"
                *ngIf="
                  compare.first_app_audience_data.specific_audience === null ||
                  compare.first_app_audience_data.specific_audience.length === 0
                "
              >
                Information is not available.
              </p>
            </span>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
    <div *ngIf="!isEmptyObject(compare)" class="app-details details-right">
      <div class="app-wrapper">
        <div class="app-title">
          <span
            >{{ appsToCompare[1].label }}
            <span class="title-type">({{ appsToCompare[1].type }})</span></span
          >
        </div>
        <div class="app-content-wrapper">
          <mat-accordion multi>
            <mat-expansion-panel
              class="rating-panel"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Ratings</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-prose-rating
                *ngIf="compare.second_app_prose_rating"
                [isDialogParent]="true"
                [rating]="compare.second_app_prose_rating.privacy_policy_rating"
              ></app-prose-rating>
              <app-user-rating
                *ngIf="compare.second_app_user_rating"
                [isDialogParent]="true"
                [appRatings]="compare.second_app_user_rating"
                [appId]="appsToCompare[1].id"
                class="app-rating-chart"
              ></app-user-rating>
            </mat-expansion-panel>
            <mat-expansion-panel
              class="rating-panel"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Missing regulations</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span
                *ngIf="
                  !compare.second_app_prose_rating ||
                  compare.second_app_prose_rating.missing_regulations.length ===
                    0
                "
                class="regulation-btn"
              >
                <mat-icon>settings_suggest</mat-icon>
                <span>No missing regulations</span>
              </span>
              <div
                *ngIf="
                  compare.second_app_prose_rating &&
                  compare.second_app_prose_rating.missing_regulations.length > 0
                "
                class="regulation-wrap"
              >
                <p
                  class="regulations-description"
                  *ngIf="
                    compare.second_app_prose_rating ||
                    compare.second_app_prose_rating.missing_regulations.length >
                      0
                  "
                >
                  Answers to following questions are not clearly addressed
                  within this privacy policy.
                </p>
                <span
                  *ngFor="
                    let regulation of compare.second_app_prose_rating
                      .missing_regulations
                  "
                  class="regulation-btn"
                >
                  <mat-icon>settings_suggest</mat-icon>
                  <span class="regulation-description">{{
                    regulation.individual_description
                  }}</span>
                </span>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >What data is collected and notice on special categories of
                    personal data?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p
                class="what-collects"
                *ngFor="
                  let party of compare.second_app_know_data.what_is_collected
                    | keyvalue
                "
              >
                <span class="party-label">{{ party.key }}:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="let value of party.value; let i = index"
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
              </p>
              <p
                class="not-available"
                *ngIf="
                  isEmptyObject(compare.second_app_know_data.what_is_collected)
                "
              >
                Information is not available.
              </p>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >What is the purpose of data processing and notice on
                    changing that purpose?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Purpose:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_know_data
                        .collecting_purpose;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
              </span>
              <p
                class="not-available"
                *ngIf="
                  compare.second_app_know_data.collecting_purpose === null ||
                  compare.second_app_know_data.collecting_purpose.length === 0
                "
              >
                Information is not available.
              </p>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >What are the legal basis and legitimate interests to
                    process data?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Legal basis:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_know_data.legal_basis;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_know_data.legal_basis === null ||
                    compare.second_app_know_data.legal_basis.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>How long is data held - retention period?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Retention period:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_know_data
                        .retention_period;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_know_data.retention_period === null ||
                    compare.second_app_know_data.retention_period.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >When will policy change and how will they notify You?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p
                class="what-collects"
                *ngFor="
                  let party of compare.second_app_know_data.when_and_how
                    | keyvalue
                "
              >
                <span class="party-label">{{ party.key }}:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="let value of party.value; let i = index"
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
              </p>
              <p
                class="not-available"
                *ngIf="isEmptyObject(compare.second_app_know_data.when_and_how)"
              >
                Information is not available.
              </p>
            </mat-expansion-panel>

            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Can you access your data?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Access data:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_control_data.access;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_control_data.access === null ||
                    compare.second_app_control_data.access.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Can you transfer your data elsewhere?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Portability:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_control_data.portability;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_control_data.portability === null ||
                    compare.second_app_control_data.portability.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Can you object the processing of your data?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Can you object:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_control_data
                        .object_processing;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_control_data.object_processing ===
                      null ||
                    compare.second_app_control_data.object_processing.length ===
                      0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Can you erase your data?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Erase:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_control_data.erase;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_control_data.erase === null ||
                    compare.second_app_control_data.erase.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Can you rectify your data?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Rectify:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_control_data.rectify;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_control_data.rectify === null ||
                    compare.second_app_control_data.rectify.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >Do you have option to provide/withdraw consent for data
                    processing?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Consent and withdrawal:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_control_data
                        .consent_and_withdrawal;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_control_data.consent_and_withdrawal ===
                      null ||
                    compare.second_app_control_data.consent_and_withdrawal
                      .length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >Have they provided contact information for objecting data
                    processing?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Contact:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_control_data.contact;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_control_data.contact === null ||
                    compare.second_app_control_data.contact.length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>

            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span
                    >What security measures are applied to protect your
                    data?</span
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Security measures:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_security_data
                        .security_measures;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_security_data.security_measures ===
                      null ||
                    compare.second_app_security_data.security_measures
                      .length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>

            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Is there a note on specific audience?</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="multiline">
                <span class="party-label">Children:</span>
                <mat-chip-list>
                  <mat-chip
                    disabled
                    disableRipple
                    #t
                    *ngFor="
                      let value of compare.second_app_audience_data
                        .specific_audience;
                      let i = index
                    "
                    [style.background]="getColor(t, i)"
                    >{{ value }}</mat-chip
                  >
                </mat-chip-list>
                <p
                  class="not-available"
                  *ngIf="
                    compare.second_app_audience_data.specific_audience ===
                      null ||
                    compare.second_app_audience_data.specific_audience
                      .length === 0
                  "
                >
                  Information is not available.
                </p>
              </span>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <button class="mt-2 submit-btn" mat-raised-button (click)="cancel()">
      OK
    </button>
  </div>
</div>
