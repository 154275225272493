<div class="wrapper">
    <div class="header">
        <div class="title" *ngIf="!uploaded">Privacy policy</div>
        <div class="title" *ngIf="uploaded">Getting your report</div>
        <a class="close-btn" (click)="close()" ngbAutofocus>
            <svg width="12" height="12" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1.5L1 13.5M1 1.5L13 13.5" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>                
        </a>
    </div>
    <div class="content">
        <form [formGroup]="uploadForm" class="upload-file-form" id="uploadFileForm" *ngIf="!uploaded">
            <div class="form-row">
                <mat-form-field appearance="outline">
                    <mat-label>Policy name</mat-label>
                    <input matInput placeholder="Policy name" autocomplete="off" formControlName="policy_name"/>
                    <mat-hint
                    *ngIf="uploadForm.get('policy_name').hasError('required') && uploadForm.get('policy_name').touched" class="danger">
                        Policy name is required
                    </mat-hint>
                    <mat-hint
                    *ngIf="uploadForm.get('policy_name').hasError('pattern') && uploadForm.get('policy_name').touched" class="danger">
                        Name can only contain alphanumerical characters, spaces, dot and dash
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="form-row">
                <mat-form-field appearance="outline">
                    <mat-label>Version</mat-label>
                    <input matInput placeholder="Version" autocomplete="off" formControlName="policy_version"/>
                    <mat-hint
                    *ngIf="uploadForm.get('policy_version').hasError('required') && uploadForm.get('policy_version').touched" class="danger">
                        Policy version is required
                    </mat-hint>
                    <mat-hint
                    *ngIf="uploadForm.get('policy_version').hasError('pattern') && uploadForm.get('policy_version').touched" class="danger">
                        Name can only contain alphanumerical characters, spaces, dots and dashes
                    </mat-hint>
                </mat-form-field>
            </div>
        </form>
        <div *ngIf="uploaded" class="uploaded-text">
            Your privacy policy is being uploaded.<br>
            You will be notified by email when the report is ready.<br>
            Thank you for your patience!
        </div>
    </div>
    <div class="footer">
        <button class="upload-btn" type="submit" (click)="submit()" *ngIf="!uploaded">
            Upload
        </button>
        <button class="upload-btn" *ngIf="uploaded" (click)="okClick()">
            OK
        </button>
    </div>
</div>