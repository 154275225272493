<div class="mobile-wrapper">
  <div class="section-title">
    <span>
      {{ title }}
    </span>
  </div>
  <mat-tab-group
    #tabGroup
    dynamicHeight
    class="tab-group dashboard-tabs"
    mat-align-tabs="start"
  >
    <mat-tab label="My privacy policies">
      <div class="scroll">
        <app-privacy-policies [isAndroid]="isAndroid"></app-privacy-policies>
      </div>
    </mat-tab>
    <mat-tab label="My analysed policies">
      <div class="scroll">
        <app-analysed-policies [isAndroid]="isAndroid"></app-analysed-policies>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
