<div class="position-relative h-100 bg-white">
  <div class="position-absolute header">
    <app-onboard-header></app-onboard-header>
  </div>
  <div class="row position-relative h-100">
    <div class="col-6 empty-state bg-white">
      <div class="image-wrapper">
        <img src="../../../assets/images/login_image-min.png" alt="login" />
      </div>
    </div>
    <div class="col-lg-6 col-sm-12 col-12">
      <div class="container">
        <div class="content-wrapper">
          <mat-tab-group [selectedIndex]="selectedTabIndex" class="tab-group" mat-align-tabs="start">
            <mat-tab label="PERSONAL">
              <app-login [individual]="true"></app-login>
            </mat-tab>
            <mat-tab class="tab-space" label="BUSINESS">
              <app-login [individual]="false"></app-login>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>