import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { ApiService } from "../../services/api.service";

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"],
})
export class VerifyEmailComponent implements OnInit {
  token: string;
  private individual: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private _notification: NotificationsService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.token = params["token"];
    });

    this.route.data.subscribe((data) => {
      this.individual = data["individual"];
    });

    this.verifyEmail(this.token);
  }

  verifyEmail(token: string) {
    this.api.verifyEmail(token, this.individual).subscribe({
      error: (e) => {
        this._notification.error("Verify email error", e.error.detail);
        this.router.navigate(["/not-found"]);
      },
    });
  }

  goToSignIn() {
    const data = {
      individual: this.individual,
    };

    let navigationExtras: NavigationExtras = {
      state: {
        data: data,
      },
    };

    this.router.navigate(["/login"], navigationExtras);
    // this.router.navigate(['/']);
  }
}
