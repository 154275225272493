<div *ngIf="sharedPrivacyPolicy.getActivePrivacyPolicy()" class="chatbot">
    <div (click)="toggleChatbot()" class="circle">
        <img src="../../../../assets/imgs/message-icon.svg" alt="message icon" />
    </div>
    <div *ngIf="isChatbotOpened" class="chat">
        <div class="header">
            <img *ngIf="activeScreen === 'chat' && shouldDisplayArrowBack" (click)="changeScreen()" class="back-arrow-icon" src="../../../../assets/imgs/back-arrow.svg" alt="back arrow" />
            <img class="logo-img" src="../../../../assets/imgs/logo.svg" alt="logo" />
            <p>pro se</p>
            <img (click)="toggleChatbot()" class="close-icon" src="../../../../assets/imgs/x-icon.svg" alt="close icon" />
        </div>
        <div *ngIf="activeScreen === 'chat' " class="main">
            <div *ngIf="sharedPrivacyPolicy.getActivePrivacyPolicy()" class="selected-app">
                <img [src]="sharedPrivacyPolicy.getActivePrivacyPolicy()?.appLogo ? sharedPrivacyPolicy.getActivePrivacyPolicy().appLogo : '../../../../assets/imgs/document.png' " alt="document" />
                <div class="info">
                    <p class="name" [maxLength]="10">{{sharedPrivacyPolicy.getActivePrivacyPolicy()?.appName}}</p>
                    <p class="selected-app-text">selected app</p>
                </div>
                <a *ngIf="sharedPrivacyPolicy.getActivePrivacyPolicy()?.link" [href]="sharedPrivacyPolicy.getActivePrivacyPolicy()?.link"  class="privacy-policy-link" target="_blank">Privacy Policy</a>
            </div>
            <div class="messages" #messagesEl>
                <p *ngIf="isChatbotTyping" class="typing">processing...</p>
                <div *ngIf="messages.length === 1" class="questions">
                    <p (click)="sendMessage(question.context)" class="question" *ngFor="let question of questions">{{question.name}}</p>
                </div>
                <div *ngFor="let message of messages" class="message-wrapper" [ngStyle]="{display: (message.content !== questions[0].context || message.content !== questions[1].context) ? 'flex' : 'none'}">
                    <div class="message">
                        <img *ngIf="message.role === 'assistant'" src="../../../../assets/imgs/robot.svg" alt="robot" />
                        <div [ngStyle]="{'margin-left': (message.role === 'assistant') ? '' : 'auto'}">
                            <p *ngIf="message.role === 'assistant' " class="message-from">{{chatbotName}}</p>
                            <p [title]="formatDate(message.time)" [ngClass]="(message.role === 'assistant') ? 'bot-message-content' : 'my-message-content'">{{message.content}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <form [formGroup]="messageForm" (ngSubmit)="sendMessage()">
                <input #messageInput type="text" placeholder="Type a message..." formControlName="message">
                <button [ngStyle]="{'pointer-events': (isFormDisabled) ? 'none' : 'auto'}" type="submit">
                    <img src="../../../../assets/imgs/send-icon.svg" alt="send icon">
                </button>
            </form>
        </div>
        <div *ngIf="activeScreen === 'conversations' " class="conversations">
            <h1>My conversations</h1>
            <div class="conversation-list">
                <div *ngFor="let conversation of conversations" class="conversation">
                    <img src="../../../../assets/imgs/robot.svg" alt="robot">
                    <div (click)="selectConversation(conversation.id)" class="message">
                        <div class="time-wrapper">
                            <p class="prose">Pro Se</p>
                            <p class="time">{{formatDate(conversation.messages[conversation.messages.length - 1].time,'date')}}</p>
                        </div>
                        <p class="last-message">{{ conversation.messages[0].role === 'assistant' ? chatbotName : 'Me' }}: {{ trimMessage(conversation.messages[0].content) }}</p>
                    </div>
                    <img (mouseover)="handleMouseOver($event)" (mouseout)="handleMouseOut($event)" (click)="deleteConversation(conversation.id)" [src]=" '../../../../assets/imgs/delete-icon.svg' " alt="delete icon"/>
                </div>
            </div>
            <button (click)="makeNewChat()">New Conversation</button>
        </div>
    </div>
</div>
