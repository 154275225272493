<div class="chooser-wrapper">
  <div class="header">
    <span class="title">Compare policies</span>
    <button mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="row dialog-content">
    <div class="app-selection-list">
      <div class="choose-list-wrapper">
        <span class="notice"
          >Please, select two policies that you want to compare ({{
            this.selectedApps.length
          }}/2).</span
        >
        <div class="archive-search-wrapper">
          <mat-form-field class="search-field" appearance="outline">
            <input
              matInput
              (keyup.enter)="onSearch(false)"
              [(ngModel)]="searchTerm"
            />
            <button matSuffix mat-icon-button (click)="onSearch(false)">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                preserveAspectRatio="xMaxYMax meet"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                  stroke="#CCA96A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19 19L14.65 14.65"
                  stroke="#CCA96A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </mat-form-field>
        </div>
        <mat-selection-list
          #appSelectionList2="matSelectionList"
          (selectionChange)="onAppSelectionChange($event)"
          class="app-list"
        >
          <mat-list-option
            [selected]="isAppSelected(app.id)"
            *ngFor="
              let app of applications
                | paginate
                  : {
                      id: 'compareAppPagination',
                      itemsPerPage: pageSize,
                      currentPage: page,
                      totalItems: count
                    }
            "
            checkbox="false"
            [value]="app"
          >
            <span class="option-wrapper">
              <img class="app-icon" [src]="app.image_link" alt="app icon" />
              <p class="app-label">{{ app.label }}</p>
              <p class="app-type" *ngIf="app.type === 'ANDROID'; else elseType">
                (Android)
              </p>
              <ng-template #elseType><p class="app-type">(iOS)</p></ng-template>
            </span>
          </mat-list-option>
        </mat-selection-list>
      </div>
      <div class="footer">
        <pagination-controls
          id="compareAppPagination"
          maxSize="5"
          responsive="true"
          previousLabel=""
          nextLabel=""
          (pageChange)="handlePageChange($event)"
        ></pagination-controls>
      </div>
    </div>
  </div>
  <div class="footer">
    <button class="mt-2 submit-btn" mat-raised-button (click)="compare()">
      Compare
    </button>
  </div>
</div>
