<div class="row wrapper bg-white">
    <div class="col-12 header">
        <app-onboard-header></app-onboard-header>
    </div>
    <div class="col-6 empty-state bg-white">
        <img src="../../../assets/images/login_image-min.png" alt="login">
    </div>
    <div class="col-lg-6 col-12">
        <div class="container">
            <div class="content-wrapper">

                <a (click)="backAction()">
                    <svg width="16" height="16" class="arrow-icon" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#5F3B19" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </a>

                <div class="title">
                    <p>Forgot your password?</p>
                </div>

                <div class="message">
                    <p>Enter the email address you used when you joined and
                        we'll send you a password reset link.</p>
                </div>

                <form [formGroup]="forgotPasswordForm" id="forgotPasswordForm" class="password-form">
                    <div class="row field-wrapper">
                        <div class="col">
                            <div class="form-group">
                                <mat-form-field appearance="outline">
                                    <mat-label>Email address</mat-label>
                                    <input formControlName="email" autocomplete="off" matInput
                                        placeholder="example@mail.com">
                                </mat-form-field>
                                <small
                                    *ngIf="forgotPasswordForm.get('email').hasError('email') && !forgotPasswordForm.get('email').hasError('required') && forgotPasswordForm.get('email').touched"
                                    id="emailHelp" class="form-text danger">
                                    Please enter a valid email address
                                </small>
                                <small
                                    *ngIf="forgotPasswordForm.get('email').hasError('required') && forgotPasswordForm.get('email').touched"
                                    id="emailHelp" class="form-text danger">
                                    Email is required
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="row submit-wrapper">
                        <div class="col">
                            <button ngbAutofocus (click)="forgotPassword(forgotPasswordForm)" class="mt-2 submit-btn"
                                form="forgotPasswordForm" mat-raised-button color="primary">Send reset link</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>