<div>
  <div class="section-title">
    <span class="parent-title">
      Settings
      <svg
        width="7"
        height="11"
        viewBox="0 0 7 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="
          background-size: 4.5px 9px;
          margin-left: 14px;
          margin-right: 14px;
        "
      >
        <path
          d="M1 10L5.5 5.5L1 1"
          stroke="#6B6B6B"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <span> Profile </span>
  </div>
  <mat-tab-group class="tab-group profile-tabs" mat-align-tabs="start">
    <mat-tab label="Basic info">
      <div class="row wrapper">
        <div
          class="col-xl-6 col-lg-7 col-md-7 col-sm-10 col-xs-12 content-wrapper"
        >
          <form [formGroup]="userForm" id="basicInfo" class="form">
            <p class="card-title">Basic info</p>
            <div class="row labeled-field-wrapper">
              <div class="col">
                <div class="form-group">
                  <p class="label">First name</p>
                  <mat-form-field appearance="outline">
                    <!-- <mat-label>First name</mat-label> -->
                    <input
                      formControlName="first_name"
                      autocomplete="off"
                      matInput
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row field-wrapper">
              <div class="col">
                <div class="form-group">
                  <p class="label">Last name</p>
                  <mat-form-field appearance="outline">
                    <!-- <mat-label>Last name</mat-label> -->
                    <input
                      formControlName="last_name"
                      autocomplete="off"
                      matInput
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row btn-wrap">
              <div class="col">
                <button
                  ngbAutofocus
                  class="mt-2 submit-btn"
                  form="userForm"
                  mat-raised-button
                  color="primary"
                  (click)="updateProfile(userForm)"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-tab>
    <mat-tab class="tab-space" label="Change password">
      <div class="row wrapper">
        <div
          class="col-xl-6 col-lg-7 col-md-7 col-sm-10 col-xs-12 content-wrapper"
        >
          <form [formGroup]="passwordForm" class="form" id="passwordForm">
            <p class="card-title">Change password</p>
            <div class="row password-field-wrapper">
              <div class="col">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>New password</mat-label>
                    <input
                      formControlName="new_password1"
                      matInput
                      [type]="hide_pass1 ? 'password' : 'text'"
                    />
                    <button
                      type="button"
                      class="eye-btn"
                      mat-icon-button
                      matSuffix
                      (click)="hide_pass1 = !hide_pass1"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide_pass1"
                    >
                      <mat-icon>{{
                        hide_pass1 ? "visibility_off" : "visibility"
                      }}</mat-icon>
                    </button>
                    <mat-error
                      *ngIf="
                        passwordForm
                          .get('new_password1')
                          .hasError('required') &&
                        passwordForm.get('new_password1').touched
                      "
                      id="password1Help1"
                      class="danger"
                    >
                      Please enter your password
                    </mat-error>
                    <mat-hint
                      *ngIf="
                        passwordForm.get('new_password1').untouched ||
                        (!passwordForm
                          .get('new_password1')
                          .hasError('required') &&
                          passwordForm.get('new_password1').touched)
                      "
                      id="new_password1Help2"
                      class="text-muted"
                    >
                      8 characters long. At least 1 digit, upper and lowercase
                      letter!
                    </mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row field-wrapper">
              <div class="col">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Repeat password</mat-label>
                    <input
                      formControlName="new_password2"
                      matInput
                      [type]="hide_pass2 ? 'password' : 'text'"
                    />
                    <button
                      type="button"
                      class="eye-btn"
                      mat-icon-button
                      matSuffix
                      (click)="hide_pass2 = !hide_pass2"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide_pass2"
                    >
                      <mat-icon>{{
                        hide_pass2 ? "visibility_off" : "visibility"
                      }}</mat-icon>
                    </button>
                    <mat-error
                      *ngIf="
                        passwordForm
                          .get('new_password2')
                          .hasError('required') &&
                        passwordForm.get('new_password2').touched
                      "
                      id="new_password2Help1"
                      class="danger"
                    >
                      Please confirm your new password
                    </mat-error>
                    <mat-error
                      *ngIf="
                        !passwordForm
                          .get('new_password2')
                          .hasError('required') &&
                        passwordForm
                          .get('new_password2')
                          .hasError('MatchPassword') &&
                        passwordForm.get('new_password2').touched
                      "
                      id="new_password2Help2"
                      class="danger"
                    >
                      Passwords don't match
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button
                  class="mt-2 submit-btn"
                  form="passwordForm"
                  mat-raised-button
                  color="primary"
                  (click)="changePassword(passwordForm)"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-tab>
    <mat-tab class="tab-space" label="Deactivate account">
      <div class="row wrapper">
        <div
          class="col-xl-6 col-lg-7 col-md-7 col-sm-10 col-xs-12 content-wrapper"
        >
          <form [formGroup]="deactivateForm" class="form" id="deactivateForm">
            <p class="card-title">Deactivate account</p>
            <div class="row password-field-wrapper">
              <div class="col">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input
                      formControlName="password"
                      matInput
                      [type]="hide_pass3 ? 'password' : 'text'"
                    />
                    <button
                      type="button"
                      class="eye-btn"
                      mat-icon-button
                      matSuffix
                      (click)="hide_pass3 = !hide_pass3"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide_pass3"
                    >
                      <mat-icon>{{
                        hide_pass3 ? "visibility_off" : "visibility"
                      }}</mat-icon>
                    </button>
                    <mat-error
                      *ngIf="
                        deactivateForm.get('password').hasError('required') &&
                        deactivateForm.get('password').touched
                      "
                      id="password3Help1"
                      class="danger"
                    >
                      Please enter your password
                    </mat-error>
                    <mat-hint
                      *ngIf="
                        deactivateForm.get('password').untouched ||
                        (!deactivateForm.get('password').hasError('required') &&
                          deactivateForm.get('password').touched)
                      "
                      id="new_password3Help2"
                      class="text-muted"
                    >
                      8 characters long. At least 1 digit, upper and lowercase
                      letter!
                    </mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button
                  class="mt-2 submit-btn-deactivate"
                  form="deactivateForm"
                  mat-raised-button
                  color="primary"
                  (click)="deactivateAccount()"
                >
                  Deactivate
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
