import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-unsubscribe",
  templateUrl: "./unsubscribe.component.html",
  styleUrls: ["./unsubscribe.component.scss"],
})
export class UnsubscribeComponent implements OnInit {
  token: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly _notification: NotificationsService,
    private readonly api: ApiService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.token = params["token"];
    });

    this.unsubscribe(this.token);
  }

  unsubscribe(token: string) {
    this.api.unsubscribe(token).subscribe({
      next: (v) => this._notification.success("Unsubscribed", v.msg),
      error: (e) =>
        this._notification.error("Unsubscribe error", e.error.detail),
    });
  }
}
