<div>
    <div class="section-title">
        <span class="parent-title"> Settings
            <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg"
            style="background-size: 4.5px 9px; margin-left: 14px; margin-right: 14px">
            <path d="M1 10L5.5 5.5L1 1" stroke="#6B6B6B" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
        </span>
        <span>
            Privacy preferences
        </span>
    </div>
    <div class="content-wrapper">
        <div class="type-selector">
            <div class="current-profile">
                <div class="title">Your current profile type:</div>
                <div class="animation-wrapper" *ngIf="currentData.profile !== ''">
                    <div class="animation-details">
                        <div class="row" style="height: 100%; width: 100%;">
                            <div class="col-7 profile-data">
                                <div class="profile-name">{{currentData.profile}}</div>
                                <div class="profile-description">{{currentData.description}}</div>
                            </div>
                            <div class="col-5">
                                <img class="preference-image" [src]="currentData.imageSrc" alt="preference image">
                            </div>
                        </div> 
                    </div>
                    <ng-lottie
                        [height]="'100%'"
                        [width]="'100%'"
                        [options]="currentOptions"
                    ></ng-lottie>
                </div>
            </div>
            <div class="available-profiles">
                <div class="title">Choose a different profile type:</div>
                <div class="animation-wrapper" *ngIf="'Guarded' !== currentData.profile" 
                (click)="selectForChange('EXTREME')" [ngClass]="(selectedForChange === 'EXTREME') ? 'selected' : ''">
                    <div class="animation-details">
                        <div class="row" style="height: 100%; width: 100%;">
                            <div class="col-7 profile-data">
                                <div class="profile-name">Guarded</div>
                                <div class="profile-description">I'm extremely protective of my privacy</div>
                            </div>
                            <div class="col-5">
                                <img class="preference-image" src="../../../../assets/images/guarded_character_svg.svg" alt="guarded character">
                            </div>
                        </div> 
                    </div>
                    <ng-lottie
                        [height]="'100%'"
                        [width]="'100%'"
                        [options]="guarderAnimationOptions"
                    ></ng-lottie>
                </div>
                <div class="animation-wrapper" *ngIf="'Vigilant' !== currentData.profile"
                (click)="selectForChange('MODERATE')" [ngClass]="(selectedForChange === 'MODERATE') ? 'selected' : ''">
                    <div class="animation-details">
                        <div class="row" style="height: 100%; width: 100%;">
                            <div class="col-7 profile-data">
                                <div class="profile-name">Vigilant</div>
                                <div class="profile-description">I care about protecting my privacy</div>
                            </div>
                            <div class="col-5">
                                <img class="preference-image" src="../../../../assets/images/vigilant-character-svg.svg" alt="vigilant character">
                            </div>
                        </div> 
                    </div>
                    <ng-lottie
                        [height]="'100%'"
                        [width]="'100%'"
                        [options]="vigilantAnimationOptions"
                    ></ng-lottie>
                </div>
                <div class="animation-wrapper" *ngIf="'Laid-back' !== currentData.profile"
                (click)="selectForChange('RELAXED')" [ngClass]="(selectedForChange === 'RELAXED') ? 'selected' : ''">
                    <div class="animation-details">
                        <div class="row" style="height: 100%; width: 100%;">
                            <div class="col-7 profile-data">
                                <div class="profile-name">Laid-back</div>
                                <div class="profile-description">I'm not too adamant about privacy protection</div>
                            </div>
                            <div class="col-5">
                                <img class="preference-image" src="../../../../assets/images/laid-back-character-svg.svg" alt="laid back character">
                            </div>
                        </div> 
                    </div>
                    <ng-lottie
                        [height]="'100%'"
                        [width]="'100%'"
                        [options]="laidbackAnimationOptions"
                    ></ng-lottie>
                </div>
                <div class="animation-wrapper" *ngIf="'Custom' !== currentData.profile"
                (click)="selectForChange('CUSTOM')" [ngClass]="(selectedForChange === 'CUSTOM') ? 'selected' : ''">
                    <div class="animation-details">
                        <div class="row" style="height: 100%; width: 100%;">
                            <div class="col-7 profile-data">
                                <div class="profile-name">Custom</div>
                                <div class="profile-description">I want to create my own profile type</div>
                            </div>
                            <div class="col-5">
                                <img class="preference-image" src="../../../../assets/images/custom-character-svg.svg" alt="custom character">
                            </div>
                        </div> 
                    </div>
                    <ng-lottie
                        [height]="'100%'"
                        [width]="'100%'"
                        [options]="customAnimationOptions"
                    ></ng-lottie>
                </div>
            </div>
        </div>
        <div class="selected-type">
            <div class="title">{{selectedForChange !== 'CUSTOM' ? 'That means that third party apps can collect:' : 'Create your own profile type' }}</div>
            <div *ngIf="profileResponse && selectedForChange !== 'CUSTOM'">
                <div>
                    <div class="subtitle">Additional data:</div>
                    <mat-chip-list>
                        <mat-chip *ngFor="let what of dataCollected" [style.background]="whatColors[what]">{{what}}</mat-chip>
                    </mat-chip-list>
                </div>
                <div class="middle-chips">
                    <div class="subtitle">For the purpose of:</div>
                    <mat-chip-list>
                        <mat-chip *ngFor="let purpose of collectingPurpose" [style.background]="purposeColors[purpose]">{{purpose}}</mat-chip>
                    </mat-chip-list>
                </div>
                <div>
                    <div class="subtitle">With the retention period:</div>
                    <mat-chip-list>
                        <mat-chip *ngFor="let retention of retentionPeriod" [style.background]="retentionColors[retention]">{{retention}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="button-wrapper">
                <button *ngIf="currentData.profile === 'Custom' && selectedForChange === ''" class="edit-btn" [routerLink]="'/'+ userType +'/create-preferences'">Edit preferences</button>
                <button *ngIf="selectedForChange === 'CUSTOM'" class="create-btn" [routerLink]="'/'+ userType +'/create-preferences'">Create</button>
                <div class="change-buttons" *ngIf="selectedForChange !== ''">
                    <a class="cancel-btn" (click)="cancelChange()">Cancel</a>
                    <button class="save-btn" *ngIf="selectedForChange !== 'CUSTOM'" (click)="saveChanges()">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</div>
