import { Component, OnInit, ViewEncapsulation, ViewChildren, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['../../../assets/scss/tw.scss', './terms-and-conditions.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(public api: ApiService, private location: Location, private route: ActivatedRoute, private router: Router) {

  }

  @ViewChildren('listItems_termsAndConditions') listItems_termsAndConditions
  @ViewChildren('arrows') arrows

  @Input() data: any;
  termsAndConditionItems = { sections: [] }
  acceptation: Boolean = false

  ngOnInit(): void {

    this.api.getTermsAndConditions().subscribe(
      (res) => {

        this.termsAndConditionItems = res
      }
    )

    this.acceptation = Boolean(this.route.snapshot.queryParamMap.get('acceptation'))

  }

  goBack() {

    //this.location.back()
    this.router.navigate(['/register'])
  }

  displaySummarisationView() {

    this.data.termsAndConditionsView = false
    this.data.summarisationView = true
  }


  toggleDropDownSection(index) {


    this.listItems_termsAndConditions.forEach((item, _index) => {

      if (index == _index) {

        if (item.nativeElement.style.height == '0px') {

          item.nativeElement.style.height = item.nativeElement.children[0].offsetHeight + 'px'
        }
        else {

          item.nativeElement.style.height = '0px'
        }
      }
    })

    this.arrows.forEach((arrow, _index) => {

      if (index == _index) {

        arrow.nativeElement.classList.toggle('custom-rotate-180')
      }
    })

  }

}
