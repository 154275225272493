import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { Observable, Subscription } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";
import { MessagingService } from "src/app/services/messaging";
import { NameUpdateService } from "src/app/services/name-update.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardWrapperComponent implements OnInit, OnDestroy {
  private fcmToken: string;
  private fcmSubscription: Subscription;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private api: ApiService,
    private nameService: NameUpdateService,
    private msg: MessagingService,
    private _notification: NotificationsService
  ) {
    this.getScreenHeight();
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.selectForRoute(val.url);
      }
    });

    this.nameService.nameChanged$.subscribe((newName) => {
      this.userName = newName;
    });
  }

  isExpanded = false;
  contentMargin = 65;
  appropriateClass = "";
  selectedMenu = 1;
  dashboardSubmenu = false;
  settingsSubmenu = false;
  userSubmenu = false;
  userName = "";
  hasParent = false;
  sectionTitle = "Dashboard";
  parentSectionTile = "";
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  ngOnInit() {
    if (this.isIndividual()) {
      this.api.getIndividualUser().subscribe((res) => {
        if (res.first_name && res.last_name) {
          this.userName = res.first_name + " " + res.last_name;
        }
      });
    } else {
      this.api.getBusinessUser().subscribe((res) => {
        if (res.first_name && res.last_name) {
          this.userName = res.first_name + " " + res.last_name;
        }
      });

      // FCM
      this.msg.getToken();
      this.msg.receiveMessage();

      this.fcmSubscription = this.msg.fcmToken$.subscribe((token) => {
        this.fcmToken = token;

        const payload = {
          registration_id: this.fcmToken,
        };

        this.api.registerToken(payload).subscribe(
          (res) => {},
          (error) => {}
        );
      });
    }
  }

  ngOnDestroy(): void {
    if (this.fcmSubscription) {
      this.fcmSubscription.unsubscribe();
    }
  }

  logout() {
    if (this.isIndividual()) {
      localStorage.removeItem("token");
      localStorage.removeItem("individual");
      this.router.navigate(["login"]);
    } else {
      if (!this.fcmToken) {
        localStorage.removeItem("token");
        localStorage.removeItem("individual");
        this.router.navigate(["login"]);
        return;
      }

      const payload = {
        registration_id: this.fcmToken,
      };

      this.api.logout(payload).subscribe(
        (res) => {
          localStorage.removeItem("token");
          localStorage.removeItem("individual");
          this.router.navigate(["login"]);
        },
        (error) => {
          if (error.status != 401) {
            let err = JSON.parse(JSON.stringify(error.error));
            this._notification.error("Logout", err.detail);
          }
        }
      );
    }
  }

  isIndividual(): boolean {
    return JSON.parse(localStorage.getItem("individual"));
  }

  sideMenuToggle() {
    this.isExpanded = !this.isExpanded;
    this.contentMargin = this.isExpanded ? 240 : 65;
  }

  expandMenu() {
    if (!this.isExpanded) {
      this.settingsSubmenu = false;
    }
    this.isExpanded = true;
    this.settingsSubmenu = !this.settingsSubmenu;
    this.contentMargin = 240;
  }

  expandDashboardSubmenu() {
    if (!this.isExpanded) {
      this.dashboardSubmenu = false;
    }
    this.isExpanded = true;
    this.dashboardSubmenu = !this.dashboardSubmenu;
    this.contentMargin = 240;
  }

  expandUserSubmenu() {
    if (!this.isExpanded) {
      this.userSubmenu = false;
    }
    this.isExpanded = true;
    this.userSubmenu = !this.userSubmenu;
    this.contentMargin = 240;
  }

  @ViewChild("drawer", { static: true }) sidenav: MatSidenav;

  menuClick(id) {
    this.selectedMenu = id;
    if (window.innerWidth <= 576) {
      this.sidenav.close();
    }
  }

  selectForRoute(route) {
    switch (route) {
      case "/dashboard/mobile":
        this.menuClick(11);
        break;
      case "/dashboard/web":
        this.menuClick(12);
        break;
      case "/sandbox":
        this.menuClick(2);
        break;
      case "/apps/android":
        this.menuClick(3);
        break;
      case "/apps/ios":
        this.menuClick(4);
        break;
      case "/apps/web":
        this.menuClick(5);
        break;
      case "/profile":
        this.menuClick(61);
        break;
      case "/account/status":
        this.menuClick(62);
        break;
      case "/business/privacy-preferences":
        this.menuClick(63);
        break;
      case "/individual/privacy-preferences":
        this.menuClick(63);
        break;
      case "/manage/individual":
        this.menuClick(71);
        break;
      case "/manage/business":
        this.menuClick(72);
        break;
      case "/dashboard/contact":
        this.menuClick(8);
        break;
      default:
        if (route.startsWith("/dashboard/web")) {
          this.menuClick(12);
        }
    }
  }

  @HostListener("window:resize", ["$event"])
  getScreenHeight(event?) {
    // console.log(window.innerHeight);
    if (window.innerHeight <= 460) {
      this.appropriateClass = "bottom-item-relative";
    } else {
      this.appropriateClass = "bootom-item-stick";
    }
  }
}
