<section style="padding-top: 96px;padding-bottom: 96px;" class="bg-white mt-20 lg:mt-24">
    <div style="max-width: 350px;" class="mx-auto px-3">
        <div class="flex flex-col gap-10">
            <p class="text-2xl font-bold text-center text-brown">
                So Nice To Meet You!<br />
                We Would Like You To
                Go Through Our Privacy Policy <br />
                Policy & Terms And Conditions
            </p>
            <button (click)="displayPrivacyPolicyView()"
                class="bg-bronze border-[1px] border-bronze text-white px-4 py-2 rounded-full hover:bg-white hover:text-bronze w-full">Continue</button>
        </div>
    </div>
</section>