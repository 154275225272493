<div class="row position-relative h-100 bg-white">

  <div class="col-12 position-absolute header">
    <app-onboard-header></app-onboard-header>
  </div>

  <!-- register main view start -->


  <div class="row position-relative h-100">

    <div class="col-6 empty-state bg-white">
      <img src="../../../assets/images/resend_mail_image-min.png" alt="resend mail" />
    </div>

    <div class="col-lg-6 col-12">

      <div class="container scroll">
        <div *ngIf="individual" class="individual-content-wrapper">
          <a (click)="backAction()">
            <svg width="16" height="16" class="arrow-icon" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#5F3B19" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </a>

          <div class="title">
            <p>Create your {{ accountType }} Pro Se account</p>
          </div>

          <form [formGroup]="signUpForm" id="signUpFormIndividual" class="register-form">
            <div class="row field-wrapper">
              <div class="col">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Email address</mat-label>
                    <input formControlName="email" autocomplete="off" matInput placeholder="example@mail.com" />
                    <mat-error *ngIf="
                        signUpForm.get('email').hasError('email') &&
                        !signUpForm.get('email').hasError('required') &&
                        signUpForm.get('email').touched
                      " id="emailHelp1" class="danger">
                      Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="
                        signUpForm.get('email').hasError('required') &&
                        signUpForm.get('email').touched
                      " id="emailHelp2" class="danger">
                      Email is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row field-wrapper password-field-wrapper">
              <div class="col">
                <div class="form-group">
                  <mat-form-field appearance="outline" color="#cca96a">
                    <mat-label>Password</mat-label>
                    <input formControlName="password" matInput [type]="hide_pass ? 'password' : 'text'" />
                    <button type="button" class="eye-btn" mat-icon-button matSuffix (click)="hide_pass = !hide_pass"
                      [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_pass">
                      <mat-icon>{{
                        hide_pass ? "visibility_off" : "visibility"
                        }}</mat-icon>
                    </button>
                    <mat-hint *ngIf="
                        signUpForm.get('password').untouched ||
                        (!signUpForm.get('password').hasError('required') &&
                          signUpForm.get('password').touched)
                      " id="new_password1Help1" class="text-muted">
                      8 characters long. At least 1 digit, upper and lowercase
                      letter!
                    </mat-hint>
                    <mat-error *ngIf="
                        signUpForm.get('password').hasError('required') &&
                        signUpForm.get('password').touched
                      " id="password1Help2" class="danger">
                      Please enter your password
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row submit-wrapper">
              <div class="col">
                <button ngbAutofocus (click)="goNext()" class="mt-2 submit-btn" form="signUpFormIndividual"
                  mat-raised-button color="primary">
                  Continue
                </button>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col footer">
              <span>Already have an account?
                <a (click)="goToLogin()">Sign in</a></span>
            </div>
          </div>
        </div>

        <div *ngIf="!individual" class="business-content-wrapper">
          <a (click)="backAction()" class="back-arrow">
            <svg width="16" height="16" class="arrow-icon" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#5F3B19" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </a>

          <div class="title" style="margin-top: 40px">
            <p>Create your {{ accountType }} Pro Se account</p>
          </div>

          <form [formGroup]="signUpForm" id="businessForm" class="register-form">
            <p class="label">Personal data</p>
            <div class="row">
              <div class="col-lg-6 col-sm-12 business-fields">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>First name</mat-label>
                    <input formControlName="first_name" autocomplete="off" matInput placeholder="First name" />
                    <mat-error *ngIf="
                        signUpForm.get('first_name').hasError('required') &&
                        signUpForm.get('first_name').touched
                      " id="nameHelp" class="danger">
                      First name is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 space business-fields">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Last name</mat-label>
                    <input formControlName="last_name" autocomplete="off" matInput placeholder="Last name" />
                    <mat-error *ngIf="
                        signUpForm.get('last_name').hasError('required') &&
                        signUpForm.get('last_name').touched
                      " id="surnameHelp" class="danger">
                      Last name is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row field-wrapper">
              <div class="col">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Email address</mat-label>
                    <input formControlName="email" autocomplete="off" matInput placeholder="example@mail.com" />
                    <mat-error *ngIf="
                        signUpForm.get('email').hasError('email') &&
                        !signUpForm.get('email').hasError('required') &&
                        signUpForm.get('email').touched
                      " id="email2Help1" class="danger">
                      Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="
                        signUpForm.get('email').hasError('required') &&
                        signUpForm.get('email').touched
                      " id="email2Help2" class="danger">
                      Email is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row field-wrapper password-field-wrapper">
              <div class="col">
                <div class="form-group">
                  <mat-form-field appearance="outline" color="#cca96a">
                    <mat-label>Password</mat-label>
                    <input formControlName="password" matInput [type]="hide_pass ? 'password' : 'text'" />
                    <button type="button" class="eye-btn" mat-icon-button matSuffix (click)="hide_pass = !hide_pass"
                      [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_pass">
                      <mat-icon>{{
                        hide_pass ? "visibility_off" : "visibility"
                        }}</mat-icon>
                    </button>
                    <mat-hint *ngIf="
                        signUpForm.get('password').untouched ||
                        (!signUpForm.get('password').hasError('required') &&
                          signUpForm.get('password').touched)
                      " id="new_password2Help1" class="text-muted">
                      8 characters long. At least 1 digit, upper and lowercase
                      letter!
                    </mat-hint>
                    <mat-error *ngIf="
                        signUpForm.get('password').hasError('required') &&
                        signUpForm.get('password').touched
                      " id="password2Help2" class="danger">
                      Please enter your password
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <p class="company-title label">Company data</p>
            <div class="row business-fields">
              <div class="col-lg-6 col-sm-12 business-fields">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Company name</mat-label>
                    <input formControlName="company_name" autocomplete="off" matInput placeholder="Company name" />
                    <mat-error *ngIf="
                        signUpForm.get('company_name').hasError('required') &&
                        signUpForm.get('company_name').touched
                      " id="companyHelp" class="danger">
                      Company name is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 space business-fields">
                <div class="form-group">
                  <mat-form-field appearance="outline">
                    <mat-label>Website</mat-label>
                    <input formControlName="web_address" autocomplete="off" matInput placeholder="Web address" />
                    <mat-error *ngIf="
                        signUpForm.get('web_address').hasError('required') &&
                        signUpForm.get('web_address').touched
                      " id="websiteHelp" class="danger">
                      Website is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row submit-wrapper">
              <div class="col">
                <button ngbAutofocus (click)="goNext()" class="mt-2 submit-btn" form="businessForm" mat-raised-button
                  color="primary">
                  Continue
                </button>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col footer">
              <span>Already have an account?
                <a (click)="goToLogin()">Sign in</a></span>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>


  <!-- register main view end -->

</div>