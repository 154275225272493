import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-register-wrapper",
  templateUrl: "./register-wrapper.component.html",
  styleUrls: ["./register-wrapper.component.scss"],
})
export class RegisterWrapperComponent {
  constructor(private _location: Location, private _router: Router) {}

  backAction() {
    this._location.back();
  }
}
