<div class="row wrapper bg-white">
  <div class="col-12 header">
    <app-onboard-header></app-onboard-header>
  </div>
  <div class="col-6 empty-state bg-white">
    <img src="../../../assets/images/invite_image.png" alt="invite">
  </div>
  <div class="col-lg-6 col-12">
    <div class="container">
      <div class="content-wrapper">

        <p class="title">Seet up password for your account</p>

        <form [formGroup]="invitationForm" id="invitationForm" class="password-form">
          <div class="row field-wrapper">
            <div class="col">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label>Password</mat-label>
                  <input #password formControlName="password" matInput [type]="hide_pass1 ? 'password' : 'text'">
                  <button type="button" class="eye-btn" mat-icon-button matSuffix (click)="hide_pass1 = !hide_pass1"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_pass1">
                    <mat-icon>{{hide_pass1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>
                <small
                  *ngIf="invitationForm.get('password').untouched || (!invitationForm.get('password').hasError('required') && invitationForm.get('password').touched)"
                  id="new_password1Help" class="form-text text-muted">
                  8 characters long. At least 1 digit, upper and lowercase letter!
                </small>
                <small
                  *ngIf="invitationForm.get('password').hasError('required') && invitationForm.get('password').touched"
                  id="password1Help1" class="form-text danger">
                  Please enter your password
                </small>
              </div>
            </div>
          </div>
          <div class="row submit-wrapper">
            <div class="col">
              <button ngbAutofocus (click)="accept(invitationForm)" class="mt-2 submit-btn" form="invitationForm"
                mat-raised-button color="primary">Get started</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>