// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cloudApiDev: "https://api.prose.biz/api/v1",
  //cloudApiDev: "http://localhost:8080/api/v1",
  firebaseConfig: {
    apiKey: "AIzaSyAyxT6B44vHGkmtbc4sEUb8z0KRp2FS7vo",
    authDomain: "prosebiz.firebaseapp.com",
    projectId: "prosebiz",
    storageBucket: "prosebiz.appspot.com",
    messagingSenderId: "1005141813189",
    appId: "1:1005141813189:web:9ec58dbfc8d4d8d7e34dac",
    measurementId: "UA-144025317-2",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
