import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { MatSelectionList } from "@angular/material/list";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationsService } from "angular2-notifications";
import { Subscription } from "rxjs";
import {
  Audience,
  ControlYourData,
  CrossReferencedResults,
  KnowYourData,
  ProSeRating,
  Security,
} from "src/app/models/app.model";
import { SandboxDocumentDetails } from "src/app/models/user.model";
import { ApiService } from "src/app/services/api.service";
import { PolicyAccordanceDialogComponent } from "../../dashboard/policy-accordance-dialog/policy-accordance-dialog.component";
import { RegulationsDialogComponent } from "../../dashboard/regulations-dialog/regulations-dialog.component";
import { SentencesDialogComponent } from "../../dashboard/sentences-dialog/sentences-dialog.component";
import { SharedPrivacyPolicy } from "src/app/services/shared-data/privacy-policy";

@Component({
  selector: "app-sandbox-report",
  templateUrl: "./sandbox-report.component.html",
  styleUrls: ["./sandbox-report.component.scss"],
})
export class SandboxReportComponent implements OnInit, AfterContentChecked {
  @Input() documentId: number;

  @ViewChild("appSelectionList")
  private selectionList?: MatSelectionList;

  @ViewChild("analyticsAccordion")
  private accordion?: MatAccordion;

  accordance = true;
  crossReferenceResults: CrossReferencedResults;

  topShadow = "";
  bottomShadow = "";
  info = "";

  knowYourData: KnowYourData = null;
  controlYourData: ControlYourData = null;
  security: Security = null;
  audience: Audience = null;
  proseRating: ProSeRating = null;

  documentDetails: SandboxDocumentDetails;

  fetchSubscription: Subscription;

  colorsEven = ["#998616", "#D99266"];
  colorsOdd = ["#5F3B19", "#CCA96A", "#CC9B00"];

  constructor(
    private api: ApiService,
    private cdr: ChangeDetectorRef,
    private _notification: NotificationsService,
    private _modal: NgbModal,
    private sharedPrivacyPolicy: SharedPrivacyPolicy
  ) {}

  ngOnInit() {
    this.getKnowYourData();
    this.getControlYourData();
    this.getSecurity();
    this.getDocumentDetails();
    this.getProSeRating();
    this.getAudience();
    // this.setUpImages()
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  getKnowYourData() {
    this.api.getSandboxKnowYourData(this.documentId).subscribe(
      (res) => {
        this.knowYourData = res;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Analysed information", err.detail);
      }
    );
  }

  getControlYourData() {
    this.api.getSandboxControlYourData(this.documentId).subscribe(
      (res) => {
        this.controlYourData = res;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Analysed information", err.detail);
      }
    );
  }

  getSecurity() {
    this.api.getSandboxSecurity(this.documentId).subscribe(
      (res) => {
        this.security = res;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Analysed information", err.detail);
      }
    );
  }

  getAudience() {
    this.api.getSandboxAudience(this.documentId).subscribe(
      (res) => {
        this.audience = res;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Analysed information", err.detail);
      }
    );
  }

  getCrossReferencedResults() {
    const params = {
      document_id: this.documentId,
    };

    this.api.getSandboxCrossReferencedResults(params).subscribe(
      (res) => {
        this.accordance = !res.differences;
        this.crossReferenceResults = res;
        this.setUpImages();
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Cross referenced results", err.detail);
      }
    );
  }

  getProSeRating() {
    this.api.getSandboxProSeRating(this.documentId).subscribe(
      (res) => {
        this.proseRating = res;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Pro Se rating", err.detail);
      }
    );
  }

  getDocumentDetails() {
    this.api.getSandboxDocumentDetails(this.documentId).subscribe(
      (res) => {
        this.documentDetails = res;
        this.sharedPrivacyPolicy.setSandboxPrivacyPolicy({
          id:res.id,
          appName: res.privacy_policy_name,
          text: res.privacy_policy_text
        })
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Document details", err.detail);
      }
    );
  }

  setUpImages() {
    if (this.accordance) {
      this.info = "../../../../../assets/images/info-i-green.svg";
      this.topShadow = "../../../../../assets/images/top-part-green.svg";
      this.bottomShadow = "../../../../../assets/images/bottom-part-green.svg";
    } else {
      this.info = "../../../../../assets/images/info-i-pink.svg";
      this.topShadow = "../../../../../assets/images/top-part-pink.svg";
      this.bottomShadow = "../../../../../assets/images/bottom-part-pink.svg";
    }
  }

  getSentences(category, attribute, value, $event) {
    $event.srcElement.blur();
    const appMetadata = {
      category: category,
      attribute: attribute,
      value: value,
      id: this.documentId,
      sandbox: true,
    };

    const modalRef = this._modal.open(SentencesDialogComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.appMetadata = appMetadata;
  }

  showDifferences() {
    if (!this.accordance) {
      const modalRef = this._modal.open(PolicyAccordanceDialogComponent, {
        size: "lg",
        centered: true,
        backdrop: "static",
        keyboard: false,
      });

      const appMetadata = {
        id: this.documentId,
        sandbox: true,
      };

      modalRef.componentInstance.crossReferenceResults =
        this.crossReferenceResults;
      modalRef.componentInstance.appMetadata = appMetadata;
    }
  }

  getRegulations() {
    const modalRef = this._modal.open(RegulationsDialogComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.missingRegulations =
      this.proseRating.missing_regulations;
    modalRef.componentInstance.business = true;
  }

  getColor(chip, index): string {
    if (chip && chip.style && chip.style.background) {
      return chip.style.background;
    } else {
      if (index % 2 == 0) {
        return this.colorsEven[(Math.random() * this.colorsEven.length) | 0];
      } else {
        return this.colorsOdd[(Math.random() * this.colorsOdd.length) | 0];
      }
    }
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }
}
