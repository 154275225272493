import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StaticPreferences } from "src/app/models/user.model";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-static-preference-overview",
  templateUrl: "./static-preference-overview.component.html",
  styleUrls: ["./static-preference-overview.component.scss"],
})
export class StaticPreferenceOverviewComponent implements OnInit {
  staticProfile: StaticPreferences;
  profileName: string;
  profileType: string;
  userType: string;

  whatColors = {
    "Computer information": "#998616",
    "User online activities": "#d99266",
    Financial: "#5f3b19",
    Unspecified: "#d99266",
    "Survey data": "#cca96a",
    Location: "#998616",
    "IP address and device IDs": "#cc9b00",
    "Cookies and tracking elements": "#d99266",
    "Generic personal information": "#5f3b19",
    Demographic: "#d99266",
    Other: "#998616",
    Contact: "#cca96a",
    "Personal identifier": "#cc9b00",
    Health: "#5f3b19",
  };

  purposeColors = {
    Advertising: "#998616",
    "Service operation and security": "#cca96a",
    Research: "#cc9b00",
    Marketing: "#d99266",
    "Legal requirement": "#cc9b00",
    "Perform service": "#5f3b19",
    Unspecified: "#998616",
    Other: "#d99266",
  };

  retentionColors = {
    Indefinitely: "#d99266",
    Unspecified: "#998616",
    "Stated Period": "#cc9b00",
    Limited: "#cca96a",
    Other: "#5f3b19",
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private location: Location
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.profileType = params["profile"];
      this.userType = params["type"];
      this.apiService
        .getStaticPreference(this.profileType, this.userType)
        .subscribe((resp) => {
          this.staticProfile = resp;
          switch (this.staticProfile.name) {
            case "EXTREME":
              this.profileName = "Guarded";
              break;
            case "MODERATE":
              this.profileName = "Vigilant";
              break;
            case "RELAXED":
              this.profileName = "Laid-back";
              break;
          }
        });
    });
  }

  goBack() {
    this.location.back();
  }

  onSubmit() {
    if (
      this.profileType != "EXTREME" &&
      this.profileType != "MODERATE" &&
      this.profileType != "RELAXED"
    ) {
      return;
    }
    const payload = {
      profile_type: this.profileType,
      data_collected_dict: {},
      collecting_purpose_dict: {},
      retention_preiod_dict: {},
    };
    this.apiService.updatePreferencesProfile(payload, this.userType).subscribe({
      next: () => {
        const url =
          this.userType === "individual" ? "apps/web" : "/dashboard/mobile";
        this.router.navigateByUrl(url);
      },
      error: (e) => console.log(e),
    });
  }
}
