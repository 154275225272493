import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';
import { NameUpdateService } from 'src/app/services/name-update.service';
import { PasswordValidation } from 'src/app/validation/password-validator';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  userForm: FormGroup;
  businessUserForm: FormGroup;
  passwordForm: FormGroup;
  deactivateForm: FormGroup;
  hide_pass1 = true;
  hide_pass2 = true;
  hide_pass3 = true;

  constructor(
    public api: ApiService,
    private router: Router,
    private _fb: FormBuilder,
    private _notification: NotificationsService,
    private nameService: NameUpdateService
  ) { }

  ngOnInit() {
    this.userForm = this._fb.group({
      first_name: [''],
      last_name: [''],
    });

    this.passwordForm = this._fb.group({
      new_password1: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)]],
      new_password2: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)]]
    }, { validator: PasswordValidation.MatchPassword }
    );

    this.deactivateForm = this._fb.group({
      password: ['',[Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)]]
    })

    if (JSON.parse(localStorage.getItem("individual"))) {
      this.api.getIndividualUser().subscribe(
        res => {
          this.userForm.patchValue(res);
        },
        error => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error('Retrieve profile info error', err.detail);
        }
      );
    } else {
      this.api.getBusinessUser().subscribe(
        res => {
          this.userForm.patchValue(res);
        },
        error => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error('Retrieve profile info error', err.detail);
        }
      );
    }

  }

  updateProfile(userForm) {
    if (userForm.valid) {
      if (JSON.parse(localStorage.getItem("individual"))) {
        this.api.updateIndividualUser(userForm.getRawValue()).subscribe(
          res => {
            this.userForm.patchValue(res);
            const newName = (res.first_name && res.last_name) ? `${res.first_name} ${res.last_name}` : '';
            this.nameService.changeName(newName);
            this._notification.success("Success", "Profile updated");
          },
          error => {
            let err = JSON.parse(JSON.stringify(error.error));
            this._notification.error('Update profile error', err.detail);
          }
        );
      } else {
        this.api.updateBusinessUser(userForm.getRawValue()).subscribe(
          res => {
            this.userForm.patchValue(res);
            const newName = (res.first_name && res.last_name) ? `${res.first_name} ${res.last_name}` : '';
            this.nameService.changeName(newName);
            this._notification.success("Success", "Profile updated");
          },
          error => {
            let err = JSON.parse(JSON.stringify(error.error));
            this._notification.error('Update profile error', err.detail);
          }
        );
      }
    } else {
      this.validateAllFormFields(this.userForm);
    }
  }

  changePassword(passwordForm) {
    if (passwordForm.valid) {
      const passwordPayload = {
        "password": passwordForm.controls.new_password1.value
      }
      if (JSON.parse(localStorage.getItem("individual"))) {
        this.api.updateIndividualUser(passwordPayload).subscribe(
          res => {
            this.passwordForm.reset();
            this.passwordForm.controls.new_password1.setErrors(null);
            this.passwordForm.controls.new_password2.setErrors(null);
            this._notification.success("Success", "Password changed");
          },
          error => {
            let err = JSON.parse(JSON.stringify(error.error));
            this._notification.error('Password change error', err.detail);
          }
        );
      } else {
        this.api.updateBusinessUser(passwordPayload).subscribe(
          res => {
            this.passwordForm.reset();
            this.passwordForm.controls.new_password1.setErrors(null);
            this.passwordForm.controls.new_password2.setErrors(null);
            this._notification.success("Success", "Password changed");
          },
          error => {
            let err = JSON.parse(JSON.stringify(error.error));
            this._notification.error('Password change error', err.detail);
          }
        );
      }
    } else {
      this.validateAllFormFields(this.passwordForm);
    }
  }

  deactivateAccount() {
    this.api.deactivateAccount().subscribe(
      res => {
        localStorage.removeItem('token');
        localStorage.removeItem('individual');
        this.router.navigate(['login']);
        this._notification.success("Success", "Account successfully deactivated");
      },
      error => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error('Deactivate account error', err.detail);
      }
    )
  }

  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);            
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);          
      }
    });
  }

}
