import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import PrivacyPolicyType from "src/app/models/privacy-policy.model";

@Injectable({
    providedIn: "root"
})

export class SharedPrivacyPolicy {

    private selectedPrivacyPolicyType: string = "privacy polices";
    private selectedPrivacyPolicyTypes: PrivacyPolicyType[] = [];
    private activePrivacyPolicy: PrivacyPolicyType;
    private dataSubject: BehaviorSubject<any>;

    constructor() {
        this.dataSubject = new BehaviorSubject<any>(this.activePrivacyPolicy);
    }

    getActivePrivacyPolicyObservable() {
        return this.dataSubject.asObservable();
    }

    private emitActivePrivacyPolicy() {
        this.dataSubject.next(this.activePrivacyPolicy);
    }

    getActivePrivacyPolicy() {
        return this.activePrivacyPolicy;
    }

    selectPrivacyPolicy(privacyPolicy: PrivacyPolicyType) {
        this.selectedPrivacyPolicyTypes = this.selectedPrivacyPolicyTypes.filter((policy) => policy.type !== privacyPolicy.type);
        this.selectedPrivacyPolicyTypes.push(privacyPolicy);
        this.setActivePrivacyPolicy();
        this.emitActivePrivacyPolicy();
    }

    unselectPrivacyPolicy(type: string) {
        this.selectedPrivacyPolicyTypes = this.selectedPrivacyPolicyTypes.filter((policy) => policy.type !== type);
        this.setActivePrivacyPolicy();
        this.emitActivePrivacyPolicy();
    }

    resetData() {
        this.selectedPrivacyPolicyTypes = [];
        this.activePrivacyPolicy = undefined;
        this.selectedPrivacyPolicyType = 'privacy polices'
        this.emitActivePrivacyPolicy();
    }

    setPrivacyPolicyType(type: string) {
        this.selectedPrivacyPolicyType = type;
        this.setActivePrivacyPolicy();
        this.emitActivePrivacyPolicy();
    }

    setSandboxPrivacyPolicy(privacyPolicy: PrivacyPolicyType){
        this.activePrivacyPolicy = privacyPolicy;
        this.emitActivePrivacyPolicy();
    }

    unsetSandboxPrivacyPolicy(){
        this.activePrivacyPolicy = undefined;
        this.emitActivePrivacyPolicy();
    }

    setActivePrivacyPolicy(){
        this.activePrivacyPolicy = this.selectedPrivacyPolicyTypes.find(policy => policy.type === this.selectedPrivacyPolicyType);
    }
}

