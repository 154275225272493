<div class="row sandbox-wrapper">
  <div
    *ngIf="!documentId || documentId === -1"
    class="col-12 empty-list-wrapper"
  >
    <div class="empty-list">
      <img
        class="empty-icon"
        src="../../../../../assets/images/empty-list.svg"
        alt="empty list"
      />
      <span class="empty-title">No Analysed Policies</span>
      <span class="empty-description"
        >You don't have any analysed privacy policies yet</span
      >
    </div>
  </div>
  <div
    *ngIf="knowYourData"
    class="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-xs-12 content-wrapper-left"
  >
    <div class="app-details">
      <div class="row prose-rating">
        <div>
          <app-prose-rating
            *ngIf="proseRating"
            [isDialogParent]="false"
            [rating]="proseRating.privacy_policy_rating / 100"
          ></app-prose-rating>
        </div>
        <div class="regulation-wrapper">
          <span class="regulation-title">Missing regulations</span>
          <span
            *ngIf="!proseRating || proseRating.missing_regulations.length === 0"
            class="regulation-btn"
          >
            <mat-icon>settings_suggest</mat-icon>
            <span>No missing regulations</span>
          </span>
          <p
            class="regulations-description"
            *ngIf="proseRating && proseRating.missing_regulations.length > 0"
          >
            Answers to following questions are not clearly addressed within this
            privacy policy.
          </p>
          <span
            *ngIf="proseRating && proseRating.missing_regulations.length > 0"
            class="regulation-btn"
          >
            <mat-icon>settings_suggest</mat-icon>
            <span>{{
              proseRating.missing_regulations[0].business_description
            }}</span>
          </span>
          <span
            *ngIf="proseRating && proseRating.missing_regulations.length > 1"
            class="regulation-btn"
          >
            <mat-icon>settings_suggest</mat-icon>
            <span>{{
              proseRating.missing_regulations[1].business_description
            }}</span>
          </span>
          <a
            *ngIf="
              proseRating !== null && proseRating.missing_regulations.length > 2
            "
            (click)="getRegulations()"
            class="regulation-link"
            >View all missing regulations</a
          >
        </div>
      </div>
      <div class="panel-header">
        <p>What users know about their data</p>
        <img src="../../../../../assets/images/know_your_data.svg" alt="know your data" />
      </div>
      <mat-accordion #analyticsAccordion="matAccordion" multi>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >What data is collected and notice on special categories of
                personal data?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p
            class="what-collects"
            *ngFor="let party of knowYourData.what_is_collected | keyvalue"
          >
            <span class="party-label">{{ party.key }}:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of party.value; let i = index"
                (click)="getSentences('WHAT', party.key, value, $event)"
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
          </p>
          <p
            class="not-available"
            *ngIf="isEmptyObject(knowYourData.what_is_collected)"
          >
            Information is not available.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >What is the purpose of data processing and notice on changing
                that purpose?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span class="multiline">
            <span class="party-label">Purpose:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="
                  let value of knowYourData.collecting_purpose;
                  let i = index
                "
                (click)="getSentences('WHY', 'Purpose', value, $event)"
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
          </span>
          <p
            class="not-available"
            *ngIf="
              knowYourData.collecting_purpose === null ||
              knowYourData.collecting_purpose.length === 0
            "
          >
            Information is not available.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >What are the legal basis and legitimate interests to process
                data?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span class="multiline">
            <span class="party-label">Legal basis:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of knowYourData.legal_basis; let i = index"
                (click)="getSentences('WHY', 'Legal basis', value, $event)"
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                knowYourData.legal_basis === null ||
                knowYourData.legal_basis.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>How long is data held - retention period?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span class="multiline">
            <span class="party-label">Retention period:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="
                  let value of knowYourData.retention_period;
                  let i = index
                "
                (click)="
                  getSentences('HOW LONG', 'Retention period', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                knowYourData.retention_period === null ||
                knowYourData.retention_period.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >When will policy change and how will users be notifed?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p
            class="what-collects"
            *ngFor="let party of knowYourData.when_and_how | keyvalue"
          >
            <span class="party-label">{{ party.key }}:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of party.value; let i = index"
                (click)="getSentences('WHEN AND HOW', party.key, value, $event)"
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
          </p>
          <p
            class="not-available"
            *ngIf="isEmptyObject(knowYourData.when_and_how)"
          >
            Information is not available.
          </p>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="panel-header">
        <p>How can users control their data</p>
        <img src="../../../../../assets/images/control_your_data.svg" alt="control your data" />
      </div>
      <mat-accordion #controlAccordion="matAccordion" multi>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Can users access their data?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Access data:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of controlYourData.access; let i = index"
                (click)="
                  getSentences(
                    'ACCESS DATA',
                    'Can you get access',
                    value,
                    $event
                  )
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.access === null ||
                controlYourData.access.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Can users transfer their data elsewhere?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Portability:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of controlYourData.portability; let i = index"
                (click)="
                  getSentences('DATA PORTABILITY', 'Portability', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.portability === null ||
                controlYourData.portability.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Can users object the processing of their data?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Can users object:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="
                  let value of controlYourData.object_processing;
                  let i = index
                "
                (click)="
                  getSentences(
                    'OBJECT THE PROCESSING OF DATA',
                    'Can you object',
                    value,
                    $event
                  )
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.object_processing === null ||
                controlYourData.object_processing.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Can users erase their data?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Erase:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of controlYourData.erase; let i = index"
                (click)="
                  getSentences('CONTROL YOUR DATA', 'Erase', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.erase === null ||
                controlYourData.erase.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Can users rectify their data?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Rectify:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of controlYourData.rectify; let i = index"
                (click)="
                  getSentences('CONTROL YOUR DATA', 'Rectify', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.rectify === null ||
                controlYourData.rectify.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >Do users have an option to provide/withdraw consent for data
                processing?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Consent and withdrawal:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="
                  let value of controlYourData.consent_and_withdrawal;
                  let i = index
                "
                (click)="
                  getSentences(
                    'CONTROL YOUR DATA',
                    'Consent and withdrawal',
                    value,
                    $event
                  )
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.consent_and_withdrawal === null ||
                controlYourData.consent_and_withdrawal.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >Has the contact information for objecting data processing been
                provided?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Contact:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of controlYourData.contact; let i = index"
                (click)="
                  getSentences('CONTROL YOUR DATA', 'Contact', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.contact === null ||
                controlYourData.contact.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="panel-header">
        <p>Security and notice on personal data breach</p>
        <img src="../../../../../assets/images/security.svg" alt="security" />
      </div>
      <mat-accordion #securityAccordion="matAccordion" multi>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >What security measures are applied to protect user's
                data?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="security" class="multiline">
            <span class="party-label">Security measures:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of security.security_measures; let i = index"
                (click)="
                  getSentences(
                    'DATA SECURITY',
                    'Security Measure',
                    value,
                    $event
                  )
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                security.security_measures === null ||
                security.security_measures.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="panel-header">
        <p>Specific audience</p>
        <img src="../../../../../assets/images/audience.svg" alt="audience" />
      </div>
      <mat-accordion #audienceAccordion="matAccordion" multi>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Is there a note on specific audience?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="audience" class="multiline">
            <span class="party-label">Children:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of audience.specific_audience; let i = index"
                (click)="
                  getSentences('SPECIFIC AUDIENCE', 'children', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                audience.specific_audience === null ||
                audience.specific_audience.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div
    *ngIf="documentId && documentId !== -1"
    class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 content-wrapper-right"
  >
    <div class="document-details">
      <span *ngIf="documentDetails" class="multiline">
        <span class="text"
          ><span class="label">Policy name: </span>
          {{ documentDetails.privacy_policy_name }}</span
        >
        <span class="text"
          ><span class="label">Policy version: </span
          >{{ documentDetails.privacy_policy_version }}</span
        >
        <mat-accordion #analyticsAccordion="matAccordion" multi>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="test">Policy text</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span class="text policy-content"
              >{{ documentDetails.privacy_policy_text }}
            </span>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- <span class="text policy-content"><span class="label">Policy content:</span> {{ documentDetails.text }} </span> -->
      </span>
    </div>
  </div>
</div>
