<div class="header">
  <div class="page-number">
    <span>2</span>
  </div>
  <div class="what-label">
    For what purpose(s) are you willing to share your data with third-party
    apps?
  </div>
</div>
<div class="row d-flex justify-content-center">
  <div class="col-3">
    <app-hexagon-component
      class="row second-row"
      [category]="'Advertising'"
      [color]="'#998616'"
      [clicked]="selected['Advertising']"
      (selectedChange)="selectChanged('Advertising', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row second-row"
      [category]="'Service operation and security'"
      [color]="'#cca96a'"
      [clicked]="selected['Service operation and security']"
      (selectedChange)="selectChanged('Service operation and security', $event)"
    ></app-hexagon-component>
  </div>
  <div class="col-3">
    <app-hexagon-component
      class="row"
      [category]="'Research'"
      [color]="'#cc9b00'"
      [clicked]="selected['Research']"
      (selectedChange)="selectChanged('Research', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row"
      [category]="'Marketing'"
      [color]="'#D99266'"
      [clicked]="selected['Marketing']"
      (selectedChange)="selectChanged('Marketing', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row"
      [category]="'Legal requirement'"
      [color]="'#cc9b00'"
      [clicked]="selected['Legal requirement']"
      (selectedChange)="selectChanged('Legal requirement', $event)"
    ></app-hexagon-component>
  </div>
  <div class="col-3">
    <app-hexagon-component
      class="row second-row"
      [category]="'Perform service'"
      [color]="'#5f3b19'"
      [clicked]="selected['Perform service']"
      (selectedChange)="selectChanged('Perform service', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row second-row"
      [category]="'Unspecified'"
      [color]="'#998616'"
      [clicked]="selected['Unspecified']"
      (selectedChange)="selectChanged('Unspecified', $event)"
    ></app-hexagon-component>
  </div>
  <div class="col-3">
    <app-hexagon-component
      class="row third-row"
      [category]="'Other'"
      [color]="'#D99266'"
      [clicked]="selected['Other']"
      (selectedChange)="selectChanged('Other', $event)"
    ></app-hexagon-component>
  </div>
</div>
