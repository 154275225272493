import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SimpleNotificationsModule } from "angular2-notifications";
import { ReCaptchaModule } from "angular2-recaptcha";
import { LottieModule } from "ngx-lottie";
import { MarkdownModule } from "ngx-markdown";
import { NgxPaginationModule } from "ngx-pagination";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AccountStatusComponent } from "./components/dashboard/account-status/account-status.component";
import { BusinessUsersComponent } from "./components/dashboard/business-users/business-users.component";
import { DashboardWrapperComponent } from "./components/dashboard/dashboard.component";
import { AnalysedPoliciesComponent } from "./components/dashboard/dashboard/analysed-policies/analysed-policies.component";
import { AnalysingPolicyComponent } from "./components/dashboard/dashboard/analysing-policy/analysing-policy.component";
import { ChooseDialogComponent } from "./components/dashboard/dashboard/choose-dialog/choose-dialog.component";
import { ComparisonDialogComponent } from "./components/dashboard/dashboard/comparison-dialog/comparison-dialog.component";
import { DashboardComponent } from "./components/dashboard/dashboard/dashboard.component";
import { PolicyAccordanceDialogComponent } from "./components/dashboard/dashboard/policy-accordance-dialog/policy-accordance-dialog.component";
import { PrivacyPoliciesComponent } from "./components/dashboard/dashboard/privacy-policies/privacy-policies.component";
import { ProseRatingComponent } from "./components/dashboard/dashboard/prose-rating/prose-rating.component";
import { RegulationsDialogComponent } from "./components/dashboard/dashboard/regulations-dialog/regulations-dialog.component";
import { SentencesDialogComponent } from "./components/dashboard/dashboard/sentences-dialog/sentences-dialog.component";
import { UserRatingComponent } from "./components/dashboard/dashboard/user-rating/user-rating.component";
import { IndividualUsersComponent } from "./components/dashboard/individual-users/individual-users.component";
import { InvitationModalComponent } from "./components/dashboard/invitation-modal/invitation-modal.component";
import { ManagementBusinessComponent } from "./components/dashboard/management-business/management-business.component";
import { ManagementIndividualComponent } from "./components/dashboard/management-individual/management-individual.component";
import { MobileAppsComponent } from "./components/dashboard/mobile-apps/mobile-apps.component";
import { PrivacyPreferencesComponent } from "./components/dashboard/privacy-preferences/privacy-preferences.component";
import { ProfileComponent } from "./components/dashboard/profile/profile.component";
import { DndDirective } from "./components/dashboard/sandbox/dnd.directive";
import { PurchaseDialogComponent } from "./components/dashboard/sandbox/purchase-dialog/purchase-dialog.component";
import { SandboxEmptyStateComponent } from "./components/dashboard/sandbox/sandbox-empty-state/sandbox-empty-state.component";
import { SandboxReportComponent } from "./components/dashboard/sandbox/sandbox-report/sandbox-report.component";
import { SandboxComponent } from "./components/dashboard/sandbox/sandbox.component";
import { TableResponsiveDirective } from "./components/dashboard/sandbox/table-responsive.directive";
import { UploadDialogComponent } from "./components/dashboard/sandbox/upload-dialog/upload-dialog.component";
import { WebAppsComponent } from "./components/dashboard/web-apps/web-apps.component";
import { ExpiredImgComponent } from "./components/expired-img/expired-img.component";
import { ForgetPasswordVerifComponent } from "./components/forget-password-verif/forget-password-verif.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { LoginWrapperComponent } from "./components/login/login-wrapper/login-wrapper.component";
import { LoginComponent } from "./components/login/login/login.component";
import { OnboardHeaderComponent } from "./components/onboard-header/onboard-header.component";
import { ContactDialogComponent } from "./components/popup/contact-dialog/contact-dialog.component";
import { PostComponent } from "./components/post/post.component";
import { RegisterWrapperComponent } from "./components/register/register-wrapper/register-wrapper.component";
import { RegisterComponent } from "./components/register/register/register.component";
import { ResendEmailComponent } from "./components/resend-email/resend-email.component";
import { UnsubscribeComponent } from "./components/unsubscribe/unsubscribe.component";
import { CustomPreferencesComponent } from "./components/user-preferences/custom-preferences/custom-preferences.component";
import { HexagonComponentComponent } from "./components/user-preferences/hexagon-component/hexagon-component.component";
import { PreferencesOverviewComponent } from "./components/user-preferences/preferences-overview/preferences-overview.component";
import { PreferencesWizardComponent } from "./components/user-preferences/preferences-wizard/preferences-wizard.component";
import { PurposePreferencesComponent } from "./components/user-preferences/purpose-preferences/purpose-preferences.component";
import { RetentionPreferencesComponent } from "./components/user-preferences/retention-preferences/retention-preferences.component";
import { SelectPreferencesProfileComponent } from "./components/user-preferences/select-preferences-profile/select-preferences-profile.component";
import { StaticPreferenceOverviewComponent } from "./components/user-preferences/static-preference-overview/static-preference-overview.component";
import { WhatPreferencesComponent } from "./components/user-preferences/what-preferences/what-preferences.component";
import { VerifyEmailComponent } from "./components/verify-email/verify-email.component";
import { VerifyInvitationComponent } from "./components/verify-invitation/verify-invitation.component";
import { AuthInterceptor } from "./models/auth.model";
import { InviteStatusPipe } from "./pipes/invite-status.pipe";
import { AuthBusinessGuard } from "./services/auth/auth-business.guard";
import { AuthIndividualGuard } from "./services/auth/auth-individual.guard";
import { AuthLoginGuard } from "./services/auth/auth-login.guard";
import { AuthGuard } from "./services/auth/auth.guard";
import { DeleteAppService } from "./services/delete-app.service";
import { FetchDataService } from "./services/fetch-data.service";
import { MessagingService } from "./services/messaging";
import { RedirectGuard } from "./services/redirect.guard";
import { WebAnalysedApplicationsComponent } from './components/dashboard/web-apps/web-analysed-applications/web-analysed-applications.component';
import { PrivacyPolicyComponent as PrivacyPolicyPage } from "./components/privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "./components/terms-and-conditions/terms-and-conditions.component";
import { GoThroughComponent } from "./components/register/go-through/go-through.component";
import { SummarisationComponent } from "./components/register/summarisation/summarisation.component";
import { WizardComponent } from "./components/register/wizard/wizard.component";
import { RegistrationSharedData } from "./services/registration-shared-data.service";
import { ModalComponent } from "./components/privacy-policy/modal/modal.component";
import { ChatbotComponent } from "./components/dashboard/chatbot/chatbot.component";
import { PlatformModule } from "@angular/cdk/platform";
import { ContactComponent } from "./components/dashboard/contact/contact.component";

// import { NgApexchartsModule } from 'ng-apexcharts';

export function playerFactory() {
  return import("lottie-web");
}

@NgModule({
  declarations: [
    AppComponent,
    PostComponent,
    ForgetPasswordVerifComponent,
    ExpiredImgComponent,
    ContactDialogComponent,
    VerifyEmailComponent,
    LoginComponent,
    LoginWrapperComponent,
    ForgotPasswordComponent,
    RegisterWrapperComponent,
    RegisterComponent,
    DashboardWrapperComponent,
    ProfileComponent,
    MobileAppsComponent,
    ManagementBusinessComponent,
    BusinessUsersComponent,
    InviteStatusPipe,
    ManagementIndividualComponent,
    VerifyInvitationComponent,
    OnboardHeaderComponent,
    ResendEmailComponent,
    InvitationModalComponent,
    SandboxComponent,
    WebAppsComponent,
    DashboardComponent,
    AccountStatusComponent,
    IndividualUsersComponent,
    CustomPreferencesComponent,
    HexagonComponentComponent,
    WhatPreferencesComponent,
    PurposePreferencesComponent,
    RetentionPreferencesComponent,
    PreferencesOverviewComponent,
    SelectPreferencesProfileComponent,
    PreferencesWizardComponent,
    StaticPreferenceOverviewComponent,
    PrivacyPreferencesComponent,
    SandboxEmptyStateComponent,
    DndDirective,
    UploadDialogComponent,
    PurchaseDialogComponent,
    PrivacyPoliciesComponent,
    AnalysedPoliciesComponent,
    UserRatingComponent,
    ProseRatingComponent,
    ComparisonDialogComponent,
    AnalysingPolicyComponent,
    SentencesDialogComponent,
    ChooseDialogComponent,
    PolicyAccordanceDialogComponent,
    SandboxReportComponent,
    RegulationsDialogComponent,
    TableResponsiveDirective,
    UnsubscribeComponent,
    WebAnalysedApplicationsComponent,
    PrivacyPolicyPage,
    TermsAndConditionsComponent,
    SummarisationComponent,
    GoThroughComponent,
    WizardComponent,
    ModalComponent,
    ChatbotComponent,
    ContactComponent
  ],
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatSelectModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatTableModule,
    MatMenuModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    SimpleNotificationsModule.forRoot(),
    ReCaptchaModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgxPaginationModule,
    MatGridListModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    YouTubePlayerModule,
    MarkdownModule.forRoot(),
    PlatformModule,
    BrowserModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (router: Router) {
        return new AuthInterceptor(router);
      },
      multi: true,
      deps: [Router],
    },
    AuthGuard,
    AuthLoginGuard,
    AuthIndividualGuard,
    AuthBusinessGuard,
    RedirectGuard,
    DeleteAppService,
    FetchDataService,
    AngularFireMessaging,
    MessagingService,
    RegistrationSharedData,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
