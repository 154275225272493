<div class="section-title">
    <span class="parent-title">Settings
      <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg" style="background-size: 4.5px 9px; margin-left: 14px; margin-right: 14px">
        <path d="M1 10L5.5 5.5L1 1" stroke="#6B6B6B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>   
    </span>
    <span>
        Account status
    </span>
</div>
<mat-divider></mat-divider>