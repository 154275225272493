import { Component, Input } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-preferences-overview",
  templateUrl: "./preferences-overview.component.html",
  styleUrls: ["./preferences-overview.component.scss"],
})
export class PreferencesOverviewComponent {
  @Input() selectedPurposes: string[] = [];
  @Input() selectedWhat: string[] = [];
  @Input() selectedRetention: String[] = [];

  whatColors = {
    "Computer information": "#998616",
    "User online activities": "#d99266",
    Financial: "#5f3b19",
    Unspecified: "#d99266",
    "Survey data": "#cca96a",
    Location: "#998616",
    "IP address and device IDs": "#cc9b00",
    "Cookies and tracking elements": "#d99266",
    "Generic personal information": "#5f3b19",
    Demographic: "#d99266",
    Other: "#998616",
    Contact: "#cca96a",
    "Personal identifier": "#cc9b00",
    Health: "#5f3b19",
  };

  purposeColors = {
    Advertising: "#998616",
    "Service operation and security": "#cca96a",
    Research: "#cc9b00",
    Marketing: "#d99266",
    "Legal requirement": "#cc9b00",
    "Perform service": "#5f3b19",
    Unspecified: "#998616",
    Other: "#d99266",
  };

  retentionColors = {
    Indefinitely: "#d99266",
    Unspecified: "#998616",
    "Stated Period": "#cc9b00",
    Limited: "#cca96a",
    Other: "#5f3b19",
  };

  constructor(private apiService: ApiService) {}
}
