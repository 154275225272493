import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-analysing-policy",
  templateUrl: "./analysing-policy.component.html",
  styleUrls: ["./analysing-policy.component.scss"],
})
export class AnalysingPolicyComponent {
  constructor(public modal: NgbActiveModal) {}

  cancel(): void {
    this.modal.close();
  }
}
