import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { ApiService } from "../../../services/api.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  signInFg: FormGroup;
  hide_pass = true;
  @Input() individual: boolean;

  constructor(
    public api: ApiService,
    private _fb: FormBuilder,
    private _notification: NotificationsService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.signInFg = this._fb.group({
      username: ["", [Validators.required, Validators.email]],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/
          ),
        ],
      ],
    });
  }

  signIn(signInFg: FormGroup): void {
    if (this.signInFg.valid) {
      let username = encodeURIComponent(this.signInFg.controls.username.value);
      let password = encodeURIComponent(this.signInFg.controls.password.value);
      let body = `username=${username}&password=${password}`;
      this.api.login(body, this.individual).subscribe(
        (res) => {
          if (res.is_first_login) {
            let nextRoute = this.individual
              ? "/individual/preferences-wizard"
              : "/business/preferences-wizard";
            this._router.navigate([nextRoute]);
          } else {
            let nextRoute = this.individual ? "/apps/web" : "/dashboard/mobile";
            this._router.navigate([nextRoute]);
          }
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Sign In error", err.detail);
        }
      );
    } else {
      this.validateAllFormFields(this.signInFg);
    }
  }

  getForgotPasswordRoute(): string {
    return this.individual
      ? "/forgot-password/personal"
      : "/forgot-password/business";
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
