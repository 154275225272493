<div class="row wrapper">
  <div class="col-6 character" style="padding: 0px; height: inherit">
    <div class="navbar-brand mr-auto" style="padding-left: 16px">
      <span class="img-space">
        <img
          class="img-logo"
          src="../../../../assets/images/stit-s-transparencijom.png"
          alt="img"
        />
      </span>
      <span class="hide-img">
        <img
          class="img-text"
          width="104px"
          height="29px"
          src="../../../../assets/images/ispis-s-transparencijom.png"
          alt="img"
        />
      </span>
    </div>
    <div class="animation-container">
      <ng-lottie
        [height]="'100%'"
        [width]="'107%'"
        [options]="animationOptions"
      ></ng-lottie>
    </div>
  </div>
  <div class="col-6 desc">
    <div class="desc-wrapper">
      <div class="title">Welcome to Pro Se!</div>
      <div class="info">
        Let's get you onboarded and find out what are your privacy preferences
        with third party apps. If you choose to skip this step, we will select a
        predefined profile type for you, which you can change anytime in the
        Settings.
      </div>
      <div class="button-section">
        <a class="skip-btn" routerLink="/dashboard/mobile">Skip</a>
        <button class="choose-btn" (click)="onChooseClick()">
          Choose my profile type
        </button>
      </div>
    </div>
  </div>
</div>
