import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-what-preferences",
  templateUrl: "./what-preferences.component.html",
  styleUrls: ["./what-preferences.component.scss"],
})
export class WhatPreferencesComponent {
  selected = {
    "Computer information": false,
    "User online activities": false,
    Financial: false,
    Unspecified: false,
    "Survey data": false,
    Location: false,
    "IP address and device IDs": false,
    "Cookies and tracking elements": false,
    "Generic personal information": false,
    Demographic: false,
    Other: false,
    Contact: false,
    "Personal identifier": false,
    Health: false,
    "User profile": false,
  };

  @Output() selectedItems = new EventEmitter<string[]>();
  @Input() set preselected(value: string[]) {
    value.forEach((data) => {
      this.selected[data] = true;
      this.selectedChanged(data, true);
    });
  }

  constructor() {
    // console.log(this.preselected);
  }

  selectedChanged(item: string, event: boolean) {
    this.selected[item] = event;
    let ret = Object.keys(this.selected).filter((key) => {
      if (this.selected[key]) {
        return key;
      }
    });
    this.selectedItems.emit(ret);
  }
}
