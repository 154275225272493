<div class="row position-relative h-100 bg-white">
  <div class="col-12 header">
    <app-onboard-header></app-onboard-header>
  </div>
  <div class="row position-relative h-100">
    <div class="col-6 empty-state bg-white">
      <div class="image-wrapper">
        <img src="../../../assets/images/register_image-min.png" alt="register" />
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="container">
        <div class="content-wrapper">
          <h2 class="title">Choose which type of account you wish to create</h2>

          <div class="button">
            <a [routerLink]="['/register/personal']">
              <div class="button-inside">
                <div class="circle">
                  <img src="../../../assets/images/personal_icon.png" alt="personal icon" />
                </div>
                <p class="text">Personal account</p>
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L1 1" stroke="#5F3B19" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </a>
          </div>

          <div class="button">
            <a [routerLink]="['/register/business']">
              <div class="button-inside">
                <div class="circle">
                  <img src="../../../assets/images/business_icon.png" alt="business icon" />
                </div>
                <p class="text">Business account</p>
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L1 1" stroke="#5F3B19" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </a>
          </div>

          <div class="row">
            <div class="col footer">
              <span>Already have an account?
                <a routerLink="/login">Sign in</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>