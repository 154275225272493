 <table appTableResponsive mat-table class="full-width-table" [dataSource]="businessUsers">
    <ng-container matColumnDef="full_name">
        <th mat-header-cell *matHeaderCellDef class="table-header">Name</th>
        <td mat-cell *matCellDef="let user" class="table-data"> {{user.full_name}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef class="table-header">Email adress</th>
        <td mat-cell *matCellDef="let user" class="table-data"> {{user.email}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="table-header">Status</th>
        <td mat-cell *matCellDef="let user"> 
            <span [ngClass]="statusClass(user.status)"> {{user.status}} </span> 
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'actions'">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user" [align]="'right'"> 
            <button type="button" mat-icon-button *ngIf="user.status === 'Active'" (click)="removeUser(user.email)">
                <svg width="18" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="action-button">
                    <path d="M1 5H3M3 5H19M3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V5H3ZM6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5M8 10V16M12 10V16" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <button type="button" mat-icon-button *ngIf="user.status !== 'Active'" [matMenuTriggerFor]="menu">
                <svg width="18" height="20" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="action-button">
                    <path d="M2 10C2.55228 10 3 9.55228 3 9C3 8.44772 2.55228 8 2 8C1.44772 8 1 8.44772 1 9C1 9.55228 1.44772 10 2 10Z" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 17C2.55228 17 3 16.5523 3 16C3 15.4477 2.55228 15 2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17Z" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="resendInvitation(user.invite_id)">
                    <svg width="18" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="action-button">
                        <path d="M21 1L10 12M21 1L14 21L10 12M21 1L1 8L10 12" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>                          
                    <span class="action-text">Resend invitation</span>
                </button>
                <button mat-menu-item (click)="removeUser(user.email)">
                    <svg width="18" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="action-button">
                        <path d="M1 5H3M3 5H19M3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V5H3ZM6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5M8 10V16M12 10V16" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>    
                    <span class="action-text">Delete user</span>
                </button>
            </mat-menu>
        </td>
    </ng-container>

    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>