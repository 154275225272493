import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-select-preferences-profile",
  templateUrl: "./select-preferences-profile.component.html",
  styleUrls: ["./select-preferences-profile.component.scss"],
})
export class SelectPreferencesProfileComponent implements OnInit {
  guarderAnimationOptions: AnimationOptions = {
    path: "../../../assets/animations/guarded_animation.json",
    rendererSettings: {
      preserveAspectRatio: "xMinYMin slice",
    },
  };

  laidbackAnimationOptions: AnimationOptions = {
    path: "../../../assets/animations/laid_back_animation.json",
    rendererSettings: {
      preserveAspectRatio: "xMinYMin slice",
    },
  };

  vigilantAnimationOptions: AnimationOptions = {
    path: "../../../assets/animations/vigilant_animation.json",
    rendererSettings: {
      preserveAspectRatio: "xMinYMin slice",
    },
  };

  customAnimationOptions: AnimationOptions = {
    path: "../../../assets/animations/custom_animation.json",
    rendererSettings: {
      preserveAspectRatio: "xMinYMin slice",
    },
  };

  profileType: string;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.profileType = params["type"];
    });
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.resize();
  }

  onPrefClick(route: string) {
    const url = `${this.profileType}${route}`;
    console.log(url);
    this.router.navigateByUrl(url);
  }
}
