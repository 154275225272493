import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserPreferencesResp } from "src/app/models/user.model";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-custom-preferences",
  templateUrl: "./custom-preferences.component.html",
  styleUrls: ["./custom-preferences.component.scss"],
})
export class CustomPreferencesComponent implements OnInit {
  active: number = 1;
  selectedPurposes: string[] = [];
  selectedWhat: string[] = [];
  selectedRetention: string[] = [];

  staticCustom: UserPreferencesResp;
  isChanging = false;

  preselectedData = [];
  preselectedPurpose = [];
  preselectedRetention = [];

  profileType: string;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.profileType = params["type"];
      if (this.profileType) {
        this.apiService
          .getStaticCustomPreference(this.profileType)
          .subscribe((resp) => {
            this.staticCustom = resp;
            this.staticCustom.name = this.staticCustom.name;
            this.staticCustom.data_collected_dict = this.reverseMap(
              this.staticCustom.data_collected_dict
            );
            this.staticCustom.collecting_purpose_dict = this.reverseMap(
              this.staticCustom.collecting_purpose_dict
            );
            this.staticCustom.retention_period_dict = this.reverseMap(
              this.staticCustom.retention_period_dict
            );
            // console.log(this.staticCustom);
          });

        this.route.data.subscribe((data) => {
          this.isChanging = data["changing"];
          if (this.isChanging) {
            this.apiService
              .getPreferencesProfile(this.profileType)
              .subscribe((resp) => {
                if (resp.name == "CUSTOM") {
                  this.preselectedData = Object.values(
                    resp.data_collected_dict
                  );
                  this.preselectedPurpose = Object.values(
                    resp.collecting_purpose_dict
                  );
                  this.preselectedRetention = Object.values(
                    resp.retention_period_dict
                  );
                }
              });
          }
        });
      }
    });
  }

  goBackPage() {
    if (this.active - 1 < 1) {
      return;
    }
    this.active--;
  }

  getBackLink() {
    return this.isChanging
      ? `/${this.profileType}/privacy-preferences`
      : `/${this.profileType}/select-preferences`;
  }

  goNextPage() {
    if (this.active + 1 > 4) {
      return;
    }
    this.active++;
  }

  onSubmit() {
    const payload = {
      profile_type: "CUSTOM",
      data_collected_dict: {},
      collecting_purpose_dict: {},
      retention_period_dict: {},
    };
    Object.values(this.selectedWhat).forEach((data) => {
      let key = data.toLowerCase();
      payload.data_collected_dict[
        this.staticCustom.data_collected_dict.get(key)
      ] = data;
    });
    Object.values(this.selectedPurposes).forEach((data) => {
      let key = data.toLowerCase();
      payload.collecting_purpose_dict[
        this.staticCustom.collecting_purpose_dict.get(key)
      ] = data;
    });
    Object.values(this.selectedRetention).forEach((data) => {
      let key = data.toLowerCase();
      payload.retention_period_dict[
        this.staticCustom.retention_period_dict.get(key)
      ] = data;
    });
    this.apiService
      .updatePreferencesProfile(payload, this.profileType)
      .subscribe((resp) => {
        let navLink = this.isChanging
          ? `/${this.profileType}/privacy-preferences`
          : "/dashboard/mobile";
        this.router.navigateByUrl(navLink);
      });
  }

  reverseMap(obj): Map<string, string> {
    const reversed = new Map<string, string>();
    Object.keys(obj).forEach((key) => {
      let newKey = obj[key].toLowerCase();
      reversed.set(newKey, key);
    });
    return reversed;
  }
}
