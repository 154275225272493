import {AbstractControl} from '@angular/forms';
export class PasswordValidation {

    static MatchPassword(AC: AbstractControl) {
       const password = AC.get('new_password1').value;
       const confirmPassword = AC.get('new_password2').value;
        if (password !== confirmPassword) {
            AC.get('new_password2').setErrors( {MatchPassword: true} );
        } else {
            if (confirmPassword)
              AC.get('new_password2').setErrors(null);
            return null;
        }
    }

    static MatchPasswordRegister(AC: AbstractControl) {
       const password = AC.get('password1').value;
       const confirmPassword = AC.get('password2').value;
        if (password !== confirmPassword) {
            AC.get('password2').setErrors( {MatchPassword: true} );
        } else {
            return null;
        }
    }

    //MOJ POKUSAJ ALI NECE :)
    static PasswordValidator(AC: AbstractControl) {
      const password1 = AC.get('password1').value;
      const password2 = AC.get('password2').value;
      if (password1.match(/^(?=.*[A-Z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/) && password2.match(/^(?=.*[A-Z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)) {
        // 8 karaktera, 1capital letter, 1 number
        return null;
      } else {
        if(!password1.match(/^(?=.*[A-Z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)){
          AC.get('password1').setErrors( {WeakPassword: true} );
        }
        if(!password2.match(/^(?=.*[A-Z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)){
          AC.get('password2').setErrors( {WeakPassword: true} );
        }
      }
    }


    /* static PasswordValidator(AC: AbstractControl) {
      const password = AC.value;
      if (password.match(/^(?=.*[A-Z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)) {
        // 8 karaktera, 1capital letter, 1 number
        return null;
      } else {
        AC.setErrors( {WeakPassword: true} );
      }
    } */
}
