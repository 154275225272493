import { Component } from "@angular/core";

@Component({
  selector: "app-sandbox-empty-state",
  templateUrl: "./sandbox-empty-state.component.html",
  styleUrls: ["./sandbox-empty-state.component.scss"],
})
export class SandboxEmptyStateComponent {
  constructor() {}
}
