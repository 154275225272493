import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthBusinessGuard implements CanActivate {
 
 constructor(private authService: AuthService, private _router: Router) {}
  
 canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   if (this.authService.isIndividual()) {
     if (this.authService.isLoggedIn)
      this._router.navigate(['not-found']);
     return false;
   }

   return true;
 }
  
}
