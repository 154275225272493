import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { RegistrationSharedData } from 'src/app/services/registration-shared-data.service';
import { NavigationExtras, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-summarisation',
  templateUrl: './summarisation.component.html',
  styleUrls: ['../../../../assets/scss/tw.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})

export class SummarisationComponent implements OnInit {

  constructor(
    private sharedService: RegistrationSharedData,
    private location: Location,
    private router: Router,
    private api: ApiService,
    private notification: NotificationsService,
  ) { }

  isSubmmited: Boolean = false

  ngOnInit(): void {

  }

  goBack() {

    //this.location.back()
    this.router.navigate(['/register'])
  }

  register() {

    //console.log(this.sharedService.getRegistrationData())

    const formData = this.sharedService.getRegistrationData()
    let registerPayload: any = {};

    if (formData) {

      this.isSubmmited = true

      if (formData.type == 'personal') {

        registerPayload = {
          email: formData.formData.controls.email.value,
          password: formData.formData.controls.password.value
        };
      }
      else {

        registerPayload = {
          first_name: formData.formData.controls.first_name.value,
          last_name: formData.formData.controls.last_name.value,
          company_name: formData.formData.controls.company_name.value,
          web_address: formData.formData.controls.web_address.value,
          email: formData.formData.controls.email.value,
          password: formData.formData.controls.password.value
        };
      }

      this.api.register(registerPayload, formData.type == 'personal' ? true : false).subscribe(
        res => {
          const data = {
            email: formData.formData.controls.email.value,
            individual: formData.type == 'personal' ? true : false,
          }
          let navigationExtras: NavigationExtras = {
            state: {
              data: data
            }
          };
          this.router.navigate(['/resend-email'], navigationExtras)
          this.isSubmmited = false
        }, error => {
          let err = JSON.parse(JSON.stringify(error.error));
          this.notification.error('Sign Up error', err.detail);
          this.isSubmmited = false
        }
      );
    }
    else {

      this.router.navigate(['/register'])
    }
  }

}
