<div class="row h-100 position-relative">
  <div class="col-12 fixed-top">
    <app-onboard-header></app-onboard-header>
  </div>

  <div class="row">
    <div
      style="background-color: #f9f5ed"
      class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center h-100"
    >
      <img
        src="../../../assets/images/verify_mail_image-min.png"
        alt="verify mail"
        class="vh-100 w-auto"
      />
    </div>
    <div
      class="col-lg-6 col-12 h-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <div class="h2 fw-bold">Unsubscribed</div>
        <p>You have successfully unsubscribed from Pro Se newsletter.</p>
      </div>
    </div>
  </div>
</div>
