import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-expired-img',
  templateUrl: './expired-img.component.html',
  styleUrls: ['./expired-img.component.scss']
})
export class ExpiredImgComponent implements OnInit {

  private isInvite: boolean;
  title: string;
  message: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.isInvite = data["invite"];
      if (this.isInvite) {
        this.title = 'The invite link has expired.';
        this.message = 'Please check with the person who shared the invite with you to ask for antoher invitation.';
      } else {
        this.title = 'Page not found';
      }
    })
  }

}
