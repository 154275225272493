import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

export interface Login {
    access_token: string;
    token_type: string;
    is_first_login: boolean;
}


@Injectable()
export class AuthInterceptor implements  HttpInterceptor {

    constructor(private router: Router) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('individual');
            this.router.navigate(['login']);
            return of(err.message);
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const authReq = req.clone({headers: req.headers.set(Cookie.tokenKey, Cookie.getToken())});
        return next.handle(req).pipe(catchError(x => this.handleAuthError(x))); 
    }
}