import { Component, Input } from "@angular/core";

@Component({
  selector: "app-onboard-header",
  templateUrl: "./onboard-header.component.html",
  styleUrls: ["./onboard-header.component.scss"],
})
export class OnboardHeaderComponent {
  @Input() cancelLink: string = "/";
  constructor() {}
}
