<section class="bg-white mt-20 lg:mt-24 py-12">
    <div class="container">

        <div class="flex flex-col items-center gap-10">

            <div>
                <div class="flex items-center justify-center gap-5 font-medium text-lg">

                    <p *ngFor="let filter of filters1" (click)="changeFilter1($event)"
                        [class]="activeFilter1 == filter.dataFilter ? 'text-bronze' : 'text-black' "
                        class="cursor-pointer" [attr.data-filter]="filter.dataFilter">{{filter.name}}
                    </p>

                </div>
                <hr [class]="activeFilter1 == 'nutshell' ? 'left-hr' : 'right-hr' "
                    class="bg-bronze transition-all duration-300" />
            </div>

            <div *ngIf="activeFilter1 == 'nutshell'" class="flex flex-col gap-10 w-full">

                <div style="flex-wrap: wrap;" class="flex flex-wrap items-center justify-center gap-3">

                    <img (click)="changeFilter2($event)" (mouseenter)="changeFilter2_mouseenter($event)"
                        (mouseleave)="changeFilter2_mouseleave($event)" *ngFor="let filter of filters2"
                        [attr.data-filter]="filter.dataFilter"
                        [src]="activeFilter2 == filter.dataFilter ? filter.activeImg : filter.img "
                        class="cursor-pointer" alt="img">

                </div>

                <div class="flex flex-col gap-5">

                    <div class="bg-purple-light p-10">
                        <p class="text-brown font-bold">{{activeItems_nutshell.title}}</p>
                        <img class="mx-auto" [src]="activeItems_nutshell.img" alt="img">
                    </div>

                    <div>

                        <div #listItems_nutshell
                            *ngFor="let activeItem_nuthsell of activeItems_nutshell.list;index as i">

                            <div (click)="toggleDropDownSection_nutshell(i)"
                                class="flex items-center gap-5 p-3 cursor-pointer">
                                <img src="../../../assets/imgs/gear.svg" alt="gear" />
                                <p class="font-medium">{{activeItem_nuthsell.title}}</p>
                                <img #arrows_nutshell class="ms-auto transition-all duration-300"
                                    src="../../../assets/imgs/arrow-black-down.svg" alt="black arrow" />
                            </div>

                            <div style="height: 0px;" class="overflow-hidden transition-all duration-300">

                                <div class="p-3">

                                    <div *ngIf="activeItem_nuthsell.list !== null">

                                        <div *ngIf="checkIsArray(activeItem_nuthsell.list)" class="flex flex-col">
                                            <p style="margin-bottom: 5px;" class="font-bold">
                                                {{activeItem_nuthsell.attributes[0]}}:
                                            </p>
                                            <div class="flex items-center gap-3 flex-wrap">
                                                <div (click)="getSentence(activeItem_nuthsell.category,activeItem_nuthsell.mainAttributes[0],activeItem_nuthsell.attributes[0],item)"
                                                    style="flex-wrap: wrap;"
                                                    *ngFor="let item of activeItem_nuthsell.list"
                                                    style="padding: 6px 14px;font-size: 15px;"
                                                    class="px-3 py-1.5 bg-brown rounded-full text-white cursor-pointer"
                                                    [attr-data-name]="item">
                                                    {{item}}</div>
                                            </div>
                                        </div>


                                        <div *ngIf="!checkIsArray(activeItem_nuthsell.list)"
                                            class="flex flex-col gap-5">
                                            <div *ngFor="let item of activeItem_nuthsell.list | keyvalue; index as i">
                                                <p style="margin-bottom: 5px;" class="font-bold">
                                                    {{activeItem_nuthsell.attributes[i]}}:
                                                </p>
                                                <div style="flex-wrap: wrap;" class="flex items-center gap-3">
                                                    <div *ngFor="let item of item.value "
                                                        (click)="getSentence(activeItem_nuthsell.category,activeItem_nuthsell.mainAttributes[i],activeItem_nuthsell.attributes[i],item)"
                                                        style="padding: 6px 14px;font-size: 15px;"
                                                        class="px-3 py-1.5 bg-brown rounded-full text-white cursor-pointer"
                                                        [attr.data-name]="item">
                                                        {{item}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="activeFilter1 == 'policy' " class="flex flex-col gap-10">

                <div class="flex flex-col gap-1 w-full">
                    <div *ngFor="let item of privacyPolicyItems.sections;index as i">
                        <div (click)="toggleDropDownSection_policy(i)"
                            class="flex items-center gap-5 bg-purple-light p-3 cursor-pointer">
                            <p class="text-brown font-semibold">{{item.section.replaceAll('**','')}}</p>
                            <img #arrows_policy src="../../../assets/imgs/bronze-down-arrow.svg"
                                class="ms-auto transition-all duration-300" alt="bronze arrow"/>
                        </div>
                        <div #listItems_policy style="height: 0px;" class="overflow-hidden transition-all duration-300">
                            <div class="p-3">
                                <markdown [data]="item.content" class="markdown-style"></markdown>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div *ngIf="data" class="flex items-center justify-start gap-5 w-full">
                <button (click)="goBack()"
                    class="bg-white border-[1px] border-bronze text-bronze px-4 py-2 rounded-full hover:bg-bronze hover:text-white">I
                    decline</button>
                <button (click)="displayTermsAndConditionsView()"
                    class="bg-bronze border-[1px] border-bronze text-white px-4 py-2 rounded-full hover:bg-white hover:text-bronze">I
                    accept</button>
            </div>

        </div>

    </div>

    <app-modal [modal]="modal"></app-modal>

</section>