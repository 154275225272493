import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-regulations-dialog",
  templateUrl: "./regulations-dialog.component.html",
  styleUrls: ["./regulations-dialog.component.scss"],
})
export class RegulationsDialogComponent {
  @Input() missingRegulations = null;
  @Input() business = false;

  constructor(public modal: NgbActiveModal) {}

  cancel(): void {
    this.modal.close();
  }
}
