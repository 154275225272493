import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { NotificationsService } from "angular2-notifications";
import { BehaviorSubject, Subject } from "rxjs";
import { FetchDataService } from "./fetch-data.service";

@Injectable()
export class MessagingService {
  fcmToken = new BehaviorSubject(null);

  private fcmTokenSource = new Subject<string>();

  fcmToken$ = this.fcmTokenSource.asObservable();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private _notification: NotificationsService,
    private fetchDataService: FetchDataService
  ) {}

  getToken() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.fcmTokenSource.next(token);
      },
      (error) => {
        // console.error('Unable to get fcm token.', error);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      const messageTitle = payload["notification"]["title"];
      const messageBody = payload["notification"]["body"];
      this._notification.success(
        messageTitle ? messageTitle : "Success",
        messageBody
      );

      if (payload["data"]["action"] == "analysed") {
        this.fetchDataService.fetchAnalysedPolicies();
      } else if (payload["data"]["action"] == "archived") {
        this.fetchDataService.fetchArchivedPolicies();
      } else if (payload["data"]["action"] == "sandbox_analysed") {
        this.fetchDataService.fetchAnalysedDocuments();
      }
    });
  }
}
