<section class="bg-white mt-20 lg:mt-24 py-12">
    <div style="max-width: 300px;" class="px-3 mx-auto">
        <div class="flex flex-col items-center justify-center gap-10">
            <img src="../../../../assets/imgs/summarisation.svg" alt="summarisation" />
            <p class="text-2xl text-brown font-bold text-center">
                You Acknowledge That <br />
                Your Accept Our Privacy <br />
                And Conditions
            </p>
            <div class="flex flex-col items-center justify-center gap-3 w-full">
                <button [disabled]="isSubmmited" (click)="register()"
                    class="bg-bronze border-[1px] border-bronze text-white px-4 py-2 rounded-full hover:bg-white hover:text-bronze w-full">I
                    agree</button>
                <button (click)="goBack()"
                    class="bg-white border-[1px] border-bronze text-bronze px-4 py-2 rounded-full hover:bg-bronze hover:text-white w-full">Not
                    agree</button>
            </div>
        </div>
    </div>
</section>