<div class="header">
    <div class="page-number">
        <span>
            <svg width="16" height="11" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="background-size: 11px 16px; margin: auto 0;">
                <path d="M17 1.5L6 12.5L1 7.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>    
        </span>
    </div>
    <div class="what-label">
        Here's your profile!
    </div>
</div>
<div>
    <div>
        <span class="section-description">
            <span style="color: #ffbf13">1.</span> Data I'm willing to share with third-party apps
        </span>
        <mat-chip-list>
            <mat-chip *ngFor="let what of selectedWhat" [style.background]="whatColors[what]">{{what}}</mat-chip>
        </mat-chip-list>
    </div>
    <div style="margin-top: 40px; margin-bottom: 40px">
        <span class="section-description">
            <span style="color: #ffbf13">2.</span> Purposes for which I'm willing to share my data with thrid-party apps
        </span>
        <mat-chip-list>
            <mat-chip *ngFor="let purpose of selectedPurposes" [style.background]="purposeColors[purpose]">{{purpose}}</mat-chip>
        </mat-chip-list>
    </div>
    <div>
        <span class="section-description">
            <span style="color: #ffbf13">3.</span> The amount of time I'm willing to let third-party apps retain my data
        </span>
        <mat-chip-list>
            <mat-chip *ngFor="let retention of selectedRetention" [style.background]="retentionColors[retention]">{{retention}}</mat-chip>
        </mat-chip-list>
    </div>
</div>