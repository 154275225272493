import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-purpose-preferences",
  templateUrl: "./purpose-preferences.component.html",
  styleUrls: ["./purpose-preferences.component.scss"],
})
export class PurposePreferencesComponent {
  selected = {
    Advertising: false,
    "Service operation and security": false,
    Research: false,
    Marketing: false,
    "Legal requirement": false,
    "Perform service": false,
    Unspecified: false,
    Other: false,
  };

  @Output() selectedItems = new EventEmitter<string[]>();
  @Input() set preselected(value: string[]) {
    value.forEach((data) => {
      this.selected[data] = true;
      this.selectChanged(data, true);
    });
  }

  constructor() {}

  selectChanged(item: string, event: boolean) {
    this.selected[item] = event;
    let ret = Object.keys(this.selected).filter((key) => {
      if (this.selected[key]) {
        return key;
      }
    });
    this.selectedItems.emit(ret);
  }
}
