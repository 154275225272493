import { Component, ViewEncapsulation, ViewChildren, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common'
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['../../../assets/scss/tw.scss', './privacy-policy.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(public api: ApiService, private location: Location, private route: ActivatedRoute, private router: Router) {

  }

  @ViewChildren('listItems_nutshell') listItems_nutshell
  @ViewChildren('listItems_policy') listItems_policy
  @ViewChildren('arrows_nutshell') arrows_nutshell
  @ViewChildren('arrows_policy') arrows_policy

  @Input() data: any;
  activeItems_nutshell: any;;
  privacyPolicyItems: any = []

  activeFilter1 = 'nutshell'
  activeFilter2 = 'data'
  sentence: String = ''
  modal: any = {
    isVisible: false,
    title: '',
    text: ''
  }

  ngOnInit(): void {

    this.activeItems_nutshell = this.dataItems

    this.api.getYourData().subscribe(
      (res) => {

        for (let prop in res) {

          this.dataItems.list.forEach((item) => {

            if (item.mark == prop) {

              item.list = res[prop]
            }
          })
        }

        delete this.dataItems.list[0].list['Third party']
      }
    )

    this.api.getControlData().subscribe(
      (res) => {

        for (let prop in res) {

          this.controlItems.list.forEach((item) => {

            if (item.mark == prop) {

              item.list = res[prop]
            }
          })
        }
      }
    )

    this.api.getSecurityData().subscribe(
      (res) => {

        for (let prop in res) {

          this.securityItems.list.forEach((item) => {

            if (item.mark == prop) {

              item.list = res[prop]
            }

          })
        }
      }
    )

    this.api.getAudienceData().subscribe(
      (res) => {

        for (let prop in res) {

          this.audienceItems.list.forEach((item) => {

            if (item.mark == prop) {

              item.list = res[prop]
            }
          })
        }
      }
    )

    this.api.getPrivacyPolicy().subscribe(
      (res) => {
        this.privacyPolicyItems = res
      }
    )
  }

  dataItems = {
    title: 'Know about your data',
    img: '../../../assets/imgs/know_your_data.svg',
    list: [
      {
        title: 'What data is collected and notice on special categories of personal data?',
        mark: 'what_is_collected',
        category: 'WHAT',
        attributes: ['First party', 'Third party'],
        mainAttributes: ['First party', 'Third party'],
        list: null
      },
      {
        title: 'What is the purpose of data processing and notice on changing that purpose?',
        mark: 'collecting_purpose',
        category: 'WHY',
        attributes: ['Purpose'],
        mainAttributes: ['Purpose'],
        list: null
      },
      {
        title: 'What are the legal basis and legitimate interests to process data?',
        attributes: ['Legal basis'],
        mainAttributes: ['Legal basis'],
        category: 'WHY',
        mark: 'legal_basis',
        list: null
      },
      {
        title: 'How long is data held – retention period?',
        attributes: ['Retention period'],
        mainAttributes: ['Retention period'],
        category: 'HOW LONG',
        mark: 'retention_period',
        list: null
      },
      {
        title: 'When will policy change and how will we notify You?',
        attributes: ['How', 'When'],
        mainAttributes: ['How', 'When'],
        category: 'WHEN AND HOW',
        mark: 'when_and_how',
        list: null
      }
    ]
  }

  controlItems = {
    title: 'Control your data',
    img: '../../../assets/imgs/control_your_data.svg',
    list: [
      {
        title: 'Can you access your data?',
        attributes: ['Access data'],
        mainAttributes: ['Can you get access'],
        category: 'ACCESS DATA',
        mark: 'access',
        list: null
      },
      {
        title: 'Can you transfer your data elsewhere?',
        attributes: ['Portability'],
        mainAttributes: ['Portability'],
        category: 'DATA PORTABILITY',
        mark: 'portability',
        list: null
      },
      {
        title: 'Can you object the processing of your data?',
        attributes: ['Can you object'],
        mainAttributes: ['Can you object'],
        category: 'OBJECT THE PROCESSING OF DATA',
        mark: 'object_processing',
        list: null
      },
      {
        title: 'Can you erase your data?',
        attributes: ['Erase'],
        mainAttributes: ['Erase'],
        category: 'CONTROL YOUR DATA',
        mark: 'erase',
        list: null
      },
      {
        title: 'Can you rectify your data?',
        attributes: ['Rectify'],
        mainAttributes: ['Rectify'],
        category: 'CONTROL YOUR DATA',
        mark: 'rectify',
        list: null
      },
      {
        title: 'Do you have option to provide/withdraw consent for data processing',
        attributes: ['Consent and withdrawal'],
        mainAttributes: ['Consent and withdrawal'],
        category: 'CONTROL YOUR DATA',
        mark: 'consent_and_withdrawal',
        list: null
      }
    ]
  }

  securityItems = {
    title: 'Security and notice on personal data breach',
    img: '../../../assets/imgs/security.svg',
    list: [
      {
        title: 'What security measures are applied to protect your data?',
        attributes: ['Security measures'],
        mainAttributes: ['Security Measure'],
        category: 'DATA SECURITY',
        mark: 'security_measures',
        list: null
      }
    ]
  }

  audienceItems = {
    title: 'Specific audience',
    img: '../../../assets/imgs/audience.svg',
    list: [
      {
        title: 'Is there a note on specific audience?',
        attributes: ['Children'],
        mainAttributes: ['children'],
        category: 'SPECIFIC AUDIENCE',
        mark: 'specific_audience',
        list: null
      }
    ]
  }

  filters1 = [
    {
      name: 'IN A NUTSHELL',
      dataFilter: 'nutshell'
    },
    {
      name: 'PRIVACY POLICY',
      dataFilter: 'policy'
    },
  ]

  filters2 = [
    {
      name: 'YOUR DATA',
      dataFilter: 'data',
      activeImg: '../../../assets/imgs/data-btn-active.svg',
      img: '../../../assets/imgs/data-btn.svg'
    },
    {
      name: 'CONTROL',
      dataFilter: 'control',
      activeImg: '../../../assets/imgs/control-btn-active.svg',
      img: '../../../assets/imgs/control-btn.svg'
    },
    {
      name: 'SECURITY',
      dataFilter: 'security',
      activeImg: '../../../assets/imgs/security-btn-active.svg',
      img: '../../../assets/imgs/security-btn.svg'
    },
    {
      name: 'AUDIENCE',
      dataFilter: 'audience',
      activeImg: '../../../assets/imgs/audience-btn-active.svg',
      img: '../../../assets/imgs/audience-btn.svg'
    },
  ]

  displayTermsAndConditionsView() {

    this.data.privacyPolicyView = false
    this.data.termsAndConditionsView = true
  }

  goBack() {

    //this.location.back()
    this.router.navigate(['/register'])
  }

  changeFilter1(e) {

    this.activeFilter1 = e.target.getAttribute("data-filter")
  }

  changeFilter2(e) {

    this.activeFilter2 = e.target.getAttribute('data-filter')


    if (this.activeFilter2 == 'data') {

      this.activeItems_nutshell = this.dataItems
    }
    else if (this.activeFilter2 == 'control') {

      this.activeItems_nutshell = this.controlItems
    }
    else if (this.activeFilter2 == 'security') {

      this.activeItems_nutshell = this.securityItems
    }
    else {

      this.activeItems_nutshell = this.audienceItems
    }
  }

  changeFilter2_mouseenter(e) {

    e.target.src = this.filters2.find((filter => filter.dataFilter == e.target.getAttribute('data-filter'))).activeImg
  }

  changeFilter2_mouseleave(e) {

    if (this.activeFilter2 === e.target.getAttribute('data-filter')) return

    e.target.src = this.filters2.find((filter => filter.dataFilter == e.target.getAttribute('data-filter'))).img
  }

  toggleDropDownSection_nutshell(index) {

    this.listItems_nutshell.forEach((item, _index) => {

      if (index == _index) {

        item.nativeElement.children[0].classList.toggle('bg-purple-light')

        if (item.nativeElement.children[1].style.height == '0px' || item.nativeElement.children[1].style.height == '') {

          item.nativeElement.children[1].style.height = item.nativeElement.children[1].children[0].offsetHeight + 'px'
        }
        else {

          item.nativeElement.children[1].style.height = '0px'
        }
      }
    })

    this.arrows_nutshell.forEach((arrow, _index) => {

      if (index == _index) {

        arrow.nativeElement.classList.toggle('custom-rotate-180')
      }
    })
  }

  toggleDropDownSection_policy(index) {

    this.listItems_policy.forEach((item, _index) => {

      if (index == _index) {

        if (item.nativeElement.style.height == '0px') {

          item.nativeElement.style.height = item.nativeElement.children[0].offsetHeight + 'px'
        }
        else {

          item.nativeElement.style.height = '0px'
        }
      }
    })

    this.arrows_policy.forEach((arrow, _index) => {

      if (index == _index) {

        arrow.nativeElement.classList.toggle('custom-rotate-180')
      }
    })
  }

  checkIsArray(value) {

    return Array.isArray(value) ? true : false
  }

  getSentence(category, mainAttribute, attribute, value) {

    const params = {
      category: category,
      attribute: mainAttribute,
      value: value
    }

    this.api.getProSeSentence(params).subscribe(
      (res) => {

        this.modal.isVisible = true
        this.modal.title = value == 'yes' ? attribute : value
        this.modal.text = res[0].text
      }
    )
  }
}


