import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { NotificationsService } from 'angular2-notifications';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common'
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  individual: string;

  constructor(
    public api: ApiService,
    private _notification: NotificationsService,
    private _fb: FormBuilder,
    private _router: Router,
    private route: ActivatedRoute,
    private _location: Location
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.individual = data['individual']
    })

    this.forgotPasswordForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  forgotPassword(forgotPasswordForm: FormGroup): void {
    if (this.forgotPasswordForm.valid) {
      this.api.forgotPassword(this.forgotPasswordForm.getRawValue(), this.individual).subscribe(
        res => {
          this._notification.success("Success", "Password recovery email sent");
          this.backAction();
        }, error => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error('Forgot password error', err.detail);
        }
      );
    }
    else {
      this.validateAllFormFields(this.forgotPasswordForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);            
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);          
      }
    });
  }

  backAction() {
    const data = {
      individual: this.individual
    }

    let navigationExtras: NavigationExtras = {
      state: {
        data: data
      }
    };
    
    this._router.navigate(['/login'], navigationExtras)
  }

}
