<div class="dashboard">
  <div class="section-title">
    <span>
      Dashboard
    </span>
  </div>
  <mat-tab-group #tabGroup dynamicHeight class="tab-group dashboard-tabs" mat-align-tabs="start" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="My privacy policies">
      <div class="scroll">
        <app-privacy-policies></app-privacy-policies>
      </div>
    </mat-tab>
    <mat-tab label="My analysed policies">
      <div class="scroll">
        <app-analysed-policies></app-analysed-policies>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
