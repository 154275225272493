<div class="header">
  <div class="page-number">
    <span>1</span>
  </div>
  <div class="what-label">
    What data are you willing to share with third-party apps?
  </div>
</div>
<div class="row d-flex justify-content-center">
  <div class="col-3">
    <app-hexagon-component
      class="row"
      [category]="'Computer information'"
      [color]="'#998616'"
      [clicked]="selected['Computer information']"
      (selectedChange)="selectedChanged('Computer information', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row"
      [category]="'User online activities'"
      [color]="'#D99266'"
      [clicked]="selected['User online activities']"
      (selectedChange)="selectedChanged('User online activities', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row"
      [category]="'Financial'"
      [color]="'#5f3b19'"
      [clicked]="selected['Financial']"
      (selectedChange)="selectedChanged('Financial', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row"
      [category]="'Unspecified'"
      [color]="'#D99266'"
      [clicked]="selected['Unspecified']"
      (selectedChange)="selectedChanged('Unspecified', $event)"
    ></app-hexagon-component>
  </div>
  <div class="col-3">
    <app-hexagon-component
      class="row second-row"
      [category]="'Survey data'"
      [color]="'#cca96a'"
      [clicked]="selected['Survey data']"
      (selectedChange)="selectedChanged('Survey data', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row second-row"
      [category]="'Location'"
      [color]="'#998616'"
      [clicked]="selected['Location']"
      (selectedChange)="selectedChanged('Location', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row second-row"
      [category]="'IP address and device IDs'"
      [color]="'#cc9b00'"
      [clicked]="selected['IP address and device IDs']"
      (selectedChange)="selectedChanged('IP address and device IDs', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row second-row"
      [category]="'User profile'"
      [color]="'#cca96a'"
      [clicked]="selected['User profile']"
      (selectedChange)="selectedChanged('User profile', $event)"
    ></app-hexagon-component>
  </div>
  <div class="col-3">
    <app-hexagon-component
      class="row"
      [category]="'Cookies and tracking elements'"
      [color]="'#D99266'"
      [clicked]="selected['Cookies and tracking elements']"
      (selectedChange)="
        selectedChanged('Cookies and tracking elements', $event)
      "
    ></app-hexagon-component>
    <app-hexagon-component
      class="row"
      [category]="'Generic personal information'"
      [color]="'#5f3b19'"
      [clicked]="selected['Generic personal information']"
      (selectedChange)="selectedChanged('Generic personal information', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row"
      [category]="'Demographic'"
      [color]="'#D99266'"
      [clicked]="selected['Demographic']"
      (selectedChange)="selectedChanged('Demographic', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row"
      [category]="'Other'"
      [color]="'#998616'"
      [clicked]="selected['Other']"
      (selectedChange)="selectedChanged('Other', $event)"
    ></app-hexagon-component>
  </div>
  <div class="col-3">
    <app-hexagon-component
      class="row second-row"
      [category]="'Contact'"
      [color]="'#cca96a'"
      [clicked]="selected['Contact']"
      (selectedChange)="selectedChanged('Contact', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row second-row"
      [category]="'Personal identifier'"
      [color]="'#cc9b00'"
      [clicked]="selected['Personal identifier']"
      (selectedChange)="selectedChanged('Personal identifier', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row second-row"
      [category]="'Health'"
      [color]="'#5f3b19'"
      [clicked]="selected['Health']"
      (selectedChange)="selectedChanged('Health', $event)"
    ></app-hexagon-component>
  </div>
</div>
