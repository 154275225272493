<div class="row wrapper">
  <div
    *ngIf="!applications || applications.length === 0"
    class="col-12 empty-list-wrapper"
  >
    <div class="empty-list">
      <img
        class="empty-icon"
        src="../../../../../assets/images/empty-list.svg"
        alt="empty icon"
      />
      <span class="empty-title">No Analysed Policies</span>
      <span class="empty-description"
        >You don't have any analysed privacy policies yet</span
      >
    </div>
  </div>
  <div
    *ngIf="applications && applications.length > 0"
    class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 content-wrapper-left"
  >
    <div class="analysed-app">
      <div class="archive-display">
        <span class="header">
          <p *ngIf="isBusinessUser || isPremiumIndividual" class="title">
            My analysed policies
          </p>
          <p *ngIf="!isBusinessUser && !isPremiumIndividual" class="title">
            Free premium analysed policies
          </p>
          <button
            *ngIf="isBusinessUser && applications && count > 1"
            mat-flat-button
            (click)="appComparison()"
            aria-label="Close"
          >
            {{ isPremium ? "Compare" : "Default comparison" }}
          </button>
        </span>
        <div class="app-selection-list">
          <div class="app-list-wrapper">
            <div
              *ngIf="isBusinessUser || isPremiumIndividual"
              class="archive-search-wrapper"
            >
              <mat-form-field class="search-field" appearance="outline">
                <input
                  matInput
                  (keyup.enter)="onSearch(false)"
                  [(ngModel)]="searchTerm"
                />
                <button matSuffix mat-icon-button (click)="onSearch(false)">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    preserveAspectRatio="xMaxYMax meet"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                      stroke="#CCA96A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19 19L14.65 14.65"
                      stroke="#CCA96A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </mat-form-field>
            </div>

            <span
              *ngIf="emptySearchResults"
              [@fadeInOut]
              class="search-results"
            >
              No matches have been found for a searched term.
            </span>

            <mat-selection-list
              #appSelectionList="matSelectionList"
              (selectionChange)="onAppSelectionChange(appSelectionList._value)"
              class="app-list"
            >
              <mat-list-option
                [selected]="isAppSelected(app.id)"
                *ngFor="
                  let app of applications
                    | paginate
                      : {
                          id: 'analysedAppPagination',
                          itemsPerPage: pageSize,
                          currentPage: page,
                          totalItems: count
                        }
                "
                checkbox="false"
                [value]="app"
              >
                <span class="option-wrapper">
                  <img class="app-icon" [src]="app.image_link" alt="app icon" />
                  <p class="app-label">{{ app.label }}</p>
                  <p
                    class="app-type"
                    *ngIf="isBusinessUser && app.type === 'ANDROID'"
                  >
                    (Android)
                  </p>
                  <p
                    class="app-type"
                    *ngIf="isBusinessUser && app.type === 'IOS'"
                  >
                    (iOS)
                  </p>
                </span>
              </mat-list-option>
            </mat-selection-list>
          </div>
          <div class="footer">
            <p>Showing {{ appCount() }} out of {{ count }} reports</p>
            <pagination-controls
              id="analysedAppPagination"
              maxSize="5"
              responsive="true"
              previousLabel=""
              nextLabel=""
              (pageChange)="handlePageChange($event)"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="knowYourData && appToDisplay.length !== 0"
    class="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-xs-12 content-wrapper-right"
  >
    <div class="app-details">
      <div>
        <p class="app-title">{{ appLabel }}</p>
      </div>
      <div
        [ngClass]="
          accordance ? 'policy-accordance-green' : 'policy-accordance-pink'
        "
      >
        <div class="foreground">
          <img class="info" [src]="info" alt="info" />
          <span
            (click)="showDifferences()"
            [ngClass]="accordance ? 'text-green' : 'text-pink'"
            >Privacy policy is {{ accordance ? "" : "not" }} in accordance with
            your profile preferences.</span
          >
        </div>
        <div>
          <img class="top-shadow" [src]="topShadow" alt="top shadow" />
          <img class="bottom-shadow" [src]="bottomShadow" alt="bottom shadow" />
        </div>
      </div>
      <div class="row prose-rating">
        <div>
          <app-prose-rating
            *ngIf="proseRating"
            [isDialogParent]="false"
            [rating]="proseRating.privacy_policy_rating"
          ></app-prose-rating>
        </div>
        <div class="regulation-wrapper">
          <span class="regulation-title">Missing regulations</span>
          <span
            *ngIf="!proseRating || proseRating.missing_regulations.length === 0"
            class="regulation-btn"
          >
            <mat-icon>settings_suggest</mat-icon>
            <span>No missing regulations</span>
          </span>
          <p
            class="regulations-description"
            *ngIf="proseRating && proseRating.missing_regulations.length > 0"
          >
            Answers to following questions are not clearly addressed within this
            privacy policy.
          </p>
          <span
            *ngIf="proseRating && proseRating.missing_regulations.length > 0"
            class="regulation-btn"
          >
            <mat-icon>settings_suggest</mat-icon>
            <span>{{
              proseRating.missing_regulations[0].individual_description
            }}</span>
          </span>
          <span
            *ngIf="proseRating && proseRating.missing_regulations.length > 1"
            class="regulation-btn"
          >
            <mat-icon>settings_suggest</mat-icon>
            <span>{{
              proseRating.missing_regulations[1].individual_description
            }}</span>
          </span>
          <a
            *ngIf="
              proseRating !== null && proseRating.missing_regulations.length > 2
            "
            (click)="getRegulations()"
            class="regulation-link"
            >View all missing regulations</a
          >
        </div>
      </div>
      <div class="user-ratings">
        <app-user-rating
          [isDialogParent]="false"
          [appRatings]="appRatings"
          [appId]="appId"
          class="app-rating-chart"
        ></app-user-rating>
      </div>
      <div class="panel-header">
        <p>Know about your data</p>
        <img src="../../../../../assets/images/know_your_data.svg" alt="know you data" />
      </div>
      <mat-accordion #analyticsAccordion="matAccordion" multi>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >What data is collected and notice on special categories of
                personal data?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p
            class="what-collects"
            *ngFor="let party of knowYourData.what_is_collected | keyvalue"
          >
            <span class="party-label">{{ party.key }}:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of party.value; let i = index"
                (click)="getSentences('WHAT', party.key, value, $event)"
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <!-- <span class="party-value">
                            <span *ngFor="let value of party.value; let i = index" (click)="getSentences('WHAT', party.key, value)">{{ value }}{{ (i == (party.value.length - 1))? '.':';' }} </span>
                        </span> -->
          </p>
          <p
            class="not-available"
            *ngIf="isEmptyObject(knowYourData.what_is_collected)"
          >
            Information is not available.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >What is the purpose of data processing and notice on changing
                that purpose?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span class="multiline">
            <span class="party-label">Purpose:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="
                  let value of knowYourData.collecting_purpose;
                  let i = index
                "
                (click)="getSentences('WHY', 'Purpose', value, $event)"
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <!-- <span class="party-value">
                            <span *ngFor="let value of knowYourData.collecting_purpose; let i = index" (click)="getSentences('WHY', 'Purpose', value)">{{ value }}{{ (i == (knowYourData.collecting_purpose.length - 1))? '.':';' }} </span>
                        </span> -->
          </span>
          <p
            class="not-available"
            *ngIf="
              knowYourData.collecting_purpose === null ||
              knowYourData.collecting_purpose.length === 0
            "
          >
            Information is not available.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >What are the legal basis and legitimate interests to process
                data?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span class="multiline">
            <span class="party-label">Legal basis:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of knowYourData.legal_basis; let i = index"
                (click)="getSentences('WHY', 'Legal basis', value, $event)"
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <!-- <span class="party-value">
                            <span *ngFor="let value of knowYourData.legal_basis; let i = index" (click)="getSentences('WHY', 'Legal basis', value)">{{ value }}{{ (i == (knowYourData.legal_basis.length - 1))? '.':';' }} </span>
                        </span> -->
            <p
              class="not-available"
              *ngIf="
                knowYourData.legal_basis === null ||
                knowYourData.legal_basis.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>How long is data held - retention period?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span class="multiline">
            <span class="party-label">Retention period:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="
                  let value of knowYourData.retention_period;
                  let i = index
                "
                (click)="
                  getSentences('HOW LONG', 'Retention period', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <!-- <span class="party-value">
                            <span *ngFor="let value of knowYourData.retention_period; let i = index" (click)="getSentences('HOW LONG', 'Retention period', value)">{{ value }}{{ (i == (knowYourData.retention_period.length - 1))? '.':';' }} </span>
                        </span> -->
            <p
              class="not-available"
              *ngIf="
                knowYourData.retention_period === null ||
                knowYourData.retention_period.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>When will policy change and how will they notify You?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p
            class="what-collects"
            *ngFor="let party of knowYourData.when_and_how | keyvalue"
          >
            <span class="party-label">{{ party.key }}:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of party.value; let i = index"
                (click)="getSentences('WHEN AND HOW', party.key, value, $event)"
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <!-- <span class="party-value">
                            <span *ngFor="let value of party.value; let i = index" (click)="getSentences('WHEN AND HOW', party.key, value)">{{ value }}{{ (i == (party.value.length - 1))? '.':';' }} </span>
                        </span> -->
          </p>
          <p
            class="not-available"
            *ngIf="isEmptyObject(knowYourData.when_and_how)"
          >
            Information is not available.
          </p>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="panel-header">
        <p>Control your data</p>
        <img src="../../../../../assets/images/control_your_data.svg" alt="control your data" />
      </div>
      <mat-accordion #controlAccordion="matAccordion" multi>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Can you access your data?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Access data:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of controlYourData.access; let i = index"
                (click)="
                  getSentences(
                    'ACCESS DATA',
                    'Can you get access',
                    value,
                    $event
                  )
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.access === null ||
                controlYourData.access.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Can you transfer your data elsewhere?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Portability:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of controlYourData.portability; let i = index"
                (click)="
                  getSentences('DATA PORTABILITY', 'Portability', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.portability === null ||
                controlYourData.portability.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Can you object the processing of your data?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Can you object:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="
                  let value of controlYourData.object_processing;
                  let i = index
                "
                (click)="
                  getSentences(
                    'OBJECT THE PROCESSING OF DATA',
                    'Can you object',
                    value,
                    $event
                  )
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.object_processing === null ||
                controlYourData.object_processing.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Can you erase your data?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Erase:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of controlYourData.erase; let i = index"
                (click)="
                  getSentences('CONTROL YOUR DATA', 'Erase', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.erase === null ||
                controlYourData.erase.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Can you rectify your data?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Rectify:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of controlYourData.rectify; let i = index"
                (click)="
                  getSentences('CONTROL YOUR DATA', 'Rectify', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.rectify === null ||
                controlYourData.rectify.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >Do you have option to provide/withdraw consent for data
                processing?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Consent and withdrawal:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="
                  let value of controlYourData.consent_and_withdrawal;
                  let i = index
                "
                (click)="
                  getSentences(
                    'CONTROL YOUR DATA',
                    'Consent and withdrawal',
                    value,
                    $event
                  )
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.consent_and_withdrawal === null ||
                controlYourData.consent_and_withdrawal.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >Have they provided contact information for objecting data
                processing?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="controlYourData" class="multiline">
            <span class="party-label">Contact:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of controlYourData.contact; let i = index"
                (click)="
                  getSentences('CONTROL YOUR DATA', 'Contact', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                controlYourData.contact === null ||
                controlYourData.contact.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="panel-header">
        <p>Security and notice on personal data breach</p>
        <img src="../../../../../assets/images/security.svg" alt="security" />
      </div>
      <mat-accordion #securityAccordion="matAccordion" multi>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span
                >What security measures are applied to protect your data?</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="security" class="multiline">
            <span class="party-label">Security measures:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of security.security_measures; let i = index"
                (click)="
                  getSentences(
                    'DATA SECURITY',
                    'Security Measure',
                    value,
                    $event
                  )
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                security.security_measures === null ||
                security.security_measures.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="panel-header">
        <p>Specific audience</p>
        <img src="../../../../../assets/images/audience.svg" alt="audience" />
      </div>
      <mat-accordion #audienceAccordion="matAccordion" multi>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>settings_suggest</mat-icon>
              <span>Is there a note on specific audience?</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span *ngIf="audience" class="multiline">
            <span class="party-label">Children:</span>
            <mat-chip-list>
              <mat-chip
                #t
                *ngFor="let value of audience.specific_audience; let i = index"
                (click)="
                  getSentences('SPECIFIC AUDIENCE', 'children', value, $event)
                "
                [style.background]="getColor(t, i)"
                >{{ value }}</mat-chip
              >
            </mat-chip-list>
            <p
              class="not-available"
              *ngIf="
                audience.specific_audience === null ||
                audience.specific_audience.length === 0
              "
            >
              Information is not available.
            </p>
          </span>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
