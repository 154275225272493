import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-invitation-modal',
  templateUrl: './invitation-modal.component.html',
  styleUrls: ['./invitation-modal.component.scss']
})
export class InvitationModalComponent implements OnInit {

  @Input() individual;

  invitationForm: FormGroup;

  constructor(
    private api: ApiService,
    public modal: NgbActiveModal,
    private _notification: NotificationsService,
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    if (this.individual) {
      this.invitationForm = this._fb.group({
        email: ['', [Validators.email, Validators.required]]
      })
    } else {
      this.invitationForm = this._fb.group({
        first_name: ['', [Validators.required]],
        last_name: ['', [Validators.required]],
        email: ['', [Validators.email, Validators.required]]
      })
    }
  }

  close() {
    this.modal.close( false );
  }

  submitInvite() {
    if (this.invitationForm.valid) {
      if (this.individual) {
        this.api.inviteUser(this.invitationForm.getRawValue(), true).subscribe(
          res => {
            this.invitationForm.reset();
            this.invitationForm.controls.email.setErrors(null);
            this._notification.success('Success', 'Invitation has been sent')
            this.modal.close( true );
          },
          error => {
            let err = JSON.parse(JSON.stringify(error.error));
            this._notification.error('Invitation error', err.detail);
            this.modal.close( false );
          }
        )
      } else {
        this.api.inviteUser(this.invitationForm.getRawValue(), false).subscribe(
          res => {
            this.invitationForm.reset();
            this.invitationForm.controls.first_name.setErrors(null);
            this.invitationForm.controls.last_name.setErrors(null);
            this.invitationForm.controls.email.setErrors(null);
            this._notification.success('Success', 'Invitation has been sent')
            this.modal.close( true );
          },
          error => {
            let err = JSON.parse(JSON.stringify(error.error));
            this._notification.error('Invitation error', err.detail);
            this.modal.close( false );
          }
        )
      }
    } else {
      this.validateAllFormFields(this.invitationForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);            
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);          
      }
    });
  }

}
