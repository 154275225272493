
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { PasswordValidation } from '../../validation/password-validator';
import { NotificationsService } from 'angular2-notifications';
import { Location } from '@angular/common'

@Component({
  selector: 'app-forget-password-verif',
  templateUrl: './forget-password-verif.component.html',
  styleUrls: ['./forget-password-verif.component.scss']
})

export class ForgetPasswordVerifComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  private uid: string;
  private token: string;
  hide_pass1 = true; //password1 input eye btn
  // hide_pass2 = true; //password2 input eye btn
  hide = true;
  private individual: boolean;

  constructor(
    private _notification: NotificationsService,
    private api: ApiService,
    private _router: Router,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private _location: Location
  ) {
    this.resetPasswordForm = this.fb.group({
      new_password1: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)]],
      //     new_password2: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"'\/\(\)\[\]-]{8,}$/)]]
    }, // { validator: PasswordValidation.MatchPassword }
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params['token']
    })

    this.route.data.subscribe(data => {
      this.individual = data['individual']
    })
  }

  reset(resetPasswordForm: FormGroup) {
    if (resetPasswordForm.valid) {
      const resetPassword =
      {
        token: this.token,
        new_password: resetPasswordForm.controls.new_password1.value,
      };

      this.api.resetPassConfirm(resetPassword, this.individual).subscribe(
        res => {
          this._notification.success("Success", "Password has been reset");

          // const data = {
          //   individual: this.individual
          // }
      
          // let navigationExtras: NavigationExtras = {
          //   state: {
          //     data: data
          //   }
          // };
          
          // this._router.navigate(['/login'], navigationExtras);
          this._router.navigate(['/']);
        },
        error => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Password change error", err.detail);
        }
      );

    } else {
      this.validateAllFormFields(this.resetPasswordForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);            
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);          
      }
    });
  }

  backAction() {
    this._location.back()
  }

}
