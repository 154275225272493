import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SandboxDocumentResult } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { PurchaseDialogComponent } from './purchase-dialog/purchase-dialog.component';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { saveAs } from 'file-saver';
import { NotificationsService } from 'angular2-notifications';
import { FetchDataService } from 'src/app/services/fetch-data.service';
import { Subscription } from 'rxjs';
import { SharedPrivacyPolicy } from 'src/app/services/shared-data/privacy-policy';

@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss']
})
export class SandboxComponent implements OnInit, OnDestroy {

  @ViewChild("fileDropRef") fileDropEl: ElementRef;
  policy: File;
  isAllowedExtension = true;

  privacy_policies: Array<SandboxDocumentResult> = []

  fetchSubscription: Subscription

  displayedColumns: Array<string> = ['policy_name', 'policy_version', 'policy_document', 'prose_rating', 'acceptance_rate', 'detailed_report', 'actions'];

  page = 1
  pageSize = 3
  count: number

  documentId = -1

  constructor(
    private api: ApiService,
    private _fb: FormBuilder,
    private modal: NgbModal,
    private _notification: NotificationsService,
    private fetchDataService: FetchDataService,
    private sharedPrivacyPolicy: SharedPrivacyPolicy
    ) { 
      this.fetchSubscription = this.fetchDataService.analysedDocuments$.subscribe(
        isDataFetch => {
          this.getDocuments()
        }
      )
    }

  ngOnInit() {
    this.getDocuments()
  }

  ngOnDestroy() {
    this.fetchSubscription.unsubscribe();
  }

  getDocuments() {
    const params = {
      page: this.page,
      size: this.pageSize
    }

    this.api.getOrganizationDocuments(params).subscribe((resp) => {
      this.privacy_policies = resp.items;
      this.count = resp.total
    })
  }

  uploadFile(files: FileList) {
    if (files) {
      this.policy = files.item(0);

      if ((this.policy.type != 'application/pdf') && (this.policy.type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        this.isAllowedExtension = false;
      } else {
        this.fileDropEl.nativeElement.blur();
        this.openUploadModal();
      }
    } else {

    }
  }

  onFileDropped(files) {
    this.uploadFile(files);
  }

  openUploadModal(): void {
    const modalRef = this.modal.open(UploadDialogComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false
    })
    modalRef.componentInstance.purchase = true;
    modalRef.result.then((result) => {
      this.fileDropEl.nativeElement.value = '';
      if (result) {
        const payload = {
          privacy_policy_name: result.value.policy_name,
          privacy_policy_version: result.value.policy_version,
          privacy_policy_file: this.policy
        }
        this.api.uploadFile(payload).subscribe(
          res => {
          // this.privacy_policies.push(resp)
          // this.privacy_policies = [...this.privacy_policies];
          this.getDocuments()
        },
        error => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error('Upload file', err.detail);
        })
      }
    })
  }

  donwloadDocument(document) {
    this.api.downloadPolicyDocument(document.id).subscribe(data => {
      saveAs(data, document.privacy_policy_file_name);
    })
  }

  openPurchaseModal(id): void {
    this.documentId = id
    // const modalRef = this.modal.open(PurchaseDialogComponent, {
    //   centered: true,
    //   backdrop: 'static',
    // })

    // modalRef.result.then((result) => {
    //   if (result) {
    //     let index = this.privacy_policies.indexOf(id);
    //     if (index > 0) {
    //       this.privacy_policies[index].is_report_purchased = true;
    //       this.privacy_policies = [...this.privacy_policies];
    //     }
    //   }
    // })
  }

  handlePageChange(event) {
    this.page = event
    this.getDocuments()
  }

  appCount() {
    return (this.privacy_policies.length < this.pageSize)? this.privacy_policies.length : this.pageSize
  }

  deleteDocument(document_id) {
    this.api.deleteOrganizationDocuments(document_id).subscribe(
      res => {
        if (this.page > 1 && (this.privacy_policies.length - 1) % this.pageSize == 0) {
          this.page -= 1
        }

        this.getDocuments()
      },
      error => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error('Remove document', err.detail);
      }
    )
  }

  displaySandbox() {
    this.documentId = -1
    this.getDocuments()
    this.sharedPrivacyPolicy.unsetSandboxPrivacyPolicy();
  }
  
}
