<div class="wrapper">
  <div class="row">
    <div class="section-title">
      <span *ngIf="documentId === -1"> Sandbox </span>
      <div *ngIf="documentId !== -1">
        <span (click)="displaySandbox()" class="sandbox parent-title">
          Sandbox
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="
              background-size: 4.5px 9px;
              margin-left: 14px;
              margin-right: 14px;
            "
          >
            <path
              d="M1 10L5.5 5.5L1 1"
              stroke="#6B6B6B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span> Report </span>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div *ngIf="documentId === -1" class="content-wrapper">
    <app-sandbox-empty-state
      style="width: 100%"
      *ngIf="false"
    ></app-sandbox-empty-state>
    <div class="dnd-card">
      <div class="title">Upload privacy policy</div>
      <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
        <input
          type="file"
          #fileDropRef
          id="fileDropRef"
          (change)="uploadFile($event.target.files)"
        />
        <svg
          class="icon-cloud"
          viewBox="0 0 47 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.5 27L23.5 19M23.5 19L15.5 27M23.5 19V37M40.28 31.78C42.2307 30.7165 43.7717 29.0337 44.6597 26.9972C45.5478 24.9607 45.7324 22.6864 45.1844 20.5333C44.6364 18.3803 43.387 16.471 41.6333 15.1069C39.8797 13.7427 37.7217 13.0014 35.5 13H32.98C32.3746 10.6585 31.2463 8.48464 29.6799 6.64195C28.1135 4.79927 26.1497 3.33567 23.9362 2.36118C21.7227 1.3867 19.3171 0.926686 16.9002 1.01573C14.4834 1.10478 12.1181 1.74057 9.98234 2.8753C7.84656 4.01003 5.9958 5.61417 4.56923 7.56713C3.14265 9.52009 2.17736 11.771 1.74594 14.1508C1.31452 16.5305 1.42819 18.977 2.07841 21.3065C2.72862 23.6359 3.89847 25.7877 5.49998 27.6"
            stroke="#CC9B00"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="description">
          <div class="first-part">Drag & drop your file here</div>
          <div class="second-part">or</div>
          <label class="browse-btn" for="fileDropRef">
            <span>
              <svg
                class="icon-search"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21 21L16.65 16.65"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div>Browse</div>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="policies-table">
      <table
        appTableResponsive
        mat-table
        class="full-width-table"
        [dataSource]="
          privacy_policies
            | paginate
              : {
                  id: 'tablePagination',
                  itemsPerPage: pageSize,
                  currentPage: page,
                  totalItems: count
                }
        "
      >
        <ng-container matColumnDef="policy_name">
          <th mat-header-cell *matHeaderCellDef class="table-header">
            Policy name
          </th>
          <td mat-cell *matCellDef="let result" class="table-data">
            {{ result.privacy_policy_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="policy_version">
          <th mat-header-cell *matHeaderCellDef class="table-header">
            Policy version
          </th>
          <td mat-cell *matCellDef="let result" class="table-data">
            {{ result.privacy_policy_version }}
          </td>
        </ng-container>

        <ng-container matColumnDef="policy_document">
          <th mat-header-cell *matHeaderCellDef class="table-header">
            Privacy policy
          </th>
          <td
            mat-cell
            *matCellDef="let result"
            class="table-data data-link"
            (click)="donwloadDocument(result)"
          >
            {{ result.privacy_policy_file_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="prose_rating">
          <th mat-header-cell *matHeaderCellDef class="table-header">
            Pro Se rating
          </th>
          <td mat-cell *matCellDef="let result" class="table-data">
            <span *ngIf="result.analysed">{{ result.rating }}%</span>
            <span *ngIf="!result.analysed" class="pending"
              >pending analytics</span
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="acceptance_rate">
          <th mat-header-cell *matHeaderCellDef class="table-header">
            Acceptance rate
          </th>
          <td mat-cell *matCellDef="let result" class="table-data">
            <span *ngIf="result.analysed">{{ result.acceptance_rate }}%</span>
            <span *ngIf="!result.analysed" class="pending"
              >pending analytics</span
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="detailed_report">
          <th mat-header-cell *matHeaderCellDef class="table-header">
            Detailed report
          </th>
          <td mat-cell *matCellDef="let result" class="table-data">
            <!-- <div *ngIf="result.is_report_purchased" class="data-link">Show report</div> -->
            <button
              class="purchase-btn"
              [disabled]="!result.analysed"
              (click)="openPurchaseModal(result.id)"
            >
              Show report
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let result" class="table-data">
            <span class="button-wrapper" style="max-width: 40px">
              <button mat-icon-button (click)="deleteDocument(result.id)">
                <svg
                  width="18"
                  height="20"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="action-button"
                >
                  <path
                    d="M1 5H3M3 5H19M3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V5H3ZM6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5M8 10V16M12 10V16"
                    stroke="#6B6B6B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <!-- <button (click)="$event.stopPropagation()" (mousedown)="$event.stopPropagation()" class="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="documentId = result.id">Show report</button>
                  <button mat-menu-item (click)="deleteDocument(result.id)">Remove</button>
              </mat-menu> -->
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="pagination-holder">
      <span class="info"
        >Showing {{ appCount() }} out of {{ count }} reports</span
      >
      <pagination-controls
        id="tablePagination"
        maxSize="5"
        responsive="true"
        previousLabel=""
        nextLabel=""
        (pageChange)="handlePageChange($event)"
      ></pagination-controls>
    </div>
  </div>
  <div *ngIf="documentId !== -1" class="scroll">
    <app-sandbox-report [documentId]="documentId"></app-sandbox-report>
  </div>
</div>
