<div class="analysing-wrapper">
    <div class="header">
        <span class="title">Analysing policy</span>
        <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="row content">
        <span class="info">Please be patient. Your policy will be analysed within few minutes..</span>
        <img src="../../../../../assets/images/analysing_image.svg" alt="analysing">
    </div>
    <div class="footer">
        <button class="mt-2 submit-btn" mat-raised-button (click)="cancel()">OK</button>
    </div>
</div>