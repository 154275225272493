import { Component, Input, OnChanges } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { ApplicationRating } from "src/app/models/app.model";
import { ApiService } from "src/app/services/api.service";
import { WebApiService } from "src/app/services/web-api.service";

@Component({
  selector: "app-user-rating",
  templateUrl: "./user-rating.component.html",
  styleUrls: ["./user-rating.component.scss"],
})
export class UserRatingComponent implements OnChanges {
  isBusinessUser = false;
  isAndroid = true;
  userRate = 0;
  @Input() appRatings!: ApplicationRating;
  @Input() appId: number;
  @Input() baseUrl?: string;
  @Input() platform?: string;
  totalCount = 0;

  fiveStars = 0;
  fourStars = 0;
  threeStars = 0;
  twoStars = 0;
  oneStar = 0;

  @Input() isDialogParent = false;

  userRating = 0.0;

  constructor(
    private api: ApiService,
    private web_api: WebApiService,
    private _notification: NotificationsService
  ) {
    this.isBusinessUser = !JSON.parse(localStorage.getItem("individual"));
  }

  onRateChange(event) {
    if (this.appRatings.ratings["0.0"] != event) {
      if (this.platform && this.platform === "web" && this.baseUrl) {
        this.web_api.getBusinessApplicationDetails(null, this.appId).subscribe(
          (res) => {
            const payload = {
              base_url: this.baseUrl,
              privacy_policy_link:
                res.privacy_policies__[0].privacy_policy_link,
              rating: event,
            };

            this.web_api.rateApplication(payload).subscribe(
              (res) => {
                this.appRatings.ratings[
                  `${this.appRatings.ratings["0.0"]}.0`
                ] -= 1;
                this.appRatings.ratings[`${event}.0`] += 1;
                this.appRatings.ratings["0.0"] = event;

                this.calculateRatings();
              },
              (error) => {
                let err = JSON.parse(JSON.stringify(error.error));
                this._notification.error("Rate application", err.detail);
              }
            );
          },
          (error) => {
            let err = JSON.parse(JSON.stringify(error.error));
            // this._notification.error('Get apps error', err.detail);
          }
        );
      } else {
        this.api
          .getBusinessApplicationDetails(null, this.appId, true)
          .subscribe(
            (res) => {
              const payload = {
                app_id: res.app_id,
                privacy_policy_link:
                  res.privacy_policies__[0].privacy_policy_link,
                rating: event,
              };

              this.api.rateApplication(payload, this.isAndroid).subscribe(
                (res) => {
                  this.appRatings.ratings[
                    `${this.appRatings.ratings["0.0"]}.0`
                  ] -= 1;
                  this.appRatings.ratings[`${event}.0`] += 1;
                  this.appRatings.ratings["0.0"] = event;

                  this.calculateRatings();
                },
                (error) => {
                  let err = JSON.parse(JSON.stringify(error.error));
                  this._notification.error("Rate application", err.detail);
                }
              );
            },
            (error) => {
              let err = JSON.parse(JSON.stringify(error.error));
              // this._notification.error('Get apps error', err.detail);
            }
          );
      }
    }
  }

  calculateRatings(): void {
    if (this.appRatings != undefined) {
      this.totalCount = 0;
      Object.keys(this.appRatings.ratings).forEach((key) => {
        if (key != "0.0") this.totalCount += this.appRatings.ratings[key];
      });

      this.fiveStars = this.totalCount
        ? (this.appRatings.ratings["5.0"] / this.totalCount) * 100
        : 0;
      this.fourStars = this.totalCount
        ? (this.appRatings.ratings["4.0"] / this.totalCount) * 100
        : 0;
      this.threeStars = this.totalCount
        ? (this.appRatings.ratings["3.0"] / this.totalCount) * 100
        : 0;
      this.twoStars = this.totalCount
        ? (this.appRatings.ratings["2.0"] / this.totalCount) * 100
        : 0;
      this.oneStar = this.totalCount
        ? (this.appRatings.ratings["1.0"] / this.totalCount) * 100
        : 0;

      this.userRating = this.totalCount
        ? (5 * this.appRatings.ratings["5.0"] +
            4 * this.appRatings.ratings["4.0"] +
            3 * this.appRatings.ratings["3.0"] +
            2 * this.appRatings.ratings["2.0"] +
            1 * this.appRatings.ratings["1.0"]) /
          this.totalCount
        : 0.0;
    }
  }

  ngOnChanges() {
    if (this.appRatings != undefined) {
      this.calculateRatings();

      if (this.appRatings.ratings["0.0"] != 0) {
        this.userRate = this.appRatings.ratings["0.0"];
      }
    }
  }
}
