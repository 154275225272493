<div class="wrapper">
    <app-onboard-header></app-onboard-header>
    <div class="content-wrapper">
        <a class="back-link" [routerLink]="'/' + profileType + '/preferences-wizard'">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#5F3B19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </a>
        <div class="title-label">
            Which profile type is in accordance with your privacy preferences?
        </div>
        <div class="preference-card">
            <div class="preferences-card-details" (click)="onPrefClick('/static-preference/EXTREME')">
                <div class="row" style="height: 100%;">
                    <div class="col-7">
                        <div class="title">Guarded</div>
                        <div class="description">I'm extremely protective of my privacy</div>
                    </div>
                    <div class="col-5">
                        <img class="preference-image" src="../../../../assets/images/guarded_character_svg.svg" alt="guarded character">
                    </div>
                </div> 
            </div>
            <ng-lottie
                [height]="'100%'"
                [width]="'100%'"
                [options]="guarderAnimationOptions"
            >
            </ng-lottie>
        </div>
        <div class="preference-card" (click)="onPrefClick('/static-preference/MODERATE')">
            <div class="preferences-card-details">
                <div class="row" style="height: 100%;">
                    <div class="col-7">
                        <div class="title">Vigilant</div>
                        <div class="description">I care about protecting my privacy</div>
                    </div>
                    <div class="col-5">
                        <img class="preference-image" src="../../../../assets/images/vigilant-character-svg.svg" alt="vigilant character">
                    </div>
                </div> 
            </div>
            <ng-lottie
                [height]="'100%'"
                [width]="'100%'"
                [options]="customAnimationOptions"
            ></ng-lottie>
        </div>
        <div class="preference-card" (click)="onPrefClick('/static-preference/RELAXED')">
            <div class="preferences-card-details">
                <div class="row" style="height: 100%;">
                    <div class="col-7">
                        <div class="title">Laid-back</div>
                        <div class="description">I'm not to adamant about privacy protection</div>
                    </div>
                    <div class="col-5">
                        <img class="preference-image" src="../../../../assets/images/laid-back-character-svg.svg" alt="laid back character">
                    </div>
                </div> 
            </div>
            <ng-lottie
                [height]="'100%'"
                [width]="'100%'"
                [options]="laidbackAnimationOptions"
            ></ng-lottie>
        </div>
        <div class="preference-card" (click)="onPrefClick('/custom-preferences')">
            <div class="preferences-card-details">
                <div class="row" style="height: 100%;">
                    <div class="col-7">
                        <div class="title">Custom</div>
                        <div class="description">I want to create my own profile type</div>
                    </div>
                    <div class="col-5">
                        <img class="preference-image" style="border-radius: 0 10% 10% 0;" src="../../../../assets/images/custom-character-svg.svg" alt="custom character">
                    </div>
                </div> 
            </div>
            <ng-lottie
                [height]="'100%'"
                [width]="'100%'"
                [options]="vigilantAnimationOptions"
            ></ng-lottie>
        </div>
    </div>
</div>
