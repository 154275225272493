import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { SharedPrivacyPolicy } from 'src/app/services/shared-data/privacy-policy';
import { decode, encode } from 'gpt-tokenizer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  @ViewChild('messageInput') messageInput: ElementRef;  
  @ViewChild('deleteIcon') deleteIcon: ElementRef; 
  @ViewChild('messagesEl') messagesEl: ElementRef;

  private initSystemContent: string[] = [
    "You are legal assistant for scrutiny of privacy policies. Please answer any questions user might have regarding their rights in line with the provided privacy policy for a mobile application. Also make sure to cross reference privacy policy of the mobile application with the permissions required by the mobile application. It is important that privacy policy reference all data permitted for collection by mobile application.",
    "You are legal assistant for scrutiny of privacy policies. Please answer any questions user might have regarding their rights in line with the provided privacy policy and GDPR regulative."
  ];
  private routerEventsSubscription: Subscription;
  private dataChangesSubscription: Subscription;
  private controller:any;
  private conversationId: number;
  private previousRoute:string = "";
  private isBusinessUser = !JSON.parse(localStorage.getItem('individual'));
  private authToken:string = localStorage.getItem('token');
  private modelAPI = this.isBusinessUser ? 'https://async.prose.biz/llm/business/v1/chat/completions' : 'https://async.prose.biz/llm/individual/v1/chat/completions';
  public messageForm: FormGroup;
  private maxTokenLength:number = 21500;
  public isChatbotOpened = true;
  public activeScreen = 'chat';
  public messages: { role: "user" | "assistant" | "system", content: string, time?:any }[] = [];
  public conversations:any[] = []
  public isFormDisabled = false;
  public initChatbotContentMessage:string = "";
  public shouldDisplayArrowBack:boolean = false;
  public isChatbotTyping:boolean = false;
  public isDeleteIconHovered:boolean = false;
  public chatbotName:string = 'PS Bot';
  public questions:any[] = [
    {
      name: 'Short summary',
      context: 'Write a short summary of this privacy policy in a user friendly style without using complex legal language. Focus on what is important from end user perspective including my right, the data they collect from me and how is my data handled. Please provide summary up to 200 words.'
    },
    {
      name: 'Check for red flags',
      context: "Analyze this privacy policy and check if it is GDPR compliant. Make sure to detect and stress out any inconsistencies within the privacy policy especially regarding the data they collect, retention period, right to be forgotten, children's data, consent management, data portability right, data security. Also list contradictory statements in the privacy policy. Please provide your findings in a user friendly style and try to avoid complex legal language. Use less then 200 words."
    }
  ];

  constructor(
    public sharedPrivacyPolicy: SharedPrivacyPolicy,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.detectRouteChange();
    this.detectDataChanges();
  }

  ngOnDestroy(){
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
    if (this.dataChangesSubscription) {
      this.dataChangesSubscription.unsubscribe();
    }
  }

  private initializeForm() {
    this.messageForm = this.formBuilder.group({
      message: ['',[Validators.maxLength(500)]]
    });
  }

  private detectRouteChange() {
    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if(this.router.url !== this.previousRoute){
        this.previousRoute = this.router.url;
        this.sharedPrivacyPolicy.resetData();
        if (this.router.url === '/dashboard/mobile') {
          this.initChatbotContentMessage = this.initSystemContent[0];
        } else if (this.router.url === '/dashboard/web' || this.router.url === '/sandbox') {
          this.initChatbotContentMessage = this.initSystemContent[1];
        }
      }
    });
  }

  private detectDataChanges() {
    this.dataChangesSubscription = this.sharedPrivacyPolicy.getActivePrivacyPolicyObservable().subscribe(data => {
      this.cancelAPI();
      this.activeScreen = "chat";
      this.isChatbotTyping = false;
      this.setActivePrivacyPolicy();
      this.focusInputMessageElement();
    });
  }

  setActivePrivacyPolicy(){
    if (this.sharedPrivacyPolicy.getActivePrivacyPolicy()) {
      const conversations = localStorage.history && JSON.parse(localStorage.history).find((webapp) => webapp.id === this.sharedPrivacyPolicy.getActivePrivacyPolicy().id)?.conversations;
      if(conversations?.length > 0){
        const conversation = conversations.slice(-1)[0];
        this.conversations = conversations;
        this.messages = conversation.messages;
        this.conversationId = conversation.id;
      }
      else{
        this.messages = [{role:'assistant',content: 'Hello! How can i assist you today?',time: new Date}];
        this.conversations = [];
        this.conversationId = this.generateRandomNumber();
      }
      if(this.conversations.length > 0){
        this.shouldDisplayArrowBack = true;
      }
      else{
        this.shouldDisplayArrowBack = false;
      }
    }
  }

  toggleChatbot() {
    this.isChatbotOpened = !this.isChatbotOpened;
    if(this.isChatbotOpened){
      this.focusInputMessageElement();
    }
  }

  changeScreen() {
    this.cancelAPI();
    if(this.activeScreen === 'chat'){
      this.activeScreen = 'conversations';
    }
    else{
      this.activeScreen = 'chat';
      this.focusInputMessageElement();
    }
  }

  sendMessage(question) {
    let newMessageContent:string;
    if(question){
      newMessageContent = question;
    }
    else{
      newMessageContent = this.messageForm.value.message;
    }
    if (newMessageContent.trim().length > 0) {
      this.isFormDisabled = true;
      this.messagesEl.nativeElement.scrollTop = this.messagesEl.nativeElement.scrollHeight;
      this.messages.unshift({ role: 'user', content: newMessageContent, time: new Date()});
      this.saveConversationToLocal();
      this.updateConversations();
      this.shouldDisplayArrowBack = true;
      this.messageForm.reset();
      this.getStream();
    }
  }

  async getStream(){
    this.controller = new AbortController();
    fetch(this.modelAPI,{
      method:'POST',
      body:JSON.stringify(this.prepareParams()), 
      headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authToken}`
     },
     signal:this.controller.signal
    })
    .then((response) => response?.body.pipeThrough(new TextDecoderStream()).getReader())
    .then(async (reader) => {
      if(!reader) return;
      this.isChatbotTyping = true;
      let isFirstMessageCreated:boolean = false;
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        let dataDone = false;
        const arr = value.split('\n');
        arr.forEach((data) => {
          if (data.length === 0) return;
          if (data.startsWith(':')) return;
          if (data === 'data: [DONE]') {
            dataDone = true;
            return;
          }
          const json = JSON.parse(data.substring(6));
          if(!json.choices[0].delta.role){
            this.isChatbotTyping = false;
            if(!isFirstMessageCreated){
              this.messages.unshift({role: 'assistant',content: '',time: new Date()});
              isFirstMessageCreated = true;
            }
            this.messages[0].content += json.choices[0].delta.content;   
            this.saveConversationToLocal();
            this.updateConversations();
          }
        });
        if (dataDone) break;
      }
    })  
    .finally(() => {
      this.isChatbotTyping = false;
      this.isFormDisabled = false;
      this.focusInputMessageElement();
     })
  }

  prepareParams(){
    let systemContent = this.trimSystemContent(20999);
    let params = {
      "model": "prose_chat",
      "messages": [{ role: 'system', content: systemContent}],
      "temperature": 0.1,
      "stream": true
    }
    let tokenCounter = encode(systemContent).length + 1;
    let messages:any[] = [];
    for(let i = 0; i < this.messages.length - 1; i++){
      if(i === 0 || this.messages[i].role !== this.messages[i - 1].role){
        tokenCounter += (encode(this.messages[i].content).length +  1);
        if(tokenCounter < this.maxTokenLength){
          messages.unshift({role: this.messages[i].role,content: this.messages[i].content});
        }
        else{
          break;
        }
      }
    }
    params.messages = [...params.messages, ...messages];
    return params;
  }

  trimSystemContent(length){
    return decode(encode(`${this.initChatbotContentMessage} Here is the privacy policy text: ${this.sharedPrivacyPolicy.getActivePrivacyPolicy().text}`).slice(0,length));
  }

  updateConversations(){
    const matchedIndex = this.conversations.findIndex(conversation => conversation.id === this.conversationId);
    if (matchedIndex === -1) {
      this.conversations.push({ id: this.conversationId, messages: this.messages });
    } else {
      this.conversations[matchedIndex].messages = this.messages;
    }
  }

  deleteConversation(conversationId:number){
    this.conversations = this.conversations.filter((conversation) => conversation.id !== conversationId);
    this.deleteConversationFromLocal();
  }

  makeNewChat(){
    this.cancelAPI();
    this.messages = [{role:'assistant',content:"Hello! How can i assist you today?", time: new Date()}];
    this.conversationId = this.generateRandomNumber();
    this.changeScreen();
    this.focusInputMessageElement();
    if(this.conversations.length > 0){
      this.shouldDisplayArrowBack = true;
    }
    else{
      this.shouldDisplayArrowBack = false;
    }
  }

  selectConversation(conversationId:number){
    this.cancelAPI();
    this.messages = this.conversations.find((conversation) => conversation.id === conversationId).messages;
    this.conversationId = conversationId;
    this.changeScreen();
    this.focusInputMessageElement();
  }
  
  private saveConversationToLocal() {
    let history = localStorage.history ? JSON.parse(localStorage.history) : [];
    const webappIndex = history.findIndex((webapp) => webapp.id === this.sharedPrivacyPolicy.getActivePrivacyPolicy().id);
    if (webappIndex !== -1) {
        const webapp = history[webappIndex];
        const conversationIndex = webapp.conversations.findIndex((conversation) => conversation.id === this.conversationId);
        if (conversationIndex !== -1) {
            webapp.conversations[conversationIndex].messages = this.messages;
        } else {
            webapp.conversations.push({ id: this.conversationId, messages: this.messages });
        }
    } else {
        history.push({ id: this.sharedPrivacyPolicy.getActivePrivacyPolicy().id, conversations: [{ id: this.conversationId, messages: this.messages }] });
    }
    localStorage.history = JSON.stringify(history);
  }

  deleteConversationFromLocal(){
    let history = JSON.parse(localStorage.history);
    const conversationsIds = this.conversations.map((conversation) => conversation.id);
    if(conversationsIds.length > 0){
      const webappIndex = history.findIndex((webapp) => webapp.id === this.sharedPrivacyPolicy.getActivePrivacyPolicy().id);
      history[webappIndex].conversations = history[webappIndex].conversations.filter((conversation) => conversationsIds.includes(conversation.id));
    }
    else{
      history = history.filter((webapp) => webapp.id !== this.sharedPrivacyPolicy.getActivePrivacyPolicy().id);
    }
    localStorage.history = JSON.stringify(history);
  }

  cancelAPI(){
    this.controller?.abort();
    this.isFormDisabled = false;
  }

  formatDate(timestamp:string,format:string){
    if(format === 'date'){
      return new Date(timestamp).toDateString();
    }
    else{
      return new Date(timestamp).toDateString() + " " + new Date(timestamp).toLocaleTimeString();
    }
  }

  focusInputMessageElement(){
    setTimeout(()=>{
      this.messageInput.nativeElement.focus();
    },500);
  }

  handleMouseOver(event: any) {
    event.target.src = '../../../../assets/imgs/hovered-delete-icon.svg';
  }

  handleMouseOut(event: any) {
    event.target.src = '../../../../assets/imgs/delete-icon.svg';
  }

  trimMessage(message){
    const maxLength = 25;
    if(message.length > maxLength){
      message = message.slice(0,maxLength) + '...';
    }
    return message;
  }

  generateRandomNumber(){
    return Math.floor(Math.random() * 1000000);
  }
}
