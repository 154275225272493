import { Component, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationsService } from "angular2-notifications";
import { ApiService } from "src/app/services/api.service";
import { IndividualUsersComponent } from "../individual-users/individual-users.component";
import { InvitationModalComponent } from "../invitation-modal/invitation-modal.component";

@Component({
  selector: "app-management-individual",
  templateUrl: "./management-individual.component.html",
  styleUrls: ["./management-individual.component.scss"],
})
export class ManagementIndividualComponent {
  @ViewChild("users")
  private userList: IndividualUsersComponent;

  constructor(
    private api: ApiService,
    private _modal: NgbModal,
    private _notification: NotificationsService,
    private _fb: FormBuilder
  ) {}

  openInvitationModal(): void {
    const modalRef = this._modal.open(InvitationModalComponent, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.individual = true;
    modalRef.result.then((result) => {
      if (result) {
        this.userList.fetchUsers();
      }
    });
  }
}
