<div class="row wrapper">
    <div class="section-title">
        <span>
            Contact us
        </span>
    </div>
    <div class="col-lg-8 col-sm-10 col-xs-12 content-wrapper">
        <form [formGroup]="contactForm" class="contact-form" id="contactForm">
            <div class="row field-wrapper">
                <div class="col">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Subject</mat-label>
                            <input formControlName="subject" autocomplete="off" placeholder="Subject" matInput>
                        </mat-form-field>
                        <small *ngIf="contactForm.get('subject').hasError('required') && contactForm.get('subject').touched"
                            id="contactHelp1" class="form-text danger">
                            Please enter subject
                        </small>
                    </div>
                </div>
            </div>
            <div class="row textarea-wrapper">
                <div class="col">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Your message...</mat-label>
                            <textarea formControlName="message" rows="5" autocomplete="off" matInput></textarea>
                        </mat-form-field>
                        <small *ngIf="contactForm.get('message').hasError('required') && contactForm.get('message').touched"
                            id="contactHelp2" class="form-text danger-message">
                            Please enter your message
                        </small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button mat-raised-button ngbAutofocus (click)="send(contactForm)" class="submit-btn"
                        form="contactForm">Send</button>
                </div>
            </div>
        </form>
    </div>
</div>
