import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class NameUpdateService {

  constructor() { }

  private nameChangedSource = new Subject<string>();

  nameChanged$ = this.nameChangedSource.asObservable();

  changeName(newName: string) {
    this.nameChangedSource.next(newName);
  }

}
