import { animate, style, transition, trigger } from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { MatListOption, MatSelectionList } from "@angular/material/list";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationsService } from "angular2-notifications";
import { Subscription } from "rxjs";
import {
  ApplicationRating,
  Audience,
  ControlYourData,
  CrossReferencedResults,
  KnowYourData,
  ProSeRating,
  Security,
} from "src/app/models/app.model";
import { ApiService } from "src/app/services/api.service";
import { DeleteAppService } from "src/app/services/delete-app.service";
import { FetchDataService } from "src/app/services/fetch-data.service";
import { ChooseDialogComponent } from "../choose-dialog/choose-dialog.component";
import { PolicyAccordanceDialogComponent } from "../policy-accordance-dialog/policy-accordance-dialog.component";
import { RegulationsDialogComponent } from "../regulations-dialog/regulations-dialog.component";
import { SentencesDialogComponent } from "../sentences-dialog/sentences-dialog.component";
import { SharedPrivacyPolicy } from "src/app/services/shared-data/privacy-policy";

@Component({
  selector: "app-analysed-policies",
  templateUrl: "./analysed-policies.component.html",
  styleUrls: ["./analysed-policies.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AnalysedPoliciesComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  @ViewChild("appSelectionList")
  private selectionList?: MatSelectionList;

  @ViewChild("analyticsAccordion")
  private accordion?: MatAccordion;

  @ViewChild("controlAccordion")
  private controlAccordion?: MatAccordion;

  @ViewChild("securityAccordion")
  private securityAccordion?: MatAccordion;

  @ViewChild("securityAccordion")
  private audienceAccordion?: MatAccordion;

  isBusinessUser = false;

  private _isAndroid: boolean;

  @Input() set isAndroid(value: boolean) {
    this._isAndroid = value;
    this.onMobileTypeChange();
  }

  applications: any;
  appToDisplay: any;
  lastSelectedAppId = -1;

  accordance = true;
  crossReferenceResults: CrossReferencedResults;
  proseRating: ProSeRating = null;

  topShadow = "";
  bottomShadow = "";
  info = "";

  searchTerm = "";

  knowYourData: KnowYourData = null;
  controlYourData: ControlYourData = null;
  security: Security = null;
  audience: Audience = null;

  appRatings: ApplicationRating;
  appId: number;
  appLabel: any;

  page = 1;
  pageSize = 5;
  count: number;

  isPremium = true;
  isPremiumIndividual = false;

  subscription: Subscription;
  fetchSubscription: Subscription;

  emptySearchResults = false;

  colorsEven = ["#998616", "#D99266"];
  colorsOdd = ["#5F3B19", "#CCA96A", "#CC9B00"];

  constructor(
    private api: ApiService,
    private _notification: NotificationsService,
    private _modal: NgbModal,
    private cdr: ChangeDetectorRef,
    private deleteAppService: DeleteAppService,
    private fetchDataService: FetchDataService,
    private sharedPrivacyPolicy: SharedPrivacyPolicy
  ) {
    this.isBusinessUser = !JSON.parse(localStorage.getItem("individual"));

    this.subscription = this.deleteAppService.applicationDeleted$.subscribe(
      (deletedApp) => {
        if (this.appToDisplay && this.appToDisplay[0].id == deletedApp) {
          this.lastSelectedAppId = -1;
          this.resetAIResults();
        }

        if (
          this.page > 1 &&
          (this.applications.length - 1) % this.pageSize == 0
        ) {
          this.page -= 1;
        }

        this.getApplications();
      }
    );

    this.fetchSubscription = this.fetchDataService.analysedPolicies$.subscribe(
      (isDataFetch) => {
        this.getApplications();
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.fetchSubscription.unsubscribe();
  }

  ngOnInit() {
    this.getApplications();
    this.setUpImages();

    if (!this.isBusinessUser) {
      this.getSubscription();
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();

    if (this.selectionList && this.selectionList.selectedOptions["_multiple"]) {
      this.selectionList.selectedOptions = new SelectionModel<MatListOption>(
        false
      );
    }
  }

  setUpImages() {
    if (this.accordance) {
      this.info = "../../../../../assets/images/info-i-green.svg";
      this.topShadow = "../../../../../assets/images/top-part-green.svg";
      this.bottomShadow = "../../../../../assets/images/bottom-part-green.svg";
    } else {
      this.info = "../../../../../assets/images/info-i-pink.svg";
      this.topShadow = "../../../../../assets/images/top-part-pink.svg";
      this.bottomShadow = "../../../../../assets/images/bottom-part-pink.svg";
    }
  }

  getApplications() {
    const params = {
      page: this.page,
      size: this.pageSize,
    };

    this.api
      .getBusinessAnalysedApplicationList(params, this._isAndroid)
      .subscribe(
        (res) => {
          this.applications = res.items;
          this.count = res.total;
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Get applications", err.detail);
        }
      );
  }

  getSubscription() {
    this.api.getSubscription(this._isAndroid).subscribe(
      (res) => {
        if (res.is_free_trial || res.is_entitlement_active) {
          this.isPremiumIndividual = true;
        }
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
      }
    );
  }

  handlePageChange(event) {
    this.page = event;

    if (this.searchTerm != "") {
      this.onSearch(true);
    } else {
      this.getApplications();
    }
  }

  onAppSelectionChange(value): void {
    this.appToDisplay = value;
    if (this.appToDisplay[0]) {
      if (this.accordion) {
        this.accordion.closeAll();
      }

      if (this.controlAccordion) {
        this.controlAccordion.closeAll();
      }

      if (this.securityAccordion) {
        this.securityAccordion.closeAll();
      }

      if (this.audienceAccordion) {
        this.audienceAccordion.closeAll();
      }

      this.lastSelectedAppId = this.appToDisplay[0].id;
      this.appId = this.appToDisplay[0].id;
      this.appLabel = this.appToDisplay[0].label;

      this.getAIResults();

      const ratingParams = this.isBusinessUser
        ? {
            id: this.appToDisplay[0].app_id,
            mobile_type: this.appToDisplay[0].type,
          }
        : {
            id: this.appToDisplay[0].app_id,
          };

      this.api.getApplicationRatings(ratingParams, this._isAndroid).subscribe(
        (res) => {
          this.appRatings = res;
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Application ratings", err.detail);
        }
      );

      const detailParams = {
        mobile_type: this.appToDisplay[0].type,
      };

      this.api
        .getBusinessApplicationDetails(
          detailParams,
          this.appToDisplay[0].id,
          this._isAndroid
        )
        .subscribe(
          (res) => {
            this.sharedPrivacyPolicy.selectPrivacyPolicy({
              id: res.id,
              type:'analyzed polices',
              appName: res.label,
              appLogo: res.image_link,
              link: res.privacy_policies__[0].privacy_policy_link,
              text: res.privacy_policies__[0].privacy_policy_text,
              appPermissions: res.permissions
            })
            const aiParams = this.isBusinessUser
              ? {
                  privacy_policy_id: res.privacy_policies__[0].id,
                  mobile_type: res.type,
                }
              : {
                  privacy_policy_id: res.privacy_policies__[0].id,
                };

            this.api
              .getCrossReferencedResults(aiParams, this._isAndroid)
              .subscribe(
                (res) => {
                  this.accordance = !res.differences;
                  this.crossReferenceResults = res;
                  this.setUpImages();
                },
                (error) => {
                  let err = JSON.parse(JSON.stringify(error.error));
                  this._notification.error(
                    "Cross referenced results",
                    err.detail
                  );
                }
              );

            const ratingParams = {
              mobile_type: this.appToDisplay[0].type,
            };

            this.api
              .getProSeRating(
                this._isAndroid,
                res.privacy_policies__[0].id,
                ratingParams
              )
              .subscribe(
                (res) => {
                  this.proseRating = res;
                },
                (error) => {
                  let err = JSON.parse(JSON.stringify(error.error));
                  this._notification.error("Pro Se rating", err.detail);
                }
              );
          },
          (error) => {
            let err = JSON.parse(JSON.stringify(error.error));
            this._notification.error("App details", err.detail);
          }
        );
    } else {
      this.lastSelectedAppId = -1;
      this.resetAIResults();
      this.sharedPrivacyPolicy.unselectPrivacyPolicy('analyzed polices');
    }
  }

  isAppSelected(id) {
    return this.lastSelectedAppId === id;
  }

  appCount() {
    return this.applications.length < this.pageSize
      ? this.applications.length
      : this.pageSize;
  }

  appComparison() {
    if (this.isPremium) {
      const modalRef = this._modal.open(ChooseDialogComponent, {
        size: "md",
        centered: true,
        backdrop: "static",
        keyboard: false,
      });
    } else {
      alert("Default comparasion is under construction!");
    }
  }

  getRegulations() {
    const modalRef = this._modal.open(RegulationsDialogComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.missingRegulations =
      this.proseRating.missing_regulations;
  }

  getSentences(category, attribute, value, $event) {
    $event.srcElement.blur();
    const appMetadata = {
      category: category,
      attribute: attribute,
      value: value,
      id: this.appToDisplay[0].id,
      mobile_type: this.appToDisplay[0].type,
      isAndroid: this._isAndroid,
      sandbox: false,
    };

    const modalRef = this._modal.open(SentencesDialogComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    modalRef.componentInstance.appMetadata = appMetadata;
  }

  showDifferences() {
    if (!this.accordance) {
      const modalRef = this._modal.open(PolicyAccordanceDialogComponent, {
        size: "lg",
        centered: true,
        backdrop: "static",
        keyboard: false,
      });

      const appMetadata = {
        id: this.appToDisplay[0].id,
        mobile_type: this.appToDisplay[0].type,
        isAndroid: this._isAndroid,
        label: this.appToDisplay[0].label,
        sandbox: false,
      };

      modalRef.componentInstance.crossReferenceResults =
        this.crossReferenceResults;
      modalRef.componentInstance.appMetadata = appMetadata;
    }
  }

  onMobileTypeChange() {
    this.resetAIResults();

    this.lastSelectedAppId = -1;
    this.appToDisplay = [];
    this.getApplications();
  }

  onSearch(isNextPage) {
    if (this.searchTerm == "") {
      this.page = 1;
      this.getApplications();
    } else {
      if (!isNextPage) {
        this.page = 1;
      }

      const params = {
        term: this.searchTerm,
        page: this.page,
        size: this.pageSize,
      };

      this.api.searchAnalysedApps(params, this._isAndroid).subscribe(
        (res) => {
          if (res.total == 0) {
            this.emptySearchResults = true;
            setTimeout(() => {
              this.emptySearchResults = false;
            }, 5000);
          } else {
            this.applications = res.items;
            this.count = res.total;
          }
        },
        (error) => {}
      );
    }
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  getColor(chip, index): string {
    if (chip && chip.style && chip.style.background) {
      return chip.style.background;
    } else {
      if (index % 2 == 0) {
        return this.colorsEven[(Math.random() * this.colorsEven.length) | 0];
      } else {
        return this.colorsOdd[(Math.random() * this.colorsOdd.length) | 0];
      }
    }
  }

  getAIResults() {
    const params = {
      mobile_type: this.appToDisplay[0].type,
    };

    this.api
      .getBusinessKnowYourData(params, this.appToDisplay[0].id, this._isAndroid)
      .subscribe(
        (res) => {
          this.knowYourData = res;
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Analysed information", err.detail);
        }
      );

    this.api
      .getControlYourData(params, this.appToDisplay[0].id, this._isAndroid)
      .subscribe(
        (res) => {
          this.controlYourData = res;
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Analysed information", err.detail);
        }
      );

    this.api
      .getSecurity(params, this.appToDisplay[0].id, this._isAndroid)
      .subscribe(
        (res) => {
          this.security = res;
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Analysed information", err.detail);
        }
      );

    this.api
      .getAudience(params, this.appToDisplay[0].id, this._isAndroid)
      .subscribe(
        (res) => {
          this.audience = res;
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Analysed information", err.detail);
        }
      );
  }

  resetAIResults() {
    this.knowYourData = null;
    this.controlYourData = null;
    this.security = null;
    this.audience = null;
    this.proseRating = null;
  }
}
