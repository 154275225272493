import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import {
  AppDetails,
  ApplicationRating,
  AppList,
  Audience,
  BusinessAddedApplications,
  BusinessAppDetails,
  BusinessAppList,
  Compare,
  ControlYourData,
  CrossReferencedResults,
  DefaultAppData,
  KnowYourData,
  Message,
  ProSeRating,
  SearchAppData,
  Security,
  Sentences,
} from "../models/app.model";
import { Login } from "../models/auth.model";
import {
  BusinessProfile,
  BusinessUser,
  IndividualProfile,
  PremiumSubscription,
  SandboxDocumentDetails,
} from "../models/user.model";
import { environment } from "./../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) { }

  private authHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    });
  }

  sendMsg(msg) {
    return this.http.post<any>(
      environment.cloudApiDev + "/subscribe/",
      JSON.stringify(msg),
      this.httpOptions
    );
  }

  unSubscribe(key) {
    return this.http.delete<any>(
      environment.cloudApiDev + `/individual/subscribe/${key}`
    );
  }

  resetPassConfirm(resetPassword, individual) {
    return this.http.post<any>(
      (individual
        ? environment.cloudApiDev + "/individual"
        : environment.cloudApiDev + "/business") + "/password/reset/confirm/",
      resetPassword,
      this.httpOptions
    );
  }

  contactGest(input) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    let endpoint: string;

    if (!localStorage.getItem("token")) {
      endpoint = environment.cloudApiDev + "/contact/website/";
    } else {
      endpoint =
        (JSON.parse(localStorage.getItem("individual"))
          ? environment.cloudApiDev + "/individual"
          : environment.cloudApiDev + "/business") + "/contact/";
    }

    return this.http.post<any>(endpoint, input, httpOptions);
  }

  careers(input) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    const endpoint = environment.cloudApiDev + "/contact/job/";

    return this.http.post<any>(endpoint, input, httpOptions);
  }

  getYourData() {

    const httpOptions = {
      headers: this.authHeaders(),
    };

    const endpoint = environment.cloudApiDev + "/internal/pro_se/know/data/";
    return this.http.get<any>(endpoint, httpOptions);

  }

  getControlData() {

    const httpOptions = {
      headers: this.authHeaders(),
    };

    const endpoint = environment.cloudApiDev + "/internal/pro_se/control/data/";
    return this.http.get<any>(endpoint, httpOptions);
  }

  getSecurityData() {

    const httpOptions = {
      headers: this.authHeaders(),
    };

    const endpoint = environment.cloudApiDev + "/internal/pro_se/security/";
    return this.http.get<any>(endpoint, httpOptions);
  }

  getAudienceData() {

    const httpOptions = {
      headers: this.authHeaders(),
    };

    const endpoint = environment.cloudApiDev + "/internal/pro_se/audience/";
    return this.http.get<any>(endpoint, httpOptions);
  }

  getPrivacyPolicy() {

    const httpOptions = {
      headers: this.authHeaders(),
    };

    const endpoint = environment.cloudApiDev + "/internal/pro_se/privacy_policy/";
    return this.http.get<any>(endpoint, httpOptions);
  }

  getTermsAndConditions() {

    const httpOptions = {
      headers: this.authHeaders(),
    };

    const endpoint = environment.cloudApiDev + "/internal/pro_se/terms_of_service/";
    return this.http.get<any>(endpoint, httpOptions);
  }

  getProSeSentence(params) {

    const httpOptions = {
      headers: this.authHeaders(),
      params: params
    };

    const endpoint = environment.cloudApiDev + "/internal/pro_se/sentences/";
    return this.http.get<any>(endpoint, httpOptions);
  }

  contact(input) {

    const httpOptions = {
      headers: this.authHeaders(),
    };

    const endpoint = environment.cloudApiDev + "/contact/website/";

    return this.http.post<any>(endpoint, input, httpOptions);
  }

  verifyReCaptcha(response: string) {
    return this.http.post<any>(
      environment.cloudApiDev + "/recaptcha/",
      JSON.stringify({ recaptcha: response }),
      this.httpOptions
    );
  }

  verifyEmail(token: string, individual: boolean) {
    return this.http.post<any>(
      (individual
        ? environment.cloudApiDev + "/individual"
        : environment.cloudApiDev + "/business") +
      `/users/registration/verify-email/${token}`,
      this.httpOptions
    );
  }

  login(loginCredentials, individual) {
    return this.http
      .post<Login>(
        (individual
          ? environment.cloudApiDev + "/individual"
          : environment.cloudApiDev + "/business") + "/login/",
        loginCredentials,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
      .pipe(
        map(
          (res) => {
            const token = res.access_token;
            token
              ? localStorage.setItem("token", token)
              : localStorage.removeItem("token");
            localStorage.setItem("individual", individual);
            return res;
          },
          (error) => {
            return error;
          }
        )
      );
  }

  register(registerPayload, individual) {
    return this.http.post<any>(
      (individual
        ? environment.cloudApiDev + "/individual"
        : environment.cloudApiDev + "/business") + "/users/registration/",
      JSON.stringify(registerPayload),
      this.httpOptions
    );
  }

  resendConfirmationEmail(email, individual) {
    return this.http.post<any>(
      (individual
        ? environment.cloudApiDev + "/individual"
        : environment.cloudApiDev + "/business") +
      `/users/registration/resend-confirmation-email/${email}`,
      null,
      this.httpOptions
    );
  }

  forgotPassword(email, individual) {
    return this.http.post<any>(
      (individual
        ? environment.cloudApiDev + "/individual"
        : environment.cloudApiDev + "/business") + "/password/reset/",
      JSON.stringify(email),
      this.httpOptions
    );
  }

  getIndividualUser() {
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.get<IndividualProfile>(
      environment.cloudApiDev + "/individual/users/",
      httpOptions
    );
  }

  getBusinessUser() {
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.get<BusinessProfile>(
      environment.cloudApiDev + "/business/users/",
      httpOptions
    );
  }

  updateIndividualUser(userPayload) {
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.put<IndividualProfile>(
      environment.cloudApiDev + "/individual/users/",
      JSON.stringify(userPayload),
      httpOptions
    );
  }

  updateBusinessUser(userPayload) {
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.put<BusinessProfile>(
      environment.cloudApiDev + "/business/users/",
      JSON.stringify(userPayload),
      httpOptions
    );
  }

  deactivateAccount() {
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.delete<any>(
      (JSON.parse(localStorage.getItem("individual"))
        ? environment.cloudApiDev + "/individual"
        : environment.cloudApiDev + "/business") + "/users/",
      httpOptions
    );
  }

  getApplicationList(isAndroid: boolean) {
    const endpoint = isAndroid ? "/android/" : "/ios/";
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.get<Array<AppList>>(
      environment.cloudApiDev +
      "/individual/privacy_policies/mobile" +
      endpoint,
      httpOptions
    );
  }

  getApplicationDetail(isAndroid: boolean, appId: number) {
    const endpoint = (isAndroid ? "/android/" : "/ios/") + `${appId}`;
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.get<AppDetails>(
      environment.cloudApiDev +
      "/individual/privacy_policies/mobile" +
      endpoint,
      httpOptions
    );
  }

  inviteUser(invitationPayload, individual) {
    const endpoint = (individual ? "individual/" : "business/") + "invite/";
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.post<any>(
      environment.cloudApiDev + "/business/invitations/" + endpoint,
      JSON.stringify(invitationPayload),
      httpOptions
    );
  }

  getOrganizationUsers(individual) {
    const endpoint = individual ? "individual/" : "business/";
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.get<Array<BusinessUser>>(
      environment.cloudApiDev + `/business/organizations/users/${endpoint}`,
      httpOptions
    );
  }

  removeUserFromOrganization(removePayload, individual) {
    const endpoint = individual ? "individual" : "business";
    const httpOptions = {
      headers: this.authHeaders(),
    };
    httpOptions["body"] = JSON.stringify(removePayload);
    return this.http.delete<any>(
      environment.cloudApiDev +
      `/business/organizations/users/${endpoint}/delete-user/`,
      httpOptions
    );
  }

  resendInvitation(invitationId, individual) {
    const endpoint =
      (individual ? "individual/" : "business/") +
      `resend-invitation/${invitationId}`;
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.put<any>(
      environment.cloudApiDev + "/business/invitations/" + endpoint,
      null,
      httpOptions
    );
  }

  acceptInvitation(invitationPayload, key, individual) {
    const endpoint = individual
      ? `individual/accept-invitation/${key}`
      : `business/accept-invitation/${key}`;
    return this.http.put<any>(
      environment.cloudApiDev + "/business/invitations/" + endpoint,
      invitationPayload,
      this.httpOptions
    );
  }

  verifyInvitation(key, individual) {
    const endpoint = individual
      ? `individual/verify-invitation/${key}`
      : `business/verify-invitation/${key}`;
    return this.http.get<any>(
      environment.cloudApiDev + "/business/invitations/" + endpoint,
      this.httpOptions
    );
  }

  getOrganizationDocuments(queryParams) {
    const endpoint = `${environment.cloudApiDev}/business/sandbox/files`;
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<any>(endpoint, httpOptions);
  }

  downloadPolicyDocument(document_id) {
    const endpoint = `${environment.cloudApiDev}/business/sandbox/file/${document_id}`;
    return this.http.get(endpoint, {
      headers: this.authHeaders(),
      responseType: "blob",
    });
  }

  uploadFile(payload) {
    const httpOptions = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
    };

    let formData = new FormData();
    formData.append("file", payload.privacy_policy_file);
    formData.append("fileb", payload.privacy_policy_file);
    const endpoint = `${environment.cloudApiDev}/business/sandbox/${payload.privacy_policy_name}/${payload.privacy_policy_version}/file`;

    return this.http.post<any>(endpoint, formData, httpOptions);
  }

  deleteOrganizationDocuments(document_id) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.delete<any>(
      `${environment.cloudApiDev}/business/sandbox/${document_id}`,
      httpOptions
    );
  }

  getStaticPreference(profile: any, type: any) {
    const endpoint = `/${type}/preferences/?profile=${profile}`;
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.get<any>(environment.cloudApiDev + endpoint, httpOptions);
  }

  getStaticCustomPreference(type: any) {
    const endpoint = `/${type}/preferences/?profile=CUSTOM`;
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.get<any>(environment.cloudApiDev + endpoint, httpOptions);
  }

  getPreferencesProfileType(type: any) {
    const endpoint = `/${type}/preferences/profile/type`;
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.get<any>(environment.cloudApiDev + endpoint, httpOptions);
  }

  getBusinessApplicationList(queryParams, isAndroid) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<BusinessAppList>(
      JSON.parse(localStorage.getItem("individual"))
        ? `${environment.cloudApiDev}/individual/privacy_policies/mobile/${isAndroid ? "android" : "ios"
        }/paginated/`
        : `${environment.cloudApiDev}/business/privacy_policies/mobile/`,
      httpOptions
    );
  }

  getBusinessAnalysedApplicationList(queryParams, isAndroid) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<BusinessAppList>(
      JSON.parse(localStorage.getItem("individual"))
        ? `${environment.cloudApiDev}/individual/privacy_policies/mobile/${isAndroid ? "android" : "ios"
        }/analyzed/paginated/`
        : `${environment.cloudApiDev}/business/privacy_policies/mobile/analysed`,
      httpOptions
    );
  }

  getBusinessApplicationDetails(queryParams, appId, isAndroid) {
    const individual = JSON.parse(localStorage.getItem("individual"));
    const httpOptions = individual
      ? {
        headers: this.authHeaders(),
      }
      : {
        headers: this.authHeaders(),
        params: { ...queryParams },
      };

    return this.http.get<BusinessAppDetails>(
      individual
        ? `${environment.cloudApiDev}/individual/privacy_policies/mobile/${isAndroid ? "android" : "ios"
        }/${appId}`
        : `${environment.cloudApiDev}/business/privacy_policies/mobile/${appId}`,
      httpOptions
    );
  }

  getBusinessDefaultApps(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Array<DefaultAppData>>(
      environment.cloudApiDev + "/business/privacy_policies/mobile/default/",
      httpOptions
    );
  }

  getSearchedApps(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Array<SearchAppData>>(
      environment.cloudApiDev + "/business/privacy_policies/mobile/search/",
      httpOptions
    );
  }

  getPreferencesProfile(type: any) {
    const endpoint = `/${type}/preferences/profile`;
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.get<any>(environment.cloudApiDev + endpoint, httpOptions);
  }

  addBusinessApplication(payload) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.post<BusinessAddedApplications>(
      environment.cloudApiDev + "/business/privacy_policies/mobile/",
      payload,
      httpOptions
    );
  }

  updatePreferencesProfile(profile, type) {
    const endpoint = `/${type}/preferences/profile`;
    const httpOptions = {
      headers: this.authHeaders(),
    };
    return this.http.put<any>(
      environment.cloudApiDev + endpoint,
      JSON.stringify(profile),
      httpOptions
    );
  }

  getBusinessKnowYourData(queryParams, appId, isAndroid) {
    const individual = JSON.parse(localStorage.getItem("individual"));
    const httpOptions = individual
      ? {
        headers: this.authHeaders(),
      }
      : {
        headers: this.authHeaders(),
        params: { ...queryParams },
      };

    return this.http.get<KnowYourData>(
      individual
        ? `${environment.cloudApiDev}/individual/results/${isAndroid ? "android" : "ios"
        }/know/data/${appId}`
        : `${environment.cloudApiDev}/business/results/know/data/${appId}`,
      httpOptions
    );
  }

  getApplicationRatings(queryParams, isAndroid) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<ApplicationRating>(
      JSON.parse(localStorage.getItem("individual"))
        ? `${environment.cloudApiDev}/individual/rating/${isAndroid ? "android" : "ios"
        }`
        : `${environment.cloudApiDev}/business/rating/`,
      httpOptions
    );
  }

  rateApplication(payload, isAndroid) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.post<any>(
      `${environment.cloudApiDev}/individual/rating/${isAndroid ? "android" : "ios"
      }`,
      payload,
      httpOptions
    );
  }

  getSentences(queryParams, isAndroid, sandbox) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Array<Sentences>>(
      JSON.parse(localStorage.getItem("individual"))
        ? `${environment.cloudApiDev}/individual/results/${isAndroid ? "android" : "ios"
        }/sentences/`
        : sandbox
          ? `${environment.cloudApiDev}/business/sandbox/sentences`
          : `${environment.cloudApiDev}/business/results/sentences`,
      httpOptions
    );
  }

  getCrossReferencedResults(queryParams, isAndroid) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<CrossReferencedResults>(
      JSON.parse(localStorage.getItem("individual"))
        ? `${environment.cloudApiDev}/individual/results/preferences/${isAndroid ? "android" : "ios"
        }`
        : `${environment.cloudApiDev}/business/results/preferences`,
      httpOptions
    );
  }

  getDeviationSentences(queryParams, isAndroid, sandbox) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Array<Sentences>>(
      JSON.parse(localStorage.getItem("individual"))
        ? `${environment.cloudApiDev
        }/individual/results/preferences/sentences/${isAndroid ? "android" : "ios"
        }`
        : sandbox
          ? `${environment.cloudApiDev}/business/sandbox/preferences/sentences`
          : `${environment.cloudApiDev}/business/results/preferences/sentences`,
      httpOptions
    );
  }

  deleteBusinessApplication(queryParams, appId) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.delete<any>(
      `${environment.cloudApiDev}/business/privacy_policies/mobile/${appId}`,
      httpOptions
    );
  }

  searchArchivedApps(queryParams, isAndroid) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<BusinessAppList>(
      JSON.parse(localStorage.getItem("individual"))
        ? `${environment.cloudApiDev}/individual/privacy_policies/mobile/${isAndroid ? "android" : "ios"
        }/archive/search`
        : `${environment.cloudApiDev}/business/privacy_policies/mobile/archive/search`,
      httpOptions
    );
  }

  searchAnalysedApps(queryParams, isAndroid) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<BusinessAppList>(
      JSON.parse(localStorage.getItem("individual"))
        ? `${environment.cloudApiDev}/individual/privacy_policies/mobile/${isAndroid ? "android" : "ios"
        }/analyzed/search`
        : `${environment.cloudApiDev}/business/privacy_policies/mobile/archive/analysed/search`,
      httpOptions
    );
  }

  getSubscription(isAndroid) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.get<PremiumSubscription>(
      `${environment.cloudApiDev}/individual/premium/subscription/${isAndroid ? "android" : "ios"
      }/`,
      httpOptions
    );
  }

  registerToken(payload) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.post<any>(
      `${environment.cloudApiDev}/business/fcm/devices/`,
      payload,
      httpOptions
    );
  }

  logout(payload) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.post<any>(
      `${environment.cloudApiDev}/business/logout/`,
      payload,
      httpOptions
    );
  }

  getSandboxCrossReferencedResults(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<CrossReferencedResults>(
      `${environment.cloudApiDev}/business/sandbox/preferences`,
      httpOptions
    );
  }

  getSandboxDeviationSentences(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Array<Sentences>>(
      `${environment.cloudApiDev}/business/sandbox/preferences/sentences`,
      httpOptions
    );
  }

  getSandboxKnowYourData(document_id) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.get<KnowYourData>(
      `${environment.cloudApiDev}/business/sandbox/know/data/${document_id}`,
      httpOptions
    );
  }

  getSandboxControlYourData(document_id) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.get<ControlYourData>(
      `${environment.cloudApiDev}/business/sandbox/control/data/${document_id}`,
      httpOptions
    );
  }

  getSandboxSecurity(document_id) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.get<Security>(
      `${environment.cloudApiDev}/business/sandbox/security/${document_id}`,
      httpOptions
    );
  }

  getSandboxAudience(document_id) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.get<Audience>(
      `${environment.cloudApiDev}/business/sandbox/audience/${document_id}`,
      httpOptions
    );
  }

  getSandboxSentences(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Array<Sentences>>(
      `${environment.cloudApiDev}/business/sandbox/sentences`,
      httpOptions
    );
  }

  getSandboxDocumentDetails(documentId) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.get<SandboxDocumentDetails>(
      `${environment.cloudApiDev}/business/sandbox/document/${documentId}`,
      httpOptions
    );
  }

  getSandboxProSeRating(documentId) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.get<ProSeRating>(
      `${environment.cloudApiDev}/business/sandbox/prose/rating/${documentId}`,
      httpOptions
    );
  }

  getProSeRating(isAndroid, documentId, params) {
    const individual = JSON.parse(localStorage.getItem("individual"));
    const httpOptions = individual
      ? {
        headers: this.authHeaders(),
      }
      : {
        headers: this.authHeaders(),
        params,
      };

    return this.http.get<ProSeRating>(
      individual
        ? `${environment.cloudApiDev}/individual/results/${isAndroid ? "android" : "ios"
        }/prose/rating/${documentId}`
        : `${environment.cloudApiDev}/business/results/prose/rating/${documentId}`,
      httpOptions
    );
  }

  getControlYourData(queryParams, appId, isAndroid) {
    const individual = JSON.parse(localStorage.getItem("individual"));
    const httpOptions = individual
      ? {
        headers: this.authHeaders(),
      }
      : {
        headers: this.authHeaders(),
        params: { ...queryParams },
      };

    return this.http.get<ControlYourData>(
      individual
        ? `${environment.cloudApiDev}/individual/results/${isAndroid ? "android" : "ios"
        }/control/data/${appId}`
        : `${environment.cloudApiDev}/business/results/control/data/${appId}`,
      httpOptions
    );
  }

  getSecurity(queryParams, appId, isAndroid) {
    const individual = JSON.parse(localStorage.getItem("individual"));
    const httpOptions = individual
      ? {
        headers: this.authHeaders(),
      }
      : {
        headers: this.authHeaders(),
        params: { ...queryParams },
      };

    return this.http.get<Security>(
      individual
        ? `${environment.cloudApiDev}/individual/results/${isAndroid ? "android" : "ios"
        }/security/${appId}`
        : `${environment.cloudApiDev}/business/results/security/${appId}`,
      httpOptions
    );
  }

  getAudience(queryParams, appId, isAndroid) {
    const individual = JSON.parse(localStorage.getItem("individual"));
    const httpOptions = individual
      ? {
        headers: this.authHeaders(),
      }
      : {
        headers: this.authHeaders(),
        params: { ...queryParams },
      };

    return this.http.get<Audience>(
      individual
        ? `${environment.cloudApiDev}/individual/results/${isAndroid ? "android" : "ios"
        }/audience/${appId}`
        : `${environment.cloudApiDev}/business/results/audience/${appId}`,
      httpOptions
    );
  }

  getCompare(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Compare>(
      `${environment.cloudApiDev}/business/results/compare`,
      httpOptions
    );
  }

  unsubscribe(token: string) {
    return this.http.delete<Message>(
      `${environment.cloudApiDev}/individual/users/unsubscribe/${token}/`
    );
  }
}
