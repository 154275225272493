import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-hexagon-component",
  templateUrl: "./hexagon-component.component.html",
  styleUrls: ["./hexagon-component.component.scss"],
})
export class HexagonComponentComponent {
  @Input() clicked?: boolean;
  @Input() category?: String;
  @Input() color?: String;
  @Output() selectedChange = new EventEmitter<boolean>();

  constructor() {}

  onCellClicked() {
    this.clicked = !this.clicked;
    this.selectedChange.emit(this.clicked);
  }
}
