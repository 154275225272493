import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AnimationOptions } from "ngx-lottie";
import { UserPreferencesResp } from "src/app/models/user.model";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-privacy-preferences",
  templateUrl: "./privacy-preferences.component.html",
  styleUrls: ["./privacy-preferences.component.scss"],
})
export class PrivacyPreferencesComponent implements OnInit {
  guarderAnimationOptions: AnimationOptions = {
    path: "../../../assets/animations/guarded_animation.json",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  laidbackAnimationOptions: AnimationOptions = {
    path: "../../../assets/animations/laid_back_animation.json",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  vigilantAnimationOptions: AnimationOptions = {
    path: "../../../assets/animations/custom_animation.json",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  customAnimationOptions: AnimationOptions = {
    path: "../../../assets/animations/vigilant_animation.json",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  currentOptions: AnimationOptions;
  currentData = {
    profile: "",
    description: "",
    imageSrc: "",
  };

  profileResponse: UserPreferencesResp;
  dataCollected = [];
  collectingPurpose = [];
  retentionPeriod = [];

  selectedForChange = "";
  userType: string;

  constructor(private apiService: ApiService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userType = params["type"];
      if (this.userType) {
        this.apiService
          .getPreferencesProfileType(this.userType)
          .subscribe(() => {
            this.apiService
              .getPreferencesProfile(this.userType)
              .subscribe((resp) => {
                this.profileResponse = resp;
                this.changeDetails(this.profileResponse);
                this.changeCurrent(this.profileResponse.name);
              });
          });
      }
    });
  }

  selectForChange(profileName) {
    if (this.selectedForChange === profileName) {
      this.cancelChange();
    } else {
      this.selectedForChange = profileName;
      this.apiService
        .getStaticPreference(profileName, this.userType)
        .subscribe((resp) => {
          this.dataCollected = resp.data_collected;
          this.collectingPurpose = resp.collecting_purpose;
          this.retentionPeriod = resp.retention_period;
        });
    }
  }

  cancelChange() {
    this.selectedForChange = "";
    this.changeDetails(this.profileResponse);
  }

  saveChanges() {
    const payload = {
      profile_type: this.selectedForChange,
      data_collected_dict: {},
      collecting_purpose_dict: {},
      retention_period_dict: {},
    };

    this.apiService
      .updatePreferencesProfile(payload, this.userType)
      .subscribe((resp) => {
        this.profileResponse = resp;
        this.changeDetails(this.profileResponse);
        this.changeCurrent(this.profileResponse.name);
        this.cancelChange();
      });
  }

  changeDetails(response) {
    this.dataCollected = Object.values(response.data_collected_dict);
    this.collectingPurpose = Object.values(response.collecting_purpose_dict);
    this.retentionPeriod = Object.values(response.retention_period_dict);
  }

  changeCurrent(profileName) {
    switch (profileName) {
      case "EXTREME":
        this.currentData = this.staticData.guarded;
        this.currentOptions = this.guarderAnimationOptions;
        break;
      case "MODERATE":
        this.currentData = this.staticData.vigilant;
        this.currentOptions = this.vigilantAnimationOptions;
        break;
      case "RELAXED":
        this.currentData = this.staticData.laidBack;
        this.currentOptions = this.laidbackAnimationOptions;
        break;
      case "CUSTOM":
        this.currentData = this.staticData.custom;
        this.currentOptions = this.customAnimationOptions;
    }
  }

  whatColors = {
    "Computer information": "#998616",
    "User online activities": "#d99266",
    Financial: "#5f3b19",
    Unspecified: "#d99266",
    "Survey data": "#cca96a",
    Location: "#998616",
    "IP address and device IDs": "#cc9b00",
    "Cookies and tracking elements": "#d99266",
    "Generic personal information": "#5f3b19",
    Demographic: "#d99266",
    Other: "#998616",
    Contact: "#cca96a",
    "Personal identifier": "#cc9b00",
    Health: "#5f3b19",
    "User profile": "#cca96a",
  };

  purposeColors = {
    Advertising: "#998616",
    "Service operation and security": "#cca96a",
    Research: "#cc9b00",
    Marketing: "#d99266",
    "Legal requirement": "#cc9b00",
    "Perform service": "#5f3b19",
    Unspecified: "#998616",
    Other: "#d99266",
  };

  retentionColors = {
    Indefinitely: "#d99266",
    Unspecified: "#998616",
    "Stated Period": "#cc9b00",
    Limited: "#cca96a",
    Other: "#5f3b19",
  };

  staticData = {
    guarded: {
      profile: "Guarded",
      description: "I'm extremely protective of my privacy",
      imageSrc: "../../../../assets/images/guarded_character_svg.svg",
    },
    vigilant: {
      profile: "Vigilant",
      description: "I care about protecting my privacy",
      imageSrc: "../../../../assets/images/vigilant-character-svg.svg",
    },
    laidBack: {
      profile: "Laid-back",
      description: "I'm not to adamant about privacy protection",
      imageSrc: "../../../../assets/images/laid-back-character-svg.svg",
    },
    custom: {
      profile: "Custom",
      description: "I want to create my own profile type",
      imageSrc: "../../../../assets/images/custom-character-svg.svg",
    },
  };
}
