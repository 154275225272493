import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationsService } from "angular2-notifications";
import {
  Audience,
  Compare,
  ControlYourData,
  KnowYourData,
  ProSeRating,
  Security,
} from "src/app/models/app.model";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-comparison-dialog",
  templateUrl: "./comparison-dialog.component.html",
  styleUrls: ["./comparison-dialog.component.scss"],
})
export class ComparisonDialogComponent implements OnInit, AfterContentChecked {
  @Input() appsToCompare = [];
  knowYourData: Array<KnowYourData> = [];
  controlYourData: Array<ControlYourData> = [];
  security: Array<Security> = [];
  audience: Array<Audience> = [];

  proseRating: Array<ProSeRating> = [];

  appRatings = [];

  compare: Compare = null;

  appId: number;

  colorsEven = ["#998616", "#D99266"];
  colorsOdd = ["#5F3B19", "#CCA96A", "#CC9B00"];

  constructor(
    public modal: NgbActiveModal,
    private api: ApiService,
    private _notification: NotificationsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const compareParams = {
      first_app_id: this.appsToCompare[0].id,
      second_app_id: this.appsToCompare[1].id,
      first_mobile_type: this.appsToCompare[0].type,
      second_mobile_type: this.appsToCompare[1].type,
    };

    this.api.getCompare(compareParams).subscribe(
      (res) => {
        this.compare = res;
      },
      (error) => {}
    );
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  getAIResults(index) {
    const params = {
      mobile_type: this.appsToCompare[index].type,
    };

    this.api
      .getBusinessKnowYourData(params, this.appsToCompare[index].id, null)
      .subscribe(
        (res) => {
          this.knowYourData[index] = res;
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Analysed information", err.detail);
        }
      );

    this.api
      .getControlYourData(params, this.appsToCompare[index].id, null)
      .subscribe(
        (res) => {
          this.controlYourData[index] = res;
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          this._notification.error("Analysed information", err.detail);
        }
      );

    this.api.getSecurity(params, this.appsToCompare[index].id, null).subscribe(
      (res) => {
        this.security[index] = res;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Analysed information", err.detail);
      }
    );

    this.api.getAudience(params, this.appsToCompare[index].id, null).subscribe(
      (res) => {
        this.audience[index] = res;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Analysed information", err.detail);
      }
    );

    const ratingParams = {
      id: this.appsToCompare[index].app_id,
      mobile_type: this.appsToCompare[index].type,
    };

    this.api.getApplicationRatings(ratingParams, null).subscribe(
      (res) => {
        this.appRatings[index] = res;
      },
      (error) => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error("Application ratings", err.detail);
      }
    );

    const detailParams = {
      mobile_type: this.appsToCompare[index].type,
    };

    this.api
      .getBusinessApplicationDetails(
        detailParams,
        this.appsToCompare[index].id,
        null
      )
      .subscribe(
        (res) => {
          const ratingParams = {
            mobile_type: this.appsToCompare[index].type,
          };
          this.api
            .getProSeRating(null, res.privacy_policies__[0].id, ratingParams)
            .subscribe(
              (res) => {
                this.proseRating[index] = res;
              },
              (error) => {
                let err = JSON.parse(JSON.stringify(error.error));
                this._notification.error("Pro Se rating", err.detail);
              }
            );
        },
        (error) => {}
      );
  }

  getColor(chip, index): string {
    if (chip && chip.style && chip.style.background) {
      return chip.style.background;
    } else {
      if (index % 2 == 0) {
        return this.colorsEven[(Math.random() * this.colorsEven.length) | 0];
      } else {
        return this.colorsOdd[(Math.random() * this.colorsOdd.length) | 0];
      }
    }
  }

  cancel(): void {
    this.modal.close();
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  isAppFetched() {
    return this.compare != null;

    // return this.knowYourData.length == 2 && this.controlYourData.length == 2 && this.security.length == 2 && this.audience.length == 2 &&
    //   this.knowYourData[0] && this.knowYourData[1] && this.controlYourData[0] && this.controlYourData[1] &&
    //   this.security[0] && this.security[1] && this.proseRating[0] && this.proseRating[1]
    // return this.compare != null && !this.isEmptyObject(this.compare) &&
    // !this.isEmptyObject(this.compare.first_app_know_data) &&
    // !this.isEmptyObject(this.compare.second_app_know_data) &&
    // !this.isEmptyObject(this.compare.first_app_control_data) &&
    // !this.isEmptyObject(this.compare.second_app_control_data) &&
    // !this.isEmptyObject(this.compare.first_app_security_data) &&
    // !this.isEmptyObject(this.compare.second_app_security_data) &&
    // !this.isEmptyObject(this.compare.first_app_audience_data) &&
    // !this.isEmptyObject(this.compare.second_app_audience_data) &&
    // !this.isEmptyObject(this.compare.first_app_user_rating) &&
    // !this.isEmptyObject(this.compare.second_app_user_rating) &&
    // !this.isEmptyObject(this.compare.first_app_prose_rating) &&
    // !this.isEmptyObject(this.compare.second_app_prose_rating)
  }
}
