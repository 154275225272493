<div class="wrapper">
  <div class="title">
    <p>Sign in to your account</p>
  </div>
  <form [formGroup]="signInFg" id="signInForm" class="login-form">
    <div class="row field-wrapper">
      <div class="col">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Email address</mat-label>
            <input formControlName="username" autocomplete="off" matInput placeholder="example@mail.com" />
            <mat-error *ngIf="
                signInFg.get('username').hasError('required') &&
                signInFg.get('username').touched
              " id="emailHelp" class="danger">
              Email is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input formControlName="password" matInput [type]="hide_pass ? 'password' : 'text'" />
            <button type="button" class="eye-btn" mat-icon-button matSuffix (click)="hide_pass = !hide_pass"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_pass">
              <mat-icon>{{
                hide_pass ? "visibility_off" : "visibility"
                }}</mat-icon>
            </button>
            <mat-error *ngIf="
                signInFg.get('password').hasError('required') &&
                signInFg.get('password').touched
              " id="passwordHelp1" class="danger">
              Please enter your password
            </mat-error>
            <mat-error *ngIf="
                signInFg.get('password').hasError('pattern') &&
                !signInFg.get('password').hasError('required') &&
                signInFg.get('password').touched
              " id="passwordHelp2" class="danger">
              8 characters long. At least 1 digit, upper and lowercase letter!
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row forgot-password-wrapper">
      <div class="col">
        <a class="forgot-password" [routerLink]="getForgotPasswordRoute()">Forgot password?</a>
      </div>
    </div>
    <div class="row submit-wrapper">
      <div class="col">
        <button ngbAutofocus (click)="signIn(signInFg)" class="mt-2 submit-btn" form="signInForm" mat-raised-button
          color="primary">
          <span>Sign In</span>
        </button>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col footer">
      <span>Don't have an account? <a routerLink="/register">Register</a></span>
    </div>
  </div>
</div>