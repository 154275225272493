import { Injectable } from '@angular/core';

@Injectable()
export class RegistrationSharedData {

    private registrationData: any = null;

    setRegistrationData(data: any) {
        this.registrationData = data;
    }

    getRegistrationData() {

        return this.registrationData
    }
}