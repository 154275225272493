<div class="row wrapper bg-white">
    <div class="col-12 header">
        <app-onboard-header></app-onboard-header>
    </div>
    <div class="col-6 empty-state bg-white">
        <img src="../../../assets/images/resend_mail_image-min.png" alt="resend mail">
    </div>
    <div class="col-lg-6 col-12">
        <div class="container">
            <div class="content-wrapper">

                <a (click)=backAction()>
                    <svg width="16" height="16" class="arrow-icon" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#5F3B19" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </a>

                <h2 class="title">Check your email</h2>

                <p class="message">We've sent an email to <b>{{ email }}</b> with
                    a link to verify your account.</p>

                <p class="message"><b>Didn't get an email?</b></p>

                <p class="message">If you have not received the verification email from us,
                    you can click the resend button bellow to have another email sent to you.
                </p>

                <div class="row resend-wrapper">
                    <div class="col">
                        <a (click)="resendEmail()" class="resend-email">Resend verification email</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>