import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DeleteAppService {

    private applicationDeletedSource = new Subject<number>()

    applicationDeleted$ = this.applicationDeletedSource.asObservable()

    removeApplication(deletedAppId: number) {
        this.applicationDeletedSource.next(deletedAppId)
    }
}