<div class="row wrapper bg-white">
  <div class="col-12 header">
    <app-onboard-header></app-onboard-header>
  </div>

  <div class="col-6 empty-state bg-white">
    <img src="../../../assets/images/verify_mail_image-min.png" alt="verify mail" />
  </div>

  <div class="col-lg-6 col-12">
    <div class="container">
      <div>
        <p class="title">Email address verified!</p>

        <p class="message">
          You have verified your email address. You can now sign in to your
          account.
        </p>

        <div class="row submit-wrapper">
          <div class="col">
            <button ngbAutofocus (click)="goToSignIn()" class="mt-2 submit-btn" mat-raised-button color="primary">
              Go to sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>