<div (click)="closeModal()" [class]="modal.isVisible ? 'block' : 'hidden' "
    class="h-full w-full fixed top-0 left-0 z-50 wrapper">
    <div class="px-5 h-full flex items-center justify-center">
        <div class="modal mx-auto bg-white rounded-md">
            <div class="flex items-center justify-between">
                <h1 class="text-2xl font-bold">{{modal.title}}</h1>
                <span (click)="closeModal()" class="text-gray text-lg cursor-pointer" [innerHTML]="'&#10006;'"></span>
            </div>
            <div class="main">
                <p class="text-gray">
                    {{modal.text}}
                </p>
            </div>
            <div style="text-align: right;">
                <button (click)="closeModal()"
                    class="bg-bronze border border-bronze text-white px-5 py-2 rounded-md hover:bg-white hover:text-bronze">OK</button>
            </div>
        </div>
    </div>
</div>