import { Component, ElementRef, Input, ViewChild } from "@angular/core";

@Component({
  selector: "app-prose-rating",
  templateUrl: "./prose-rating.component.html",
  styleUrls: ["./prose-rating.component.scss"],
})
export class ProseRatingComponent {
  @ViewChild("chartCanvas", { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  private ctx: CanvasRenderingContext2D;

  @Input() isDialogParent = false;
  _rating = 0;

  @Input() set rating(value: number) {
    this._rating = value;
    if (this.ctx) {
      this.ctx.restore();
      this.drawCircle(this._rating);
    } else {
      this.ctx = this.canvas.nativeElement.getContext("2d");
      this.drawCircle(this._rating);
    }
  }

  drawCircle(percent): void {
    this.ctx.canvas.width = 800;
    this.ctx.canvas.height = 600;

    let width = this.ctx.canvas.width;
    let height = this.ctx.canvas.height;

    let radius = width > height ? height * 0.45 : width * 0.45;
    let centerX = width / 2;
    let centerY = height / 2;

    this.ctx.strokeStyle = "#F1EADF";
    this.ctx.lineWidth = 45;
    this.ctx.lineCap = "round";
    let startAngle = (145 * Math.PI) / 180;
    let endAngle = startAngle + (250 * Math.PI) / 180;
    let innerAngle = startAngle + (percent * 250 * Math.PI) / 180;
    this.ctx.beginPath();
    this.ctx.arc(centerX, centerY, radius, startAngle, endAngle, false);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.strokeStyle = "#cca96a";
    this.ctx.arc(centerX, centerY, radius, startAngle, innerAngle, false);
    this.ctx.stroke();
  }
}
