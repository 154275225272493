import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { BusinessUser } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-individual-users',
  templateUrl: './individual-users.component.html',
  styleUrls: ['./individual-users.component.scss']
})
export class IndividualUsersComponent implements OnInit {

  businessUsers: Array<BusinessUser>;
  displayedColumns: string[] = ['email', 'status', 'action']

  constructor(private api: ApiService, private _notification: NotificationsService) { }

  ngOnInit() {
    this.fetchUsers();
  }

  fetchUsers() {
    this.api.getOrganizationUsers(true).subscribe(
      res => {
        this.businessUsers = res;
      },
      error => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error('Retrieve users error', err.detail);
      }
    );
  }

  removeUser(email) {
    const removePayload = {
      "email": email
    }

    this.api.removeUserFromOrganization(removePayload, true).subscribe(
      res => {
        this._notification.success('Success', 'User has been deleted');
        this.businessUsers = this.businessUsers.filter(user => user.email != email);
      },
      error => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error('Delete user error', err.detail);
      }
    )

  }

  resendInvitation(invitationId) {
    this.api.resendInvitation(invitationId, true).subscribe(
      res => {
        this._notification.success('Success', 'Invitation has been resent')
      },
      error => {
        let err = JSON.parse(JSON.stringify(error.error));
        this._notification.error('Resend invitation error', err.detail);
      }
    )
  }

  statusClass(status) {
    if (status === 'Active') {
      return 'active';
    } else if (status === 'Invitation sent') {
      return 'pending';
    } else {
      return 'expired';
    }
  }

}
