<div [ngClass]="isDialogParent ? 'prose-chart-dialog' : 'prose-chart'">
  <div class="labels">
    <div class="prose-label">
      <div class="percentage">{{ _rating*100 | number: '1.0-0'}}%</div>
      <div>Pro Se rating</div>
    </div>
  </div>
  <div class="scales">
    <div class="first-row">50</div>
    <div class="second-row">
      <span>25</span>
      <span>75</span>
    </div>
    <div class="third-row">
      <span>0</span>
      <span>100</span>
    </div>
  </div>
  <canvas #chartCanvas>
  </canvas>
</div>