import { Component, OnInit, ViewChild } from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { MatSelectionList } from "@angular/material/list";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CrossReferencedResults, ProSeRating } from "src/app/models/app.model";
import { WebApp, WebAppDetails } from "src/app/models/web-app.model";
import { WebApiService } from "src/app/services/web-api.service";
import { AnalysingPolicyComponent } from "../dashboard/analysing-policy/analysing-policy.component";
import { SharedPrivacyPolicy } from "src/app/services/shared-data/privacy-policy";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
  selector: "app-web-apps",
  templateUrl: "./web-apps.component.html",
  styleUrls: ["./web-apps.component.scss"],
})
export class WebAppsComponent implements OnInit {
  constructor(
    private api: WebApiService,
    private route: ActivatedRoute,
    private router: Router,
    private _modal: NgbModal,
    private sharedPrivacyPolicy: SharedPrivacyPolicy
  ) {
    this.getApplications();
    this.getAnalysedApplications();
  }

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  ngAfterViewInit() {
    this.tabGroup.selectedIndexChange.subscribe((selectedIndex: number) => {
      
      if(selectedIndex === 0){
        this.sharedPrivacyPolicy.setPrivacyPolicyType('privacy polices');
      }
      else{
        this.sharedPrivacyPolicy.setPrivacyPolicyType('analyzed polices');
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.getAppDetails(params["id"]);
      }
    });
    this.route.queryParams.subscribe((qp) => {
      if (qp["notification"]) {
        const notify = qp["notification"];
        if (notify === "true") {
          const modalRef = this._modal.open(AnalysingPolicyComponent, {
            size: "md",
            centered: true,
            backdrop: "static",
            keyboard: false,
          });
        }
      } else if (qp["analysed"]) {
        const analysed = qp["analysed"];
        if (analysed === "true") {
          this.selectedTabIndex = 1;
          this.sharedPrivacyPolicy.setPrivacyPolicyType('analyzed polices');
        }
      }
    });
  }

  selectedTabIndex = 0;

  spinnerAction = false;
  applications: WebApp[];
  archiveSearchTerm = "";
  emptySearchResults = false;
  lastSelectedAppId = -1;
  appDetails?: WebAppDetails;
  selectedApp: WebApp[];
  page = 1;
  pageSize = 5;
  count: number;

  analysedApplications: WebApp[];
  analysedPage = 1;
  analysedPageSize = 5;
  analysedCount: number;
  analysedSearchTerm = "";
  emptyAnalysedSearchResults = false;

  analysedAppsToDisplay: any;
  lastSelectedAnalysedAppId = -1;

  accordance = true;
  crossReferenceResults: CrossReferencedResults;
  proseRating: ProSeRating = null;

  @ViewChild("appSelectionList")
  private selectionList?: MatSelectionList;

  @ViewChild("analyticsAccordion")
  private accordion?: MatAccordion;

  @ViewChild("controlAccordion")
  private controlAccordion?: MatAccordion;

  @ViewChild("securityAccordion")
  private securityAccordion?: MatAccordion;

  @ViewChild("securityAccordion")
  private audienceAccordion?: MatAccordion;

  getApplications() {
    const params = {
      page: this.page,
      size: this.pageSize,
    };

    this.api.getPaginated(params).subscribe(
      (res) => {
        this.applications = res.items;
        this.count = res.total;
      },
      (error) => console.log(error)
    );
  }

  getAnalysedApplications() {
    const params = {
      page: this.analysedPage,
      size: this.analysedPageSize,
    };

    this.api.getPaginatedAnalysed(params).subscribe(
      (res) => {
        this.analysedApplications = res.items;
        this.analysedCount = res.total;
      },
      (error) => console.log(error)
    );
  }

  deleteApp(appId) {
    this.api.delete(appId).subscribe((res) => {
      this.getApplications();
      this.getAnalysedApplications();
    });
  }

  appCount() {
    return this.applications.length < this.pageSize
      ? this.applications.length
      : this.pageSize;
  }

  analysedPageCount() {
    return this.analysedApplications &&
      this.analysedApplications.length < this.analysedPageSize
      ? this.analysedApplications.length
      : this.analysedPageSize;
  }

  handlePageChange(event) {
    this.page = event;

    if (this.archiveSearchTerm != "") {
      this.onArchiveSearch(true);
    } else {
      this.getApplications();
    }
  }

  handleAnalysedPageChange(event) {
    this.analysedPage = event;

    if (this.archiveSearchTerm != "") {
      this.onArchiveSearch(true);
    } else {
      this.getAnalysedApplications();
    }
  }

  onAppSelectionChange(): void {
    const app = this.selectedApp[0];
    this.router.navigate([
      this.isIndividual() ? "apps/web" : "dashboard/web",
      app.id,
    ]);
  }

  onArchiveSearch(isNextPage) {
    if (this.archiveSearchTerm == "") {
      this.page = 1;
      this.getApplications();
    } else {
      if (!isNextPage) {
        this.page = 1;
      }

      const params = {
        term: this.archiveSearchTerm,
        page: this.page,
        size: this.pageSize,
      };

      this.api.searchPaginated(params).subscribe(
        (res) => {
          if (res.total == 0) {
            this.emptySearchResults = true;
            setTimeout(() => {
              this.emptySearchResults = false;
            }, 5000);
          } else {
            this.applications = res.items;
            this.count = res.total;
          }
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error.error));
          // this._notification.error("Searched application", err.detail);
        }
      );
    }
  }

  getAppDetails(id) {
    this.api.getDetails(id).subscribe((res) => {
      this.sharedPrivacyPolicy.selectPrivacyPolicy({
        id: res.id,
        type:'privacy polices',
        appName: res.title,
        appLogo: res.image_link,
        link: res.privacy_policies__[0].privacy_policy_link,
        text: res.privacy_policies__[0].privacy_policy_text,
      });
      this.appDetails = res;
    });
  }

  isAppSelected(id) {
    return this.appDetails && this.appDetails.id === id;
  }

  isAnalysedAppSelected(id) {
    return this.lastSelectedAppId === id;
  }

  onAnalysedSearch(isNextPage) {
    if (this.analysedSearchTerm == "") {
      this.page = 1;
      this.getAnalysedApplications();
    } else {
      if (!isNextPage) {
        this.analysedPage = 1;
      }

      const params = {
        term: this.analysedSearchTerm,
        page: this.analysedPage,
        size: this.analysedPageSize,
      };

      this.api.searchAnalysedApps(params).subscribe((res) => {
        if (res.total == 0) {
          this.emptyAnalysedSearchResults = true;
          setTimeout(() => {
            this.emptyAnalysedSearchResults = false;
          }, 5000);
        } else {
          this.analysedApplications = res.items;
          this.analysedCount = res.total;
        }
      });
    }
  }

  private isIndividual(): boolean {
    return JSON.parse(localStorage.getItem("individual"));
  }
}
