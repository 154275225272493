<div class="header">
  <div class="page-number">
    <span>3</span>
  </div>
  <div class="what-label">
    For how long are you willing to let third-party apps retain your data?
  </div>
</div>
<div class="row d-flex justify-content-center">
  <div class="col-3">
    <app-hexagon-component
      class="row"
      [category]="'Indefinitely'"
      [color]="'#D99266'"
      [clicked]="selected['Indefinitely']"
      (selectedChange)="selectedChanged('Indefinitely', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row"
      [category]="'Unspecified'"
      [color]="'#998616'"
      [clicked]="selected['Unspecified']"
      (selectedChange)="selectedChanged('Unspecified', $event)"
    ></app-hexagon-component>
  </div>
  <div class="col-3">
    <app-hexagon-component
      class="row second-row"
      [category]="'Stated period'"
      [color]="'#cc9b00'"
      [clicked]="selected['Stated Period']"
      (selectedChange)="selectedChanged('Stated Period', $event)"
    ></app-hexagon-component>
  </div>
  <div class="col-3">
    <app-hexagon-component
      class="row"
      [category]="'Limited'"
      [color]="'#cca96a'"
      [clicked]="selected['Limited']"
      (selectedChange)="selectedChanged('Limited', $event)"
    ></app-hexagon-component>
    <app-hexagon-component
      class="row"
      [category]="'Other'"
      [color]="'#5f3b19'"
      [clicked]="selected['Other']"
      (selectedChange)="selectedChanged('Other', $event)"
    ></app-hexagon-component>
  </div>
</div>
