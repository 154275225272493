import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-go-through',
  templateUrl: './go-through.component.html',
  styleUrls: ['../../../../assets/scss/tw.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class GoThroughComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  @Input() data: any;

  displayPrivacyPolicyView() {

    this.data.goThroughView = false
    this.data.privacyPolicyView = true
  }

}
