<div class="wrapper">
<div class="first-column">
    <div class="bold-title">Welcome to Sandbox!</div>
    <div class="description">Switch to Paid plan in order to gain access to Sandbox. It comes with many benefits</div>   
    <img src="../../../../../assets/images/paid-sandbox-character.svg" alt="paid sandbox character" class="character-image">
        
</div>
<div class="second-column">
    <div class="header">
        <div class="first-row">
            <div class="title-text">Paid plan</div>
            <div class="title-price">30€</div>
        </div>
        <div class="second-row">
            <div class="rect"></div>
            <div class="text">yearly</div>
        </div>
    </div>
    <div class="content">
        <div class="subtitle">Plan includes:</div>
        <div class="list-row">
            <svg width="1.1vw" height="0.76vw" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="row-icon">
                <path d="M17 1L6 12L1 7" stroke="#5F3B19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="row-text">
                Access to web application
            </div>
        </div>
        <div class="list-row">
            <svg width="1.1vw" height="0.76vw" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="row-icon">
                <path d="M17 1L6 12L1 7" stroke="#5F3B19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="row-text">
                Search and view individual policies and permissions for mobile apps added in the archive
            </div>
        </div>
        <div class="list-row">
            <svg width="1.1vw" height="0.76vw" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="row-icon">
                <path d="M17 1L6 12L1 7" stroke="#5F3B19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="row-text">
                Generate detailed reports for specific privacy policies and request comparison between policies
            </div>
        </div>
        <div class="list-row">
            <svg width="1.1vw" height="0.76vw" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="row-icon">
                <path d="M17 1L6 12L1 7" stroke="#5F3B19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="row-text">
                Unlimited number of privacy policies with AI analyzed results
            </div>
        </div>
        <div class="list-row">
            <svg width="1.1vw" height="0.76vw" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="row-icon">
                <path d="M17 1L6 12L1 7" stroke="#5F3B19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="row-text">
                Access to Pro Se Sandbox
            </div>
        </div>
        <div class="subtitle">Sandbox includes:</div>
        <div class="list-row">
            <svg width="1.1vw" height="0.76vw" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="row-icon">
                <path d="M17 1L6 12L1 7" stroke="#5F3B19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="row-text">
                Upload privacy policy for your own application or service which will be automatically analyzed
            </div>
        </div>
        <div class="list-row">
            <svg viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="row-icon">
                <path d="M17 1L6 12L1 7" stroke="#5F3B19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="row-text">
                Metric called 'Acceptance rate'
            </div>
        </div>
        <div class="list-row">
            <svg width="1.1vw" height="0.76vw" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="row-icon">
                <path d="M17 1L6 12L1 7" stroke="#5F3B19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="row-text">
                Detailed AI report, which is charged 30EUR one time fee
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="get-btn">Get paid plan</button>
    </div>
</div>
</div>