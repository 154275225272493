<div [ngClass]="isDialogParent ? 'chart-dialog' : 'chart'">
  <div class="chart-row rating-label">
    <span>Overall user rating ({{ userRating | number: '1.1-1' }})</span>
  </div>
    <div *ngIf="!isBusinessUser" class="chart-row ratings">
      <span class="tap-label">Tap to rate:</span>
      <ng-template #t let-fill="fill">
        <span class="star" [class.full]="fill === 100">
          <span class="half" [style.width.%]="fill"><mat-icon>star</mat-icon></span>
          <mat-icon>star</mat-icon>
        </span>
      </ng-template>
      
      <ngb-rating (rateChange)="onRateChange($event)" [rate]="userRate" [starTemplate]="t" [readonly]="false" [max]="5"></ngb-rating>
    </div>
    <div class="chart-row">
      <div class="left-label">5 stars</div>
      <div class="chart-bar">
        <div class="bar-shadow"></div>
        <div class="top-bar" [ngStyle]="{'width' : fiveStars + '%'}"></div>
      </div>
      <div class="right-label">
        {{ fiveStars | number: '1.0-0' }}%
      </div>
    </div>
    <div class="chart-row">
      <div class="left-label">4 stars</div>
      <div class="chart-bar">
        <div class="bar-shadow"></div>
        <div class="top-bar" [ngStyle]="{'width': fourStars + '%'}"></div>
      </div>
      <div class="right-label">
        {{ fourStars | number: '1.0-0' }}%
      </div>
    </div>
    <div class="chart-row">
      <div class="left-label">3 stars</div>
      <div class="chart-bar">
        <div class="bar-shadow"></div>
        <div class="top-bar" [ngStyle]="{'width': threeStars + '%'}"></div>
      </div>
      <div class="right-label">
        {{ threeStars | number: '1.0-0' }}%
      </div>
    </div>
    <div class="chart-row">
      <div class="left-label">2 stars</div>
      <div class="chart-bar">
        <div class="bar-shadow"></div>
        <div class="top-bar" [ngStyle]="{'width': twoStars + '%'}"></div>
      </div>
      <div class="right-label">
        {{ twoStars | number: '1.0-0' }}%
      </div>
    </div>
    <div class="chart-row">
      <div class="left-label">1 star</div>
      <div class="chart-bar">
        <div class="bar-shadow"></div>
        <div class="top-bar" [ngStyle]="{'width': oneStar + '%'}"></div>
      </div>
      <div class="right-label">
        {{ oneStar | number: '1.0-0' }}%
      </div>
    </div>
  </div>
