import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ApplicationRating,
  Audience,
  BusinessAppDetails,
  ControlYourData,
  CrossReferencedResults,
  KnowYourData,
  ProSeRating,
  Security,
  Sentences,
} from "../models/app.model";
import { WebAppDetails, WebAppList } from "../models/web-app.model";
import { environment } from "./../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class WebApiService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}

  private authHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    });
  }

  private isIndividual(): boolean {
    return JSON.parse(localStorage.getItem("individual"));
  }

  getDetails(id) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.get<WebAppDetails>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/privacy_policies/web/${id}`,
      httpOptions
    );
  }

  getPaginated(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<WebAppList>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/privacy_policies/web/${this.isIndividual() ? "paginated/" : ""}`,
      httpOptions
    );
  }

  searchPaginated(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<WebAppList>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/privacy_policies/web/archive/search/`,
      httpOptions
    );
  }

  getPaginatedAnalysed(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<WebAppList>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/privacy_policies/web/${
        this.isIndividual() ? "analyzed/paginated/" : "analysed/"
      }`,
      httpOptions
    );
  }

  searchAnalysedApps(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<WebAppList>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/privacy_policies/web/${
        this.isIndividual() ? "analyzed/search/" : "archive/analysed/search/"
      }`,
      httpOptions
    );
  }

  getApplicationRatings(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<ApplicationRating>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/rating/web`,
      httpOptions
    );
  }

  delete(appId) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.delete<BusinessAppDetails>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/privacy_policies/web/${appId}`,
      httpOptions
    );
  }

  getBusinessApplicationDetails(queryParams, appId) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<BusinessAppDetails>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/privacy_policies/web/${appId}`,
      httpOptions
    );
  }

  getCrossReferencedResults(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<CrossReferencedResults>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/results/${
        this.isIndividual() ? "preferences/web/" : "web/preferences/"
      }`,
      httpOptions
    );
  }

  getProSeRating(documentId, params) {
    const httpOptions = {
      headers: this.authHeaders(),
      params,
    };

    return this.http.get<ProSeRating>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/results/web/prose/rating/${documentId}`,
      httpOptions
    );
  }

  getSentences(queryParams) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Array<Sentences>>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/results/web/sentences/`,
      httpOptions
    );
  }

  getBusinessKnowYourData(queryParams, appId) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<KnowYourData>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/results/web/know/data/${appId}`,
      httpOptions
    );
  }

  getControlYourData(queryParams, appId) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<ControlYourData>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/results/web/control/data/${appId}`,
      httpOptions
    );
  }

  getSecurity(queryParams, appId) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Security>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/results/web/security/${appId}`,
      httpOptions
    );
  }

  getAudience(queryParams, appId) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Audience>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/results/web/audience/${appId}`,
      httpOptions
    );
  }

  getDeviationSentences(queryParams, isAndroid, sandbox) {
    const httpOptions = {
      headers: this.authHeaders(),
      params: { ...queryParams },
    };

    return this.http.get<Array<Sentences>>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/results/${
        this.isIndividual()
          ? "preferences/sentences/web"
          : "web/preferences/sentences"
      }`,
      httpOptions
    );
  }

  rateApplication(payload) {
    const httpOptions = {
      headers: this.authHeaders(),
    };

    return this.http.post<any>(
      `${environment.cloudApiDev}/${
        this.isIndividual() ? "individual" : "business"
      }/rating/web`,
      payload,
      httpOptions
    );
  }
}
