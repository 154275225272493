import { Component, ViewChild } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { FetchDataService } from "src/app/services/fetch-data.service";
import { SharedPrivacyPolicy } from "src/app/services/shared-data/privacy-policy";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  constructor(private fetchDataService: FetchDataService,private sharedPrivacyPolicy: SharedPrivacyPolicy) {}

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  onTabChange($event) {
    if ($event.index == 1) {
      this.fetchDataService.fetchAnalysedPolicies();
    } else {
      this.fetchDataService.fetchArchivedPolicies();
    }
  }

  ngAfterViewInit() {
    this.tabGroup.selectedIndexChange.subscribe((selectedIndex: number) => {
      
      if(selectedIndex === 0){

        this.sharedPrivacyPolicy.setPrivacyPolicyType('privacy polices');
      }
      else{
        this.sharedPrivacyPolicy.setPrivacyPolicyType('analyzed polices');
      }
    });
  }
}
