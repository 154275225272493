<div class="wrapper">
    <app-onboard-header></app-onboard-header>
    <div class="content-wrapper" *ngIf="staticProfile">
        <a class="back-link" [routerLink]="'/' + userType + '/select-preferences'">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#5F3B19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </a>
        <div class="title">
            {{profileName}}
        </div>
        <div>
            <span class="section-description">
                <span style="color: #ffbf13">1.</span> Data I'm willing to share with third-party apps
            </span>
            <mat-chip-list>
                <mat-chip *ngFor="let what of staticProfile.data_collected" [style.background]="whatColors[what]">{{what}}</mat-chip>
            </mat-chip-list>
        </div>
        <div style="margin-top: 40px; margin-bottom: 40px">
            <span class="section-description">
                <span style="color: #ffbf13">2.</span> Purposes for which I'm willing to share my data with thrid-party apps
            </span>
            <mat-chip-list>
                <mat-chip *ngFor="let purpose of staticProfile.collecting_purpose" [style.background]="purposeColors[purpose]">{{purpose}}</mat-chip>
            </mat-chip-list>
        </div>
        <div>
            <span class="section-description">
                <span style="color: #ffbf13">3.</span> The amount of time I'm willing to let third-party apps retain my data
            </span>
            <mat-chip-list>
                <mat-chip *ngFor="let retention of staticProfile.retention_period" [style.background]="retentionColors[retention]">{{retention}}</mat-chip>
            </mat-chip-list>
        </div>
        <button class="start-btn" (click)="onSubmit()">Get started</button>
    </div>
</div>
