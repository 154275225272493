<div class="row position-relative min-vh-100 bg-white">

    <div class="col-12 position-absolute header">
        <app-onboard-header></app-onboard-header>
    </div>

    <div class="row position-relative h-100 bg-white">

        <div *ngIf="data.goThroughView">
            <app-go-through [data]="data"></app-go-through>
        </div>

        <div *ngIf="data.privacyPolicyView">
            <app-privacy-policy-page [data]="data"></app-privacy-policy-page>
        </div>

        <div *ngIf="data.termsAndConditionsView">
            <app-terms-and-conditions [data]="data"></app-terms-and-conditions>
        </div>

        <div *ngIf="data.summarisationView">
            <app-summarisation [data]="data"></app-summarisation>
        </div>

    </div>
</div>