<div class="web-wrapper">
  <div class="section-title">Web Archive</div>
  <mat-tab-group
    #tabGroup
    dynamicHeight
    class="bg-white dashboard-tabs"
    mat-align-tabs="start"
    [(selectedIndex)]="selectedTabIndex"
  >
    <mat-tab label="My privacy policies">
      <div class="scroll">
        <div class="row policy-archive-wrapper">
          <mat-spinner
            *ngIf="spinnerAction"
            class="spinner"
            [color]="'#cca96a'"
          ></mat-spinner>
          <div
            *ngIf="!applications || applications.length === 0"
            class="col-12 empty-list-wrapper"
          >
            <div class="empty-list">
              <img
                class="empty-icon"
                src="../../../../../assets/images/empty-list.svg"
                alt="empty icon"
              />
              <span class="empty-title">No Privacy Policies</span>
              <span class="empty-description">
                <span>
                  You haven't added any privacy policies yet. Install Browser
                  Extension to start building your archive.</span
                >
                <!-- <div class="actions">
                  <a
                    class="btn"
                    href="https://apps.apple.com/us/app/pro-se-digital-legal-assistant/id1570105728"
                    target="_blank"
                    style="
                      background-image: url('../../../assets/images/app-store-badge.png');
                      background-position: center center;
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                    "
                  >
                  </a>
                </div> -->
              </span>
            </div>
          </div>
          <div
            class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 content-wrapper-left"
          >
            <div
              *ngIf="applications && applications.length > 0"
              class="app-form"
            >
              <div
                *ngIf="applications && applications.length > 0"
                class="archive-display"
              >
                <p class="title">My Privacy policies</p>
                <div class="app-selection-list">
                  <div class="app-list-wrapper">
                    <div class="archive-search-wrapper">
                      <mat-form-field class="search-field" appearance="outline">
                        <input
                          matInput
                          (keyup.enter)="onArchiveSearch(false)"
                          [(ngModel)]="archiveSearchTerm"
                        />
                        <button
                          matSuffix
                          mat-icon-button
                          (click)="onArchiveSearch(false)"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            preserveAspectRatio="xMaxYMax meet"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                              stroke="#CCA96A"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19 19L14.65 14.65"
                              stroke="#CCA96A"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </mat-form-field>
                    </div>
                    <span
                      *ngIf="emptySearchResults"
                      [@fadeInOut]
                      class="search-results"
                    >
                      No matches have been found for a searched term.
                    </span>
                    <mat-selection-list
                      #appSelectionList="matSelectionList"
                      (selectionChange)="onAppSelectionChange()"
                      class="app-list"
                      [multiple]="false"
                      [(ngModel)]="selectedApp"
                    >
                      <mat-list-option
                        [selected]="isAppSelected(app.id)"
                        *ngFor="
                          let app of applications
                            | paginate
                              : {
                                  id: 'appPagination',
                                  itemsPerPage: pageSize,
                                  currentPage: page,
                                  totalItems: count
                                }
                        "
                        checkbox="false"
                        [value]="app"
                      >
                        <div class="option-wrapper">
                          <div class="option-name">
                            <img class="app-icon" [src]="app.image_link" alt="app icon" />
                            <div class="app-label">
                              {{ app.title }}
                            </div>
                          </div>
                          <span class="button-wrapper" style="max-width: 40px">
                            <button
                              (click)="$event.stopPropagation()"
                              (mousedown)="$event.stopPropagation()"
                              class="menu-button"
                              mat-icon-button
                              [matMenuTriggerFor]="menu"
                            >
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item (click)="deleteApp(app.id)">
                                Remove
                              </button>
                            </mat-menu>
                          </span>
                        </div>
                      </mat-list-option>
                    </mat-selection-list>
                  </div>
                  <div class="footer">
                    <p>
                      Showing {{ appCount() }} out of {{ count }} applications
                    </p>
                    <pagination-controls
                      id="appPagination"
                      maxSize="5"
                      responsive="true"
                      previousLabel=""
                      nextLabel=""
                      (pageChange)="handlePageChange($event)"
                    ></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="appDetails"
            class="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-xs-12 content-wrapper-right"
          >
            <div class="app-details">
              <div>
                <p class="app-title">{{ appDetails.title }}</p>
              </div>
              <mat-accordion #accordion="matAccordion" multi>
                <mat-expansion-panel
                  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span>Link for privacy policy</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <a
                    class="policy-link"
                    [href]="
                      appDetails.privacy_policies__[0].privacy_policy_link
                    "
                    target="_blank"
                    >{{
                      appDetails.privacy_policies__[0].privacy_policy_link
                    }}</a
                  >
                </mat-expansion-panel>
                <mat-expansion-panel
                  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span>Privacy policy text</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <markdown
                    class="policy-text"
                    [data]="
                      appDetails.privacy_policies__[0].privacy_policy_text
                    "
                  >
                  </markdown>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="My analysed policies">
      <div class="scroll">
        <app-web-analysed-applications></app-web-analysed-applications>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
