import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }

  isLoggedIn(): boolean {
    return localStorage.getItem('token') ? true : false;
  }

  isIndividual(): boolean {
    return JSON.parse(localStorage.getItem('individual'));
  }

}
