<div class="sentences-wrapper">
    <div class="header">
        <span class="title">{{ title }}</span>
        <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="row content">
        <div class="sentences">
           <span class="sentences-inner" *ngIf="sentences">
                <span class="sentence-text" *ngFor="let sentence of sentences">{{sentence.text}}</span>
           </span>
        </div>
    </div>
    <div class="footer">
        <button class="mt-2 submit-btn" mat-raised-button (click)="cancel()">OK</button>
    </div>
</div>