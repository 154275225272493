<div class="regulation-wrapper">
    <div class="header">
        <span class="title">Missing regulations</span>
        <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="row content">
        <span *ngIf="!missingRegulations" class="regulation-btn" >
            <mat-icon>settings_suggest</mat-icon>
            <span>Missing regulation 1</span>
        </span>
        <div class="regulation-wrap" *ngIf="missingRegulations">
            <p class="regulations-description">Answers to following questions are not clearly addressed within this privacy policy.
            </p>
            <span *ngFor="let regulation of missingRegulations " class="regulation-btn" >
                <mat-icon>settings_suggest</mat-icon>
                <span>{{ business ? regulation.business_description : regulation.individual_description }}</span>
            </span>
        </div>
    </div>
    <div class="footer">
        <button class="mt-2 submit-btn" mat-raised-button (click)="cancel()">OK</button>
    </div>
</div>
