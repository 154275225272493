import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { AccountStatusComponent } from "./components/dashboard/account-status/account-status.component";
import { ContactComponent } from "./components/dashboard/contact/contact.component";
import { DashboardWrapperComponent } from "./components/dashboard/dashboard.component";
import { DashboardComponent } from "./components/dashboard/dashboard/dashboard.component";
import { ManagementBusinessComponent } from "./components/dashboard/management-business/management-business.component";
import { ManagementIndividualComponent } from "./components/dashboard/management-individual/management-individual.component";
import { MobileAppsComponent } from "./components/dashboard/mobile-apps/mobile-apps.component";
import { PrivacyPreferencesComponent } from "./components/dashboard/privacy-preferences/privacy-preferences.component";
import { ProfileComponent } from "./components/dashboard/profile/profile.component";
import { SandboxComponent } from "./components/dashboard/sandbox/sandbox.component";
import { WebAppsComponent } from "./components/dashboard/web-apps/web-apps.component";
import { ExpiredImgComponent } from "./components/expired-img/expired-img.component";
import { ForgetPasswordVerifComponent } from "./components/forget-password-verif/forget-password-verif.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { LoginWrapperComponent } from "./components/login/login-wrapper/login-wrapper.component";
import { RegisterWrapperComponent } from "./components/register/register-wrapper/register-wrapper.component";
import { RegisterComponent } from "./components/register/register/register.component";
import { ResendEmailComponent } from "./components/resend-email/resend-email.component";
import { UnsubscribeComponent } from "./components/unsubscribe/unsubscribe.component";
import { CustomPreferencesComponent } from "./components/user-preferences/custom-preferences/custom-preferences.component";
import { PreferencesWizardComponent } from "./components/user-preferences/preferences-wizard/preferences-wizard.component";
import { SelectPreferencesProfileComponent } from "./components/user-preferences/select-preferences-profile/select-preferences-profile.component";
import { StaticPreferenceOverviewComponent } from "./components/user-preferences/static-preference-overview/static-preference-overview.component";
import { VerifyEmailComponent } from "./components/verify-email/verify-email.component";
import { VerifyInvitationComponent } from "./components/verify-invitation/verify-invitation.component";
import { AuthBusinessGuard } from "./services/auth/auth-business.guard";
import { AuthIndividualGuard } from "./services/auth/auth-individual.guard";
import { AuthLoginGuard } from "./services/auth/auth-login.guard";
import { AuthGuard } from "./services/auth/auth.guard";
import { RedirectGuard } from "./services/redirect.guard";
import { WizardComponent } from "./components/register/wizard/wizard.component";

const prose = 'Pro Se | '

const routes: Routes = [
  {
    path: "login",
    component: LoginWrapperComponent,
    canActivate: [AuthLoginGuard],
    title: prose + 'Log in'
  },
  {
    path: "register",
    component: RegisterWrapperComponent,
    title: prose + 'Register'
  },
  {
    path: "register/personal",
    component: RegisterComponent,
    data: { individual: true },
    title: prose + 'Register - personal'
  },
  {
    path: "register/business",
    component: RegisterComponent,
    data: { individual: false },
    title: prose + 'Register - business'
  },
  {
    path: 'register/personal/wizard',
    component: WizardComponent,
    title: prose + 'Register wizard - personal'
  },
  {
    path: 'register/business/wizard',
    component: WizardComponent,
    title: prose + 'Register wizard - business'
  },
  {
    path: "confirm-email/individual",
    component: VerifyEmailComponent,
    data: { individual: true },
    title: prose + 'Confirm email - individual'
  },
  {
    path: "confirm-email/business",
    component: VerifyEmailComponent,
    data: { individual: false },
    title: prose + 'Confirm email - business'
  },
  {
    path: "forgot-password/personal",
    component: ForgotPasswordComponent,
    data: { individual: true },
    title: prose + 'Forgot password - personal'
  },
  {
    path: "forgot-password/business",
    component: ForgotPasswordComponent,
    data: { individual: false },
    title: prose + 'Forgot password - business'
  },
  {
    path: "reset-password/individual",
    component: ForgetPasswordVerifComponent,
    data: { individual: true },
    title: prose + 'Reset password - individual'
  },
  {
    path: "reset-password/business",
    component: ForgetPasswordVerifComponent,
    data: { individual: false },
    title: prose + 'Reset password - business'
  },
  {
    path: "resend-email",
    component: ResendEmailComponent,
    title: prose + 'Resend email'
  },
  {
    path: "accept-invite/business/:key",
    component: VerifyInvitationComponent,
    data: { individual: false },
    title: prose + 'Accept invite - business'
  },
  {
    path: "accept-invite/individual/:key",
    component: VerifyInvitationComponent,
    data: { individual: true },
    title: prose + 'Accept invite - individual'
  },
  {
    path: ":type/custom-preferences",
    component: CustomPreferencesComponent,
    data: { changing: false },
    title: prose + 'Custom preferences'
  },
  {
    path: ":type/create-preferences",
    component: CustomPreferencesComponent,
    data: { changing: true },
    title: prose + 'Custom preferences'
  },
  {
    path: ":type/select-preferences",
    component: SelectPreferencesProfileComponent,
    title: prose + 'Select preferences'
  },
  {
    path: ":type/preferences-wizard",
    component: PreferencesWizardComponent,
    title: prose + 'Preferences wizard'
  },
  {
    path: ":type/static-preference/:profile",
    component: StaticPreferenceOverviewComponent,
    title: prose + 'Static preferences'
  },
  {
    path: "",
    component: DashboardWrapperComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        redirectTo: "dashboard/mobile",
      },
      {
        path: "dashboard/mobile",
        component: DashboardComponent,
        canActivate: [AuthGuard, AuthBusinessGuard],
        title: prose + 'Dashbord - mobile',
      },
      {
        path: "dashboard/web",
        component: WebAppsComponent,
        canActivate: [AuthGuard, AuthBusinessGuard],
        title: prose + 'Dashbord - web',
      },
      {
        path: "dashboard/web/:id",
        component: WebAppsComponent,
        canActivate: [AuthGuard, AuthBusinessGuard],
        title: prose + 'Dashbord - web',
      },
      {
        path: "sandbox",
        component: SandboxComponent,
        canActivate: [AuthGuard, AuthBusinessGuard],
        title: prose + 'Sandbox',
      },
      {
        path: "apps/web",
        component: WebAppsComponent,
        canActivate: [AuthGuard],
        title: prose + 'Dashbord',
      },
      {
        path: "apps/web/:id",
        component: WebAppsComponent,
        canActivate: [AuthGuard],
        title: prose + 'Dashbord',
      },
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuard],
        title: prose + 'Profile',
      },
      {
        path: ":type/privacy-preferences",
        component: PrivacyPreferencesComponent,
        canActivate: [AuthGuard],
        title: prose + 'Privacy preferences',
      },
      {
        path: "account/status",
        component: AccountStatusComponent,
        canActivate: [AuthGuard],
        title: prose + 'Account status',
      },
      {
        path: "contact",
        component: ContactComponent,
        canActivate: [AuthGuard],
        title: prose + 'Contact',
      },
      {
        path: "apps/:type",
        component: MobileAppsComponent,
        canActivate: [AuthGuard, AuthIndividualGuard],
        title: prose + 'Apps',
      },
      {
        path: "manage/business",
        component: ManagementBusinessComponent,
        canActivate: [AuthGuard, AuthBusinessGuard],
        title: prose + 'Manage - business',
      },
      {
        path: "manage/individual",
        component: ManagementIndividualComponent,
        canActivate: [AuthGuard, AuthBusinessGuard],
        title: prose + 'Manage - individual',
      },
    ],
  },
  {
    path: "invitation-expired",
    component: ExpiredImgComponent,
    data: { invite: true },
    title: prose + 'Invitation expired'
  },
  {
    path: "not-found",
    component: ExpiredImgComponent,
    data: { invite: false },
    title: prose + 'Not found'
  },
  {
    path: "privacy-policy",
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    title: prose + 'Privacy policy',
    data: {
      externalUrl:
        "https://storage.googleapis.com/frontend_prosebiz/Pro%20Se%20Privacy%20Policy.pdf",
    },
  },
  {
    path: "unsubscribe",
    component: UnsubscribeComponent,
    title: prose + 'Unsubscribe'
  },
  {
    path: "**",
    redirectTo: "/not-found",
    pathMatch: "full",
    title: prose + 'Not found'
  },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: "enabled",
  scrollPositionRestoration: "enabled",
  onSameUrlNavigation: "reload",
  initialNavigation: "enabledBlocking",
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
